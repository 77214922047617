/* eslint-disable react/jsx-key */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import PropTypes from "prop-types";
import React from "react";
import { Col } from "react-bootstrap";
import VERIFIED from "../../../images/prout_verified.png";

const PLACEHOLDER_IMAGE =
  "https://static.vecteezy.com/system/resources/previews/004/141/669/non_2x/no-photo-or-blank-image-icon-loading-images-or-missing-image-mark-image-not-available-or-image-coming-soon-sign-simple-nature-silhouette-in-frame-isolated-illustration-vector.jpg";
const Image = ({ image }) => {
  return <img src={image} alt="" style={{ width: 35 }} />;
};
const ServiceItem = ({
  name = "John Doe",
  attributes = [],
  category_type,
  onClick = () => {},
  serviceImage,
}) => {
  function formatValues(data) {
    try {
      const regex = /"([^"]+)"/g;
      const matches = data.match(regex);
      const specializations = matches.map((match) => match.slice(1, -1));
      return specializations.join(", ");
    } catch (e) {
      return data;
    }
  }
  return (
    <Col className="p-4" key={name + category_type}>
      <p
        key={name + category_type}
        className="font-semibold"
        style={{ display: "inline-block", marginRight: "1.5px" }}
      >
        {name || "No name"}
      </p>
      {category_type !== "HOUSING" && category_type !== "JOB" && (
        <Image key={name + Math.random() + 0.5} image={VERIFIED} />
      )}
      {/* <Box
        key={_id}
        sx={{
          padding: 3,
          height: 200,
          width: 300,
          backgroundColor: "#e6e1e1",
          margin: "3px 0",
          cursor: "pointer",
        }}
        onClick={() => onClick()}
      ></Box> */}
      <div
        style={{ marginLeft: "35px", width: 300, height: 300, cursor: "pointer" }}
        onClick={() => onClick()}
      >
        <img
          src={serviceImage || PLACEHOLDER_IMAGE}
          alt="Profile"
          style={{
            marginLeft: -35,
            width: 300,
            height: 300,
            objectFit: "cover",
          }}
        />
        {/* <small>{`${userData.base_url}${userData.image_urls[0]}`}</small> */}
      </div>
      {/* {category_type !== "HOUSING" && category_type !== "JOB" && (
        <>
          <p style={{ fontStyle: "italic", color: "grey" }}>
            Verified Since {formatDate(since)}
          </p>
          {footer && <p>{footer}</p>}
        </>
      )} */}
      {category_type === "HOUSING" && (
        <section key={Math.random() + category_type + Math.random() + 0.5}>
          {attributes.map((attribute) => {
            if (attribute.attribute === "location") {
              const locationValue = attribute.value;
              return (
                <p
                  key={attribute.attribute}
                  style={{ fontStyle: "italic", color: "grey", marginBottom: "5px" }}
                >
                  <span style={{ color: "black" }}>Location:</span> {locationValue}
                </p>
              );
            }
            return null;
          })}

          {attributes.map((attribute) => {
            if (attribute.attribute === "listing type") {
              const listingType = attribute.value;
              return (
                <p
                  key={attribute.attribute}
                  style={{ fontStyle: "italic", color: "grey", marginBottom: "5px" }}
                >
                  <span style={{ color: "black" }}>Listing Type:</span> {listingType}
                </p>
              );
            }
            return null;
          })}

          {attributes.map((attribute) => {
            if (attribute.attribute === "price") {
              const price = attribute.value;
              return (
                <p
                  style={{ fontStyle: "italic", color: "grey", marginBottom: "5px" }}
                >
                  <span style={{ color: "black" }}>Price:</span> {price}
                </p>
              );
            }
            return null;
          })}
        </section>
      )}

      {category_type === "JOB" && (
        <>
          {attributes.map((attribute) => {
            if (attribute.attribute === "industry") {
              const type = attribute.value;
              return (
                <p
                  key={attribute.attribute}
                  style={{ fontStyle: "italic", color: "grey", marginBottom: "5px" }}
                >
                  <span style={{ color: "black" }}>Industry:</span> {type}
                </p>
              );
            }
            return null;
          })}

          {attributes.map((attribute) => {
            // if (attribute.attribute === "job title") {
            //   const type = attribute.value;
            //   return (
            //     <p
            //       style={{ fontStyle: "italic", color: "grey", marginBottom: "5px" }}
            //       key={attribute.attribute}
            //     >
            //       <span style={{ color: "black" }}>Job:</span> {type}
            //     </p>
            //   );
            // } else
            if (attribute.attribute === "position") {
              const type = attribute.value;
              return (
                <p
                  style={{ fontStyle: "italic", color: "grey", marginBottom: "5px" }}
                  key={attribute.attribute}
                >
                  <span style={{ color: "black" }}>Position:</span> {type}
                </p>
              );
            }
            return null;
          })}

          {attributes.map((attribute) => {
            if (attribute.attribute === "tenure") {
              const type = attribute.value;
              return (
                <p
                  key={attribute.attribute}
                  style={{ fontStyle: "italic", color: "grey", marginBottom: "5px" }}
                >
                  <span style={{ color: "black" }}>Commitment:</span> {type}
                </p>
              );
            }
            return null;
          })}
        </>
      )}

      {category_type === "LEGAL" && (
        <>
          {attributes.map((attribute) => {
            if (attribute.attribute === "firm") {
              const type = attribute.value;
              return (
                <p
                  key={attribute.attribute}
                  style={{ fontStyle: "italic", color: "grey", marginBottom: "5px" }}
                >
                  <span style={{ color: "black" }}>Firm:</span> {type}
                </p>
              );
            }
            return null;
          })}

          {attributes.map((attribute) => {
            if (attribute.attribute === "specialisation") {
              const type = formatValues(attribute.value);
              return (
                <p
                  key={attribute.attribute}
                  style={{ fontStyle: "italic", color: "grey", marginBottom: "5px" }}
                >
                  <span style={{ color: "black" }}>Specialisation:</span> {type}
                </p>
              );
            }
            return null;
          })}
        </>
      )}

      {category_type === "HEALTH" && (
        <>
          {attributes.map((attribute) => {
            if (attribute.attribute === "clinic") {
              const type = attribute.value;
              return (
                <p
                  key={attribute.attribute}
                  style={{ fontStyle: "italic", color: "grey", marginBottom: "5px" }}
                >
                  <span style={{ color: "black" }}>Clinic or Hospital:</span> {type}
                </p>
              );
            }
            return null;
          })}

          {attributes.map((attribute) => {
            if (attribute.attribute === "specialisation") {
              const type = formatValues(attribute.value);
              return (
                <p
                  key={attribute.attribute}
                  style={{ fontStyle: "italic", color: "grey", marginBottom: "5px" }}
                >
                  <span style={{ color: "black" }}>Specialisation:</span> {type}
                </p>
              );
            }
            return null;
          })}
        </>
      )}
    </Col>
  );
};

ServiceItem.propTypes = {
  _id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  since: PropTypes.string.isRequired,
  office: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  footer: PropTypes.string,
};

export default ServiceItem;
