import StarIcon from "@mui/icons-material/Star";
import StarHalfIcon from "@mui/icons-material/StarHalf";
import PropTypes from "prop-types";
import React from "react";
import "../../styles/ratingstar.css";

const RatingStars = ({ rating, readOnly, title }) => {
  const fullStars = Math.floor(rating);
  const hasHalfStar = rating % 1 !== 0;
  const maxStars = 5;
  const stars = [];

  for (let i = 0; i < fullStars; i++) {
    stars.push(<StarIcon key={i} />);
  }

  if (hasHalfStar) {
    stars.push(<StarHalfIcon key={fullStars} />);
  }

  for (let i = stars.length; i < maxStars; i++) {
    stars.push(<StarIcon key={i} className="empty-star" />);
  }

  return (
    <div className={`rating-widget${readOnly ? " read-only" : ""}`}>
      {title && <div className="rating-widget-title">{title}</div>}
      <div className="rating-widget-stars">{stars}</div>
    </div>
  );
};

RatingStars.propTypes = {
  rating: PropTypes.number.isRequired,
  readOnly: PropTypes.bool,
  title: PropTypes.string,
};

RatingStars.defaultProps = {
  readOnly: false,
};

export default RatingStars;
