import React from "react";
import { Container, Nav } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import "../../styles/dashboard.css";
import BackButton from "../common/BackButton";
import Prout from "../common/Prout";

const ReviewPanel = () => {
  const navigate = useNavigate();

  return (
    <Container fluid>
      <div className="side-panel show">
        <div className="side-panel-header">
          <div className="back-button-container">
            <BackButton onClick={() => navigate(-1)} />
          </div>

          <Prout />
          <div className="side-panel-header-border"></div>
        </div>
        <Nav className="mr-auto flex-column">
          <br />
          <br />
          {/* <Link
            className="navlink-black"
            style={{ textDecoration: "none" }}
            to="/create_review"
          >
            New Review
          </Link>{" "} */}
          <br />
          <Link
            className="navlink-black"
            style={{ textDecoration: "none" }}
            to="/create_page"
          >
            Submit Listing
          </Link>{" "}
          <br />
          {/* <Link
            className="navlink-black"
            style={{ textDecoration: "none" }}
            to="/invite"
          >
            Invite a Friend
          </Link>{" "} */}
          <br />
        </Nav>
      </div>
    </Container>
  );
};

export default ReviewPanel;
