import React from "react";

interface TileProps {
  image: string;
  altText: string;
  widthxy: string;
  onClick: () => void;
}

const ImageTile: React.FC<TileProps> = ({ image, altText, widthxy, onClick }) => {
  return (
    <div
      onClick={onClick}
      className="block relative w-full focus:outline-none border-hidden"
    >
      <img
        src={image}
        alt=""
        style={{
          width: widthxy,
        }}
      />
    </div>
  );
};

export default ImageTile;
