import { ImageListItem, Typography } from "@mui/material";
import { Box } from "@mui/system";

const NotFound = () => {
  return (
    <Box display="flex" sx={{ flexDirection: "column" }} justifyContent="center">
      <Typography variant="h4" textAlign="center" fontWeight="bold" mt={10} mb={10}>
        Page not found
      </Typography>
      <ImageListItem sx={{ margin: "auto" }}>
        <img
          src="../../../images/not-found.png"
          loading="lazy"
          style={{ width: "200px" }}
        />
      </ImageListItem>
    </Box>
  );
};

export default NotFound;
