export const formatDate = (date) =>
  new Date(date).toLocaleString("default", {
    month: "long",
    year: "numeric",
    day: "2-digit",
  });
export const formatDateDDMMYYYY = (dateString) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // January is 0!
  const year = date.getFullYear();

  return `${day}-${month}-${year}`;
};
