import React, { useEffect, useState } from "react";

interface DelayedRenderProps {
  children: React.ReactNode;
}

const DelayedRender: React.FC<DelayedRenderProps> = ({ children }) => {
  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setShowContent(true);
    }, 1000);

    return () => clearTimeout(timeoutId);
  }, []);

  return showContent ? <>{children}</> : null;
};

export default DelayedRender;
