import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import { getExpanded, setExpanded } from "../../features/user/userSlice";
import Header from "../common/Header";
import SidePanel from "../common/SidePanel";

const Main = () => {
  //const [isExpanded, setIsExpanded] = useState(false);
  const expanded = useSelector(getExpanded);
  const dispatch = useDispatch();
  const toggleMenu = () => {
    dispatch(setExpanded(!expanded));
    //setIsExpanded(!isExpanded);
  };
  return (
    <div>
      {" "}
      <Header toggleMenu={toggleMenu} title="prout" />
      <SidePanel isExpanded={expanded} toggleMenu={toggleMenu} />
      <Outlet />
    </div>
  );
};

export default Main;
