import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  Box,
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { message } from "antd";
import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { changePassword } from "../../api/auth";
import BackButton from "../common/BackButton";

const ChangePassword = () => {
  const navigate = useNavigate();
  const [data, setData] = useState({
    otp: "",
    password: "",
    confirmPassword: "",
  });

  const changePasswordMutation = useMutation({
    mutationFn: changePassword,
    onSuccess: () => {
      message.success("Successfully changed password");
      navigate("/login");
    },
    onError: () => {
      message.error("Error during change password");
    },
  });

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setData({ ...data, [name]: value });
  };

  const handleSubmit = () => {
    //console.log(data);
    if (data.confirmPassword !== data.password) {
      message.error("Password doesn't match");
      return;
    }
    if (data.password.length < 5) {
      message.error("Password should have at least 5 characters");
      return;
    }
    if (data.otp.length < 6) {
      message.error("OTP should have at least 6 characters");
      return;
    }
    changePasswordMutation.mutate({ token: data.otp, password: data.password });
  };

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  const handleToggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword((prevState) => !prevState);
  };

  return (
    <Box>
      <BackButton onClick={() => navigate("/login")} />
      <Box
        sx={{
          width: {
            md: 400,
          },
          margin: "auto",
        }}
      >
        <div style={{ margin: "0 15px" }}>
          <Typography mb={4} textAlign="center">
            Change Password
          </Typography>
          <Form.Group controlId="email" className="form-group-spacing">
            <Form.Label>Enter your OTP</Form.Label>
            <TextField
              type="text"
              placeholder="Enter your OTP"
              fullWidth
              name="otp"
              value={data.otp}
              onChange={handleChange}
              variant="standard"
              inputProps={{ className: "custom-input" }}
              InputLabelProps={{ className: "small-placeholder" }}
            />
            <Form.Label>Password</Form.Label>
            <TextField
              type={showPassword ? "text" : "password"}
              placeholder="Enter your password"
              value={data.password}
              onChange={handleChange}
              fullWidth
              name="password"
              variant="standard"
              inputProps={{ className: "custom-input" }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleTogglePasswordVisibility}
                      size="small"
                    >
                      {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Form.Label>Confirm password</Form.Label>
            <TextField
              type={showConfirmPassword ? "text" : "password"}
              placeholder="Enter your password"
              value={data.confirmPassword}
              onChange={handleChange}
              fullWidth
              name="confirmPassword"
              variant="standard"
              inputProps={{ className: "custom-input" }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleToggleConfirmPasswordVisibility}
                      size="small"
                    >
                      {showConfirmPassword ? (
                        <VisibilityOffIcon />
                      ) : (
                        <VisibilityIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Form.Group>
          {changePasswordMutation.isLoading ? (
            <div className="text-center">
              <CircularProgress style={{ margin: "auto" }} />
            </div>
          ) : (
            <Button
              variant="secondary"
              type="submit"
              className="custom-rainbow-button mt-3"
              disabled={!data.confirmPassword || !data.password || !data.otp}
              onClick={handleSubmit}
            >
              Submit
            </Button>
          )}
        </div>
      </Box>
    </Box>
  );
};

export default ChangePassword;
