/* eslint-disable no-unused-vars */
import EditIcon from "@mui/icons-material/Edit";

import { CircularProgress, IconButton } from "@mui/material";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { message } from "antd";
import ImageCompression from "browser-image-compression";
import React, { useEffect, useState } from "react";
import { Alert, Button, Col, Container, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getProfile, updateProfile, uploadProfileImage } from "../../api/auth";
import { getEmail, getToken } from "../../features/user/userSlice";
import "../../styles/dashboard.css";
import BackButton from "../common/BackButton";
import Loading from "../common/Loading";

const Profile = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const API_BASE_DOMAIN = import.meta.env.VITE_API_BASE_URL;

  const [errorMessage, setErrorMessage] = useState("");
  const [imageError, setImageError] = useState("");
  const token = useSelector(getToken);
  const email = useSelector(getEmail);
  const [isUpload, setIsUpload] = useState(false);

  const [profileImage, setProfileImage] = useState();
  const [baseUrl, setBaseUrl] = useState(API_BASE_DOMAIN + "/images/");

  const [profileImageLink, setProfileImageLink] = useState();

  const handleFileChange = async (e) => {
    const file = e.target.files[0];

    const options = {
      maxSizeMB: 0.6,
      maxWidthOrHeight: 1300,
      useWebWorker: true,
    };

    try {
      const compressedFile = await ImageCompression(file, options);
      //console.log("Original File:", file);
      //console.log("Compressed file:", compressedFile);

      // Add the file extension back to the compressed file if it's missing
      if (!compressedFile.name.includes(".")) {
        const fileExtension = file.name.split(".").pop();
        compressedFile.name += `.${fileExtension}`;
      }

      if (compressedFile && compressedFile.size < 3 * 1024 * 1024) {
        setImageError("");
        setProfileImage(compressedFile);
        setProfileImageLink(URL.createObjectURL(compressedFile));
      } else {
        setImageError("The file size exceeds the limit");
      }
    } catch (error) {
      console.error("Error during image compression:", error);
      setImageError("Error during image compression");
    }
  };

  const { data, isLoading: isProfileDataLoading } = useQuery({
    queryFn: () => getProfile({ token, username: email }),
    queryKey: ["user-profile"],
  });

  const userProfile = data?.response;
  const [userData, setUserData] = useState({
    orientation: "",
    gender: "",
    biography: "",
    firstname: "",
    lastname: "",
    description: "",
    website: "",
    age_bracket: "",
    image_urls: "",
    base_url: "",
    pronoun: "",
  });

  const updateProfileMutation = useMutation({
    mutationFn: updateProfile,
    onSuccess: () => {
      message.success("Successfully updated the profile");
      queryClient.invalidateQueries(["user-profile"]);
    },
    onError: (error) => {
      console.error("Profile update failed:", error);
      message.error("Failed to update the profile");
    },
  });

  const submit = (e) => {
    e.preventDefault();

    const {
      orientation,
      gender,
      biography,
      firstname,
      lastname,
      description,
      website,
      age_bracket,
      pronoun,
    } = userData;

    const values = {
      orientation,
      gender,
      biography,
      firstName: firstname,
      lastName: lastname,
      description,
      website,
      pronoun,
      ageBracket: age_bracket,
    };
    //console.log(userData);

    updateProfileMutation.mutate({ token, username: email, values });
  };

  const handleProfileChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setUserData({ ...userData, [name]: value });
    //console.log(userData);
  };

  const profileImageMutation = useMutation({
    mutationFn: uploadProfileImage,
    onSuccess: () => {
      message.success("Successfully updated profile image");
      setProfileImage("");
      queryClient.invalidateQueries(["user-profile"]);
    },
    onError: () => {
      message.error("Error while uploading profile image");
    },
  });

  const handleUploadProfileImage = () => {
    //console.log(profileImage);
    profileImageMutation.mutate({ token, username: email, profileImage });
    setIsUpload(false);
    setImageError("");
  };

  useEffect(() => {
    if (userProfile) {
      setUserData(userProfile);
      if (userProfile.image_urls[0]) {
        setProfileImageLink(
          `${userProfile.base_url}${userProfile.image_urls.at(
            userProfile.image_urls.length - 1
          )}`
        );
      }
      setBaseUrl(userProfile.base_url);
    }
  }, [userProfile]);

  if (isProfileDataLoading) {
    return <Loading />;
  }

  return (
    <Container fluid>
      <div className="side-panel show">
        {/* <div className="side-panel-header">
          <div className=" mt-8 ml-8 back-button-container">
            <BackButton onClick={() => navigate(-1)} />
          </div>
        </div> */}

        <Col xs={12} md={9} className="p-4">
          <BackButton color="text-black" onClick={() => navigate(-1)} />
        </Col>

        <div className="profile-image-container">
          <div className="profile-image-wrapper" style={{ marginLeft: "35px" }}>
            <img
              src={
                profileImageLink ||
                "https://www.vhv.rs/dpng/d/312-3120300_default-profile-hd-png-download.png"
              }
              alt="Profile"
              className="profile-image"
              style={{ marginRight: "-20px" }}
            />
            {/* <small>{`${userData.base_url}${userData.image_urls[0]}`}</small> */}
            <IconButton
              className="edit-profile-button"
              onClick={() => setIsUpload((prev) => !prev)}
            >
              <EditIcon />
            </IconButton>
          </div>
        </div>
        {isUpload && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <input
              accept=".png,.jpg,.jpeg"
              type="file"
              style={{
                marginBottom: "20px",
                fontSize: "16px",
                paddingTop: "10px",
              }}
              onChange={handleFileChange}
              name="image"
            />
            <small style={{ color: "red", marginBottom: 10, marginTop: -5 }}>
              {imageError}
            </small>

            {profileImageMutation.isLoading ? (
              <div className="text-center">
                <CircularProgress style={{ margin: "auto" }} />
              </div>
            ) : (
              <Button
                disabled={imageError || !profileImage}
                variant="primary"
                size="sm"
                onClick={handleUploadProfileImage}
                style={{ marginBottom: 10 }}
              >
                Upload
              </Button>
            )}
          </div>
        )}
        <p className="p-italic">Hi {userData.firstname || userData.username}</p>
        <Col xs={12} md={9} className="p-4">
          <p className="p-italic">
            These details will be seen by other users. Only your first name can be
            viewed by other users
          </p>
        </Col>
        <Col xs={12} md={9} className="p-4">
          <Form onSubmit={submit}>
            {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
            <Form.Group controlId="firstname" className="form-group-spacing">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter First Name"
                value={userData.firstname || ""}
                name="firstname"
                onChange={handleProfileChange}
              />
            </Form.Group>
            <Form.Group controlId="lastname" className="form-group-spacing">
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Last Name"
                value={userData.lastname || ""}
                name="lastname"
                onChange={handleProfileChange}
              />
            </Form.Group>{" "}
            <Form.Group controlId="pronoun" className="form-group-spacing">
              <Form.Label>Pronouns</Form.Label>
              <Form.Control
                type="text"
                placeholder="he/him, she/her, they/them"
                value={userData.pronoun || ""}
                name="pronoun"
                onChange={handleProfileChange}
              />
            </Form.Group>
            <Form.Group controlId="orientation" className="form-group-spacing">
              <Form.Label>Sexual Orientation</Form.Label>
              <Form.Control
                type="text"
                placeholder="gay, lesbian, bisexual, pansexual, asexual etc"
                value={userData.orientation || ""}
                name="orientation"
                onChange={handleProfileChange}
              />
            </Form.Group>
            <Form.Group controlId="gender" className="form-group-spacing">
              <Form.Label>Gender Identity</Form.Label>
              <Form.Control
                type="text"
                placeholder="Cisgender, Transgender etc"
                value={userData.gender || ""}
                name="gender"
                onChange={handleProfileChange}
              />
            </Form.Group>
            <Form.Group controlId="lastname" className="form-group-spacing">
              <Form.Label>Age Bracket</Form.Label>
              <Form.Select
                aria-label="Default select example"
                value={userData.age_bracket || ""}
                onChange={handleProfileChange}
                name="age_bracket"
              >
                <option value="16-24">16-24</option>
                <option value="25-34">25-34</option>
                <option value="35-44">35-44</option>
                <option value="45-54">45-54</option>
                <option value="55+">55+</option>
              </Form.Select>
            </Form.Group>{" "}
            <Form.Group controlId="biography" className="form-group-spacing">
              <Form.Label>Tell us more about yourself</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Your interests, passion, or any LGBTQ organisations you volunteer or work in"
                value={userData.biography || ""}
                name="biography"
                onChange={handleProfileChange}
              />
            </Form.Group>
            {/* <Form.Group controlId="description" className="form-group-spacing">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Add additional description"
                value={userData.description || ""}
                name="description"
                onChange={handleProfileChange}
              />
            </Form.Group>
            <Form.Group controlId="website" className="form-group-spacing">
              <Form.Label>Website</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Website"
                value={userData.website || ""}
                name="website"
                onChange={handleProfileChange}
              />
            </Form.Group> */}
            {updateProfileMutation.isLoading ? (
              <div className="text-center">
                <CircularProgress style={{ margin: "auto" }} />
              </div>
            ) : (
              <Button
                variant="secondary"
                type="submit"
                className="custom-rainbow-button mt-3 w-8/12"
              >
                Update Profile
              </Button>
            )}
          </Form>
          <Button
            variant="secondary"
            onClick={() => navigate(-1)}
            className="custom-rainbow-button mt-3 w-8/12"
          >
            Back
          </Button>
        </Col>
      </div>
    </Container>
  );
};

export default Profile;
