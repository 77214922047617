import React from "react";

const Settings = () => {
  return (
    <div>
      <br />
      <center>UNDER CONSTRUCTION</center>
    </div>
  );
};

export default Settings;
