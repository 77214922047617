import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Box, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import BackgroundVideo from "../common/BackgroundVideo";
import Divider from "../common/Divider";
const Intro = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    localStorage.setItem("introIsVisited", 1);
    navigate("/login");
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        mt: 5,
        mb: 10,
      }}
    >
      <div className="relative w-full mt-10">
        <BackgroundVideo height="h-64" />
        <div className="absolute inset-0 flex items-center justify-center text-center text-white">
          <p className=" font-extrabold text-2xl">
            WELCOME TO PROUT.{" "}
            <span className="block font-light">
              WE CONNECT YOU TO
              <span className="block">INCLUSIVE CRITICAL </span>
              <span className="block"></span>SERVICE PROVIDERS.
            </span>
            <span className=" mt-4 font-thin text-xs">
              HEALTHCARE. LEGAL. JOBS. HOUSING.
            </span>
          </p>
        </div>
      </div>
      <Box
        sx={{
          marginX: "auto",
          width: "80%",
          maxWidth: 600,
          mt: 8,
        }}
      >
        <Typography className=" justify-normal">
          Seeking the services of a LGBTQ+ friendly mental health therapist or
          gynecologist? Engaging a lawyer to help you ensure your rainbow family or
          partner is protected? Finding a job at a company that will not discriminate
          against you for who you are? Searching for a safe housing space to live as
          your authentic self?
        </Typography>
        <Typography className="justify-normal" mt={3}>
          PROUT aims to connect you to inclusive critical service providers under
          these four categories: Legal, Healthcare, Housing, & Jobs.
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          cursor: "pointer",
          width: "90%",
          maxWidth: 600,
          mr: 3,
          mt: 3,
        }}
      >
        <ArrowForwardIcon
          onClick={handleClick}
          sx={{ width: "40px", height: "40px" }}
        />
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          cursor: "pointer",
          width: "80%",
          maxWidth: 600,
        }}
      >
        {" "}
        <Divider className="" />
      </Box>
    </Box>
  );
};

export default Intro;
