import { ExclamationCircleFilled } from "@ant-design/icons";
import { Box, Typography } from "@mui/material";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Button, Form, Input, Modal, message } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  addServiceChangeRequest,
  deleteServiceChangeRequest,
  getServiceChangeRequests,
} from "../../api/auth";
import { getEmail, getToken } from "../../features/user/userSlice";
import { formatDate } from "../../helpers/formatDate";
import FloatingActionButton from "../common/FloatingActionButton";

const ChangeRequests = () => {
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };
  const { serviceId } = useParams();
  const token = useSelector(getToken);
  const email = useSelector(getEmail);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const queryClient = useQueryClient();
  const { data, isLoading } = useQuery({
    queryFn: () => getServiceChangeRequests({ token, username: email, serviceId }),
    queryKey: ["requests", serviceId],
  });

  const REQUESTS = data ?? [];

  const addRequestMutation = useMutation({
    mutationFn: addServiceChangeRequest,
    onSuccess: () => {
      message.success("Request added successfully");
      queryClient.invalidateQueries(["requests", serviceId]);
    },
    onError: () => {
      message.error("Error while adding request");
    },
  });

  const deleteRequestMutation = useMutation({
    mutationFn: deleteServiceChangeRequest,
    onSuccess: () => {
      message.success("Successfully deleted a request");
      queryClient.invalidateQueries(["requests", serviceId]);
    },
    onError: () => {
      message.error("Error while deleting request");
    },
  });

  const onFinish = (values) => {
    values = { ...values, service_id: serviceId };
    addRequestMutation.mutate({ token, username: email, values });
    setIsModalOpen(false);
  };

  const handleDeleteRequest = (id) => {
    deleteRequestMutation.mutate({ token, username: email, id });
  };

  if (isLoading) {
    return <h2>Loading...</h2>;
  }
  if (!isLoading) {
    //console.log(REQUESTS);
  }

  return (
    <Box sx={{ marginTop: 5, marginLeft: 3 }}>
      {REQUESTS.length === 0 && <Typography>No Requests</Typography>}
      {REQUESTS.length > 0 &&
        REQUESTS.map((req) => (
          <Box
            sx={{
              marginBottom: 5,
            }}
            key={req.id}
          >
            <Typography>Title: {req.title}</Typography>
            <Typography>Content: {req.content}</Typography>
            <Typography>Status: {req.status}</Typography>
            <Typography>Date: {formatDate(req.created_at)}</Typography>
            <Button
              size="small"
              danger
              onClick={() => {
                Modal.confirm({
                  title: "Are you sure delete this user?",
                  icon: <ExclamationCircleFilled />,
                  okText: "Yes",
                  okType: "danger",
                  onOk: () => {
                    handleDeleteRequest(req.id);
                  },
                });
              }}
            >
              Delete
            </Button>
          </Box>
        ))}
      <div className="fab-container" onClick={() => setIsModalOpen(true)}>
        <FloatingActionButton />
      </div>
      <Modal
        title={
          <div style={{ textAlign: "center", fontSize: 20, marginBottom: 30 }}>
            Add Service Change Requests
          </div>
        }
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        footer={null}
        width={700}
        destroyOnClose={true}
        maskClosable={false}
      >
        <Form
          {...layout}
          onFinish={onFinish}
          style={{ maxWidth: 600 }}
          initialValues={{ ...data }}
        >
          <Form.Item
            name="title"
            label="Title"
            rules={[{ required: true, message: "Required" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="content"
            label="Content"
            rules={[{ required: true, message: "Required" }]}
          >
            <Input.TextArea />
          </Form.Item>

          <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
            <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </Box>
  );
};

export default ChangeRequests;
