import React, { FC, useEffect, useState } from "react";
import { FaChevronDown } from "react-icons/fa";

interface Option {
  label: string;
  onClick: () => void;
}

interface DropdownProps {
  options: Option[];
  selectedValue: string;
  initialValue: string;
}

const DropdownCustom: FC<DropdownProps> = ({
  options,
  selectedValue,
  initialValue,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState(selectedValue);

  useEffect(() => {
    setSelected(selectedValue); // Update selected state when selectedValue prop changes
  }, [selectedValue]);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleSelect = (option: Option) => {
    setSelected(option.label);
    option.onClick();
    setIsOpen(false);
  };

  return (
    <div className="relative inline-block text-left">
      <div className="flex items-center cursor-pointer" onClick={toggleDropdown}>
        <span className="mr-2 text-violet-950 text-lg">
          {selected === "undefined" || selected === "" || selected === undefined
            ? initialValue
            : selected}
        </span>
        <FaChevronDown className=" text-violet-950" />
      </div>
      {isOpen && (
        <div className="absolute left-0 mt-2 w-48 bg-white shadow-lg rounded-md z-10">
          <div className="py-1">
            {options.map((option) => (
              <div
                key={option.label}
                className="px-4 py-2 hover:bg-gray-200 cursor-pointer text-violet-950"
                onClick={() => handleSelect(option)}
              >
                {option.label}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default DropdownCustom;
