import { ExclamationCircleFilled } from "@ant-design/icons";
import { Box, CircularProgress, Typography } from "@mui/material";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Carousel, Divider, Modal, message } from "antd";
import ImageCompression from "browser-image-compression";
import Linkify from "linkify-react";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  deleteServiceImage,
  deleteServiceImageSolo,
  getOwnServicesWithAttributes,
  updateService,
  uploadServiceImage,
} from "../../api/auth";
import {
  getCurrentService,
  getEmail,
  getProfileId,
  getServices,
  getToken,
} from "../../features/user/userSlice";
import isValidSGNum from "../../helpers/contactNumSG";
import { removeDuplicates } from "../../helpers/duplicateRemover";
import { formatDateDDMMYYYY } from "../../helpers/formatDate";
import DashboardFooter from "../common/DashboardFooter";
import useScrollToBottom from "../hooks/UseScrollToBottom";
import UpdateServiceModal from "./UpdateServiceModal";

const PLACEHOLDER_IMAGE =
  "https://static.vecteezy.com/system/resources/previews/004/141/669/non_2x/no-photo-or-blank-image-icon-loading-images-or-missing-image-mark-image-not-available-or-image-coming-soon-sign-simple-nature-silhouette-in-frame-isolated-illustration-vector.jpg";

// import "../../styles/dashboard.css";
// import "../../styles/register.css";

const ReadPage = () => {
  const isBottom = useScrollToBottom();
  const { serviceId } = useParams();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const services = useSelector(getServices);
  const currentService = useSelector(getCurrentService);

  const service = services.find((service) => service._id.toString() === serviceId);
  if (!service) {
    return navigate("/dashboard");
  }

  const [imageError, setImageError] = useState("");
  const [serviceImage, setServiceImage] = useState();
  const [profileImageLink, setProfileImageLink] = useState();
  const [isRefetch, setIsRefetch] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [tags, setTags] = useState([]);
  const [initialTags, setInitialTags] = useState([]);
  const [selectedService, setSelectedService] = useState(service);

  const token = useSelector(getToken);
  const email = useSelector(getEmail);
  const profileId = useSelector(getProfileId);
  const dispatch = useDispatch();

  // const handleFileChange = (e) => {
  //   const profilePic = e.target.files[0];
  //   const MAX_FILE_SIZE = 3 * 1024 * 1024;

  //   if (profilePic && profilePic.size <= MAX_FILE_SIZE) {
  //     setImageError("");
  //     setServiceImage(profilePic);
  //     setProfileImageLink(URL.createObjectURL(profilePic));
  //   } else {
  //     setImageError("Maximum image size is 3mb");
  //   }
  // };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];

    const options = {
      maxSizeMB: 0.6,
      maxWidthOrHeight: 1300,
      useWebWorker: true,
    };

    try {
      const compressedFile = await ImageCompression(file, options);

      // Add the file extension back to the compressed file if it's missing
      if (!compressedFile.name.includes(".")) {
        const fileExtension = file.name.split(".").pop();
        compressedFile.name += `.${fileExtension}`;
      }

      if (compressedFile && compressedFile.size < 3 * 1024 * 1024) {
        setImageError("");
        setServiceImage(compressedFile);
        setProfileImageLink(URL.createObjectURL(compressedFile));
      } else {
        setImageError("The file size exceeds the limit");
      }
    } catch (error) {
      console.error("Error during image compression:", error);
      setImageError("Error during image compression");
    }
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const renderLink = ({ type, href, value, key }) => {
    if (type === "phone") {
      return (
        <a href={`tel:${value}`} key={key}>
          {value}
        </a>
      );
    }
  };

  const editService = (service) => {
    if (service.tags && service.tags.length > 0) {
      setInitialTags(
        service.tags.map((tag, i) => ({
          value: tag,
          id: Date.now() + i,
        }))
      );
    }

    setIsModalOpen(true);
  };

  const { data, isLoading, refetch } = useQuery({
    queryFn: () => getOwnServicesWithAttributes({ token, username: email }),
    queryKey: ["servicess"],
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    enabled: isRefetch,
  });

  const SERVICES = data?.response ?? [];
  const [toDeleteImage, setToDeleteImage] = useState();

  const serviceImageMutation = useMutation({
    mutationFn: uploadServiceImage,
    onSuccess: async () => {
      message.success("Successfully updated service image");
      setServiceImage("");
      setIsRefetch(true);
      queryClient.invalidateQueries(["servicess", "services"]);
    },
    onError: () => {
      message.error("Error while uploading service image");
    },
  });

  const deleteServiceImageMutation = useMutation({
    mutationFn: deleteServiceImage,
    onSuccess: () => {
      message.success("Successfully deleted profile image");
      setProfileImageLink("");
      setIsRefetch(true);
      queryClient.invalidateQueries(["servicess", "services"]);
    },
    onError: () => {
      message.error("Error while deleting service image");
    },
  });

  const handleUploadProfileImage = () => {
    serviceImageMutation.mutate({ token, username: email, serviceImage, serviceId });
    setImageError("");
  };

  // handles adding of data to attributes field
  const categoryHandler = (values, category) => {
    const attributes = [];
    const CATEGORIES = {
      legal: [
        "firm",
        "specialisation",
        "confirmation",
        "profession",
        "contact",
        "email",
        "website",
        "about me",
        "facebook",
        "instagram",
      ],
      health: [
        "clinic",
        "specialisation",
        "profession",
        "confirmation",
        "contact",
        "email",
        "website",
        "about me",
        "facebook",
        "instagram",
        "mental health specialization",
      ],
      job: [
        "salary",
        "position",
        "tenure",
        "company",
        "contact",
        "company address",
        "industry",
        "work place type",
        "job title",
        "job description",
        "confirmation",
      ],
      housing: [
        "listing type",
        "category",
        "availability",
        "existing tenancy",
        "bedrooms",
        "toilets",
        "floor area in sqft",
        "price",
        "description",
        "furnishing",
        "floor level",
        "contact",
        "confirmation",
      ],
    };
    const keys = CATEGORIES[category];

    keys.forEach((key) => {
      attributes.push({ attribute: key, value: values[key] });
      delete values[key];
    });

    return attributes;
  };

  const updateServiceMutation = useMutation({
    mutationFn: updateService,
    onSuccess: () => {
      message.success("Successfully updated a service");
      refetch();
      setIsModalOpen(false);
    },
    onError: () => {
      setIsModalOpen(false);
      message.error("Error while  updating a service");
    },
  });

  const deleteImageMutationHousing = useMutation({
    mutationFn: deleteServiceImageSolo,
    onSuccess: () => {
      message.success("Successfully deleted image");
      setProfileImageLink("");
      setIsRefetch(true);
      queryClient.invalidateQueries(["servicess", "services"]);
    },
    onError: () => {
      message.error("Error while deleting an image");
    },
  });
  const handleDeleteServiceImage = () => {
    if (service.category_type !== "HOUSING") {
      deleteServiceImageMutation.mutate({ token, username: email, serviceId });
    } else {
      deleteImageMutationHousing.mutate({
        token,
        username: email,
        image: toDeleteImage,
      });
    }
  };

  const handleUpdateService = (values) => {
    const category = values["category_type"];
    const attributes = categoryHandler(values, category.toLowerCase());
    values.attributes = JSON.stringify(attributes);
    values.category_type = values.category_type.toUpperCase();

    updateServiceMutation.mutate({
      token,
      username: email,
      values: { ...values, profile_id: service.profile_id, tags: tags },
      id: service._id,
    });
    setTags([]);
    setInitialTags([]);
    setIsModalOpen(false);
  };

  const contentStyle = {
    width: "auto",
    // height: "160px",
    // color: "#fff",
    // lineHeight: "160px",
    // textAlign: "center",
    // background: "#364d79",
  };

  function rearrangeArray(type, arr) {
    const jobs = [
      "position",
      "job title",
      "salary",
      "industry",
      "company",
      "company address",
      "work place type",
      "tenure",
      "job description",
      "contact",
      "confirmation",
    ];

    const housing = [
      "listing type",
      "category",
      "availability",
      "existing tenancy",
      "location",
      "bedrooms",
      "toilets",
      "floor area in sqft",
      "price",
      "description",
      "furnishing",
      "floor level",
      "contact",
      "confirmation",
    ];
    const health = [
      "specialisation",
      "profession",
      "clinic",
      "contact",
      "email",
      "website",
      "about me",
      "mental health specialization",
      "facebook",
      "instagram",
      "confirmation",
    ];
    const legal = [
      "profession",
      "firm",
      "contact",
      "email",
      "website",
      "specialisation",
      "about me",
      "company facebook",
      "company instagram",
      "contact person",
      "confirmation",
    ];

    const data = {
      JOB: jobs,
      HOUSING: housing,
      LEGAL: legal,
      HEALTH: health,
    };

    const result = [];

    for (const key of data[type]) {
      const obj = arr.find((item) => item.attribute === key);
      if (obj) {
        result.push(obj);
      }
    }

    return result;
  }

  //  ----- causing bug in dashboard read page ----
  // if (!isLoading) {
  //   localStorage.setItem("services", JSON.stringify(SERVICES));
  //   dispatch(setServices(SERVICES));
  // }

  useEffect(() => {
    if (currentService !== "HOUSING") {
      if (service.images[0]) {
        setProfileImageLink(
          `${service.base_url}${service.images.at(service.images.length - 1)}`
        );
      }
    } else {
      setProfileImageLink(service.images);
    }
  }, [service]);

  return (
    <div>
      <Container fluid className="container">
        {/* <Header toggleMenu={toggleMenu} title="prout" isBack="true" />
      <SidePanel isExpanded={isExpanded} toggleMenu={toggleMenu} /> */}

        <Col xs={12} md={9} className="p-4">
          <Form.Group controlId="fullName" className="">
            <p className="font-semibold">{service.name || "No name"}</p>
          </Form.Group>
          {service.category_type !== "HOUSING" ? (
            <div style={{ marginLeft: "35px", width: 300, height: 300 }}>
              <img
                src={profileImageLink || PLACEHOLDER_IMAGE}
                alt="Profile"
                style={{
                  marginLeft: -35,
                  width: 300,
                  height: 300,
                  objectFit: "cover",
                }}
              />
              {/* <small>{`${userData.base_url}${userData.image_urls[0]}`}</small> */}
            </div>
          ) : (
            <>
              <Carousel
                draggable
                autoplay
                afterChange={(i) => setToDeleteImage(service.images[i])}
              >
                {service.images.map((img, i) => (
                  <div key={i}>
                    <img
                      src={service.base_url + img || PLACEHOLDER_IMAGE}
                      alt="Image"
                      style={{
                        marginLeft: -35,
                        width: 300,
                        height: 300,
                        objectFit: "cover",
                      }}
                    />
                  </div>
                ))}
              </Carousel>
            </>
          )}
          {profileId === service?.profile_id.toString() &&
            service.verified === "FALSE" && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: 200,
                }}
              >
                <input
                  accept=".png,.jpg,.jpeg"
                  type="file"
                  style={{
                    marginBottom: "20px",
                    fontSize: "16px",
                    paddingTop: "10px",
                  }}
                  onChange={handleFileChange}
                  name="image"
                />
                <small style={{ color: "red", marginBottom: 10, marginTop: -5 }}>
                  {imageError}
                </small>

                <Box>
                  {false ? (
                    <div className="text-center">
                      <CircularProgress style={{ margin: "auto" }} />
                    </div>
                  ) : (
                    <Button
                      disabled={imageError || !serviceImage}
                      variant="primary"
                      size="sm"
                      onClick={handleUploadProfileImage}
                      style={{ marginBottom: 10 }}
                    >
                      Upload
                    </Button>
                  )}
                  {handleDeleteServiceImage.isLoading ? (
                    <div className="text-center">
                      <CircularProgress style={{ margin: "auto" }} />
                    </div>
                  ) : (
                    <Button
                      disabled={handleDeleteServiceImage.isLoading}
                      variant="outline-danger"
                      size="sm"
                      style={{ marginBottom: 10, marginLeft: 10 }}
                      onClick={() => {
                        Modal.confirm({
                          title: "Are you sure delete this image?",
                          icon: <ExclamationCircleFilled />,
                          okText: "Yes",
                          okType: "danger",
                          onOk: handleDeleteServiceImage,
                        });
                      }}
                    >
                      Delete image
                    </Button>
                  )}
                </Box>
              </div>
            )}

          <Form>
            {/* <Form.Group className="">
            <Form.Label className="form-label-read">
              {service.verified === "FALSE" ? (
                <div style={{ display: "flex" }}>
                  <p>Not Verified</p>
                  {profileId === service?.profile_id.toString() && (
                    <EditIcon onClick={() => editService(service)} />
                  )}
                </div>
              ) : (
                `Verified Since: ${new Date(service.verified).toLocaleString(
                  "default",
                  { month: "long", year: "numeric", day: "2-digit" }
                )}`
              )}
            </Form.Label>
          </Form.Group> */}

            {service.category_type !== "HOUSING" && service.category_type !== "JOB" && (
              <Link
                style={{ fontSize: "16px", bottomPadding: "10px", color: "black" }}
                to={`/review_details/${serviceId}`}
              >
                Reviews
                {/* See reviews ({service.review_count}) */}
              </Link>
            )}

            <Form.Group
              style={{
                paddingTop: "10px",
                display:
                  !service.first_name || service.first_name === "N.A"
                    ? "none"
                    : "block",
              }}
              controlId="fullName"
              className="form-group-spacing"
            >
              <Form.Label id="fullName" className="form-label-read">
                Full Name
              </Form.Label>
              <Typography textTransform="capitalize">
                {" "}
                {service.first_name || "No Firstname"}{" "}
                {service.last_name || "No lastname"}
              </Typography>
            </Form.Group>

            <Form.Group
              style={{ display: !service.address ? "none" : "block" }}
              controlId="officeLocation"
              className="form-group-spacing"
            >
              <Form.Label className="form-label-read">Office Location</Form.Label>
              <p>{service.address}</p>
            </Form.Group>

            <Form.Group
              style={{ display: !service.neighbourhood ? "none" : "block" }}
              controlId="officeLocation"
              className="form-group-spacing"
            >
              <Form.Label className="form-label-read">Neighbourhood</Form.Label>
              <p>{service.neighbourhood}</p>
            </Form.Group>

            {/* <Form.Group controlId="specialisation" className="form-group-spacing">
            <Form.Label className="form-label-read">Specialisation</Form.Label>
            <p>{service.specialization}</p>
          </Form.Group> */}

            {service.email_address && (
              <Form.Group
                style={{ display: !service.email_address ? "none" : "block" }}
                controlId="emailAddress"
                className="form-group-spacing"
              >
                <Form.Label className="form-label-read mr-16">
                  Email Address
                </Form.Label>
                <Linkify>{service.email_address}</Linkify>
              </Form.Group>
            )}
            <Form.Group
              style={{ display: "none" }}
              controlId="contactNumber"
              className="form-group-spacing"
            >
              <Form.Label className="form-label-read">Contact Number</Form.Label>
              <p>{service.mobile_no}</p>
            </Form.Group>

            <Form.Group
              style={{ display: !service.biography ? "none" : "block" }}
              controlId="biography"
              className="form-group-spacing"
            >
              <Form.Label className="form-label-read">Description</Form.Label>
              <p className="whitespace-pre-line" style={{ whiteSpace: "pre-line" }}>
                {service.biography}
              </p>
            </Form.Group>

            {rearrangeArray(
              service.category_type,
              removeDuplicates(service.attributes)
            ).map((attr, i) => {
              if (attr.attribute === "confirmation") {
                return null; // Skip rendering if attribute is 'confirmation'
              }
              const multiAttr = ["mental health specialization", "specialisation"];
              if (multiAttr.includes(attr.attribute)) {
                return (
                  <Form.Group
                    controlId="biography"
                    className="form-group-spacing"
                    key={i}
                  >
                    {attr.value && (
                      <>
                        <Form.Label
                          style={{ textTransform: "capitalize" }}
                          className="form-label-read"
                        >
                          {attr.attribute}
                        </Form.Label>
                        <p className="whitespace-pre-line">
                          {attr.value
                            ? attr.value.replace(/[{}"]/g, "").replace(/,/g, ", ")
                            : "None"}
                        </p>
                      </>
                    )}
                  </Form.Group>
                );
              }
              return (
                <Form.Group
                  controlId="biography"
                  className="form-group-spacing"
                  key={i}
                >
                  {attr.value && (
                    <>
                      <Form.Label
                        style={{ textTransform: "capitalize" }}
                        className="form-label-read"
                      >
                        {attr.attribute}
                      </Form.Label>
                      <section className="whitespace-pre-line">
                        <Linkify options={{ target: "blank" }}>
                          {isValidSGNum(attr.value) ? (
                            <p style={{ whiteSpace: "pre-line" }}>
                              <a
                                href={`tel:${attr.value}`}
                                className="text-blue-600 hover:text-blue-800"
                              >
                                {attr.value}
                              </a>
                            </p>
                          ) : attr.attribute.toLowerCase() === "availability" ? (
                            formatDateDDMMYYYY(attr.value)
                          ) : (
                            <p className=" whitespace-pre-line">{attr.value}</p>
                          )}
                        </Linkify>
                      </section>
                    </>
                  )}
                </Form.Group>
              );
            })}

            {profileId !== service?.profile_id.toString() && (
              <Link
                to={`/reports/?firstname=${service.first_name}&lastname=${service.last_name}&serviceId=${service._id}`}
                className=" p-italic"
              >
                Report bugs or suggest edits
              </Link>
            )}
          </Form>
        </Col>
        <UpdateServiceModal
          isModalOpen={isModalOpen}
          handleCancel={handleCancel}
          data={{ ...selectedService }}
          onFinish={(values) => handleUpdateService(values)}
          addTags={(values) => {
            setTags(values);
          }}
          initialTags={initialTags}
          isSubmitting={updateServiceMutation.isLoading}
        />
        <div className="mb-64 mt-32">
          <Divider />
        </div>
      </Container>
      <DashboardFooter isBottom={isBottom} />
    </div>
  );
};

export default ReadPage;
