import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { getStatus } from "../features/user/userSlice";
const PrivateRoute = () => {
  const isLoggedIn = useSelector(getStatus);
  const isDemoCodeEntered = localStorage.getItem("demoCodeEntered");
  const isIntroVisited = localStorage.getItem("introIsVisited");

  if (isLoggedIn) {
    return <Outlet />;
  } else {
    if (isDemoCodeEntered) {
      if (isIntroVisited) {
        return <Navigate to="/login" />;
      } else {
        return <Navigate to="/intro" />;
      }
    } else {
      return <Navigate to="/splash" />;
    }
  }
};

export default PrivateRoute;
