import { configureStore } from "@reduxjs/toolkit";
import dashboardReducer from "../dashboard/DashboardStore";
const rootReducer = {
  dashboard: dashboardReducer,
};

const store = configureStore({
  reducer: rootReducer,
});

export type RootState = ReturnType<typeof store.getState>;
export const selectCategory = (state: RootState) => state.dashboard.category;
export const selectSearch = (state: RootState) => state.dashboard.search;
export const selectPage = (state: RootState) => state.dashboard.page;

export default store;
