import { Box, CircularProgress, TextField } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { message } from "antd";
import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { resendOTP } from "../../api/auth";
import BackButton from "../common/BackButton";

const ResendOtp = () => {
  const navigate = useNavigate();
  const [mb, setMB] = useState("");
  const [loading, setLoading] = useState(false);

  const resendMutation = useMutation({
    mutationFn: resendOTP,
    onSuccess: () => {
      message.success("OTP Successfully sent!");
      navigate(-1);
    },
    onError: (error) => {
      const STATUS = error.response.status;
      //console.log("mobile", mb);
      if (STATUS === 429) {
        setLoading(false);
        message.error("Slow down! You're making too many requests");
        return;
      }
      message.error("Error during verification");
    },
  });

  const handleSubmit = () => {
    setLoading(true);
    resendMutation.mutate({ values: { mobile_number: mb } });
  };

  return (
    <Box>
      <BackButton onClick={() => navigate(-1)} />
      <Box
        sx={{
          width: {
            md: 400,
          },
          margin: "auto",
        }}
      >
        <div style={{ margin: "0 10px" }}>
          <Form.Group controlId="email" className="form-group-spacing">
            <Form.Label>
              {" "}
              Enter your mobile number to resend verification code
            </Form.Label>
            <TextField
              type="text"
              placeholder="Enter your number"
              fullWidth
              value={mb}
              onChange={(e) => setMB(e.target.value)}
              variant="standard"
              inputProps={{ className: "custom-input" }}
              InputLabelProps={{ className: "small-placeholder" }}
            />
          </Form.Group>
          {false ? (
            <div className="text-center">
              <CircularProgress style={{ margin: "auto" }} />
            </div>
          ) : (
            <Button
              variant="secondary"
              type="submit"
              disabled={loading}
              className="custom-rainbow-button mt-3"
              onClick={() => handleSubmit()}
            >
              Submit
            </Button>
          )}
        </div>
      </Box>
    </Box>
  );
};

export default ResendOtp;
