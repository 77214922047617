import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setEmail, setToken, userStatus } from "../../features/user/userSlice";
const Logout = () => {
  const [showLogoutMessage, setShowLogoutMessage] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleLogout = () => {
    localStorage.removeItem("isProfileComplete");
    localStorage.removeItem("authToken");
    localStorage.removeItem("email");
    localStorage.removeItem("services");
    localStorage.removeItem("profileId");
    localStorage.removeItem("total_count");
    localStorage.removeItem("category");
    localStorage.removeItem("categoryRectangle");
    dispatch(userStatus(false));
    dispatch(setToken(""));
    dispatch(setEmail(""));
  };

  const handleCancel = () => {
    setShowLogoutMessage(false);
    navigate(-1); // This line is used to go back to the previous screen
  };

  return (
    <Container>
      <Row className="justify-content-center">
        <Col xs={10} sm={8} md={6} lg={4}>
          <div className="text-center mt-4">
            {showLogoutMessage && (
              <>
                <h5>Are you sure you want to logout?</h5>
                <p>
                  <span
                    style={{
                      marginRight: "10px",
                      color: "black",
                      cursor: "pointer",
                    }}
                    onClick={handleLogout}
                  >
                    Confirm
                  </span>
                  <span
                    style={{ color: "grey", cursor: "pointer" }}
                    onClick={handleCancel}
                  >
                    Cancel
                  </span>
                </p>
              </>
            )}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Logout;
