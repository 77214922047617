import { Box } from "@mui/material";
import { Button } from "antd";
import PropTypes from "prop-types";
import React from "react";
import { Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const PLACEHOLDER_IMAGE =
  "https://static.vecteezy.com/system/resources/previews/004/141/669/non_2x/no-photo-or-blank-image-icon-loading-images-or-missing-image-mark-image-not-available-or-image-coming-soon-sign-simple-nature-silhouette-in-frame-isolated-illustration-vector.jpg";

const ServicePagesItem = ({
  _id = 0,
  name = "John Doe",
  since = "Since April 2021",
  description = "",
  onClick = () => {},
  serviceImage,
}) => {
  const navigate = useNavigate();

  return (
    <Col xs={12} md={9} className="p-4">
      <p>{name || "No name"}</p>
      <div
        style={{ marginLeft: "35px", width: 300, height: 200 }}
        onClick={() => onClick()}
      >
        <img
          src={serviceImage || PLACEHOLDER_IMAGE}
          alt="Profile"
          style={{
            marginLeft: -35,
            width: 300,
            height: 200,
            objectFit: "cover",
          }}
        />
        {/* <small>{`${userData.base_url}${userData.image_urls[0]}`}</small> */}
      </div>
      <p> {since}</p>
      <p>{description}</p>
      <Button onClick={() => navigate(`/requests/${_id}`)}>
        View Change Requests
      </Button>
    </Col>
  );
};

ServicePagesItem.propTypes = {
  _id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  since: PropTypes.string.isRequired,
  office: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default ServicePagesItem;
