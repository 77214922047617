export const removeDuplicates = (jsonData) => {
  const uniqueAttributes = new Set();
  const updatedData = [];

  jsonData.forEach((item) => {
    const { attribute, value } = item;
    const attributeValue = JSON.stringify(value); // Convert value to string for accurate comparison

    const attributeKey = `${attribute}:${attributeValue}`;
    if (!uniqueAttributes.has(attributeKey)) {
      uniqueAttributes.add(attributeKey);
      updatedData.push({ attribute, value });
    }
  });

  return updatedData;
};

export const removeIDDuplicates = (data) => {
  // Create a new Set to track unique _id values
  const uniqueIds = new Set();

  // Filter the response array
  const filteredData = data.response.filter((item) => {
    // Check if _id is already in the Set
    if (uniqueIds.has(item._id)) {
      // If yes, filter out this item (it's a duplicate)
      return false;
    } else {
      // If not, add _id to the Set and keep this item
      uniqueIds.add(item._id);
      return true;
    }
  });

  // Return the filtered data
  return filteredData;
};
