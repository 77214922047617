/* eslint-disable no-unused-vars */
import { Button, Col, Form, Input, Modal, Row, Select } from "antd";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../styles/antform.css";
import Category from "../common/Category";

const UpdateServiceModal = ({
  isModalOpen,
  handleCancel,
  data,
  onFinish,
  addTags,
  initialTags,
  isSubmitting,
}) => {
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  //console.log;

  const navigate = useNavigate();

  const handleAddTags = (tags) => {
    addTags(tags);
  };

  const [form] = Form.useForm();

  const [category, setCategory] = useState(data.category_type.toLowerCase());
  const [neighbourhood, setNeighbourhood] = useState("");

  data?.attributes?.forEach((attr, i) => {
    if (attr.attribute === "availability") {
      // data.attributes[i].value = dayjs(attr.value);
    } else {
      // data[attr.attribute] = attr.value;
      const multiAttr = ["mental health specialization", "specialisation"];
      if (multiAttr.includes(attr.attribute)) {
        // //console.log(attr.value.replace(/{|}/g, "").split(","));
        try {
          data[attr.attribute] = attr.value.replace(/[{}"]/g, "").split(",");
        } catch (err) {
          //console.log(err);
          data[attr.attribute] = [];
        }
      } else {
        data[attr.attribute] = attr.value;
      }
    }
    //console.log(attr.value);
  });

  //console.log(data.attributes);

  return (
    <Modal
      open={isModalOpen}
      onCancel={handleCancel}
      width={700}
      destroyOnClose={true}
      maskClosable={false}
      footer={null}
    >
      <Row justify="center" align="middle" style={{ padding: "20px" }}>
        <Col xs={24} sm={20} md={16} lg={12} xl={10} xxl={8}>
          <Form
            {...layout}
            onFinish={onFinish}
            style={{ maxWidth: 550 }}
            form={form}
            initialValues={{ data }}
          >
            <Form.Item
              name="name"
              label="Name"
              extra={
                category === "health"
                  ? "Doctor or therapist’s name"
                  : category === "legal"
                  ? "Lawyer’s name"
                  : category === "job"
                  ? "eg. Graphic Designer"
                  : category === "housing"
                  ? "eg. 3 room HDB in Potong Pasir or 2 Bedroom Condo in Tanjong Pagar"
                  : "Select a category first"
              }
              rules={[{ required: true, message: "Required" }]}
            >
              <Input />
            </Form.Item>

            <Form.Item name="first_name" label="Name" style={{ display: "none" }}>
              <Input value="N.A" />
            </Form.Item>
            <Form.Item name="last_name" label="Name" style={{ display: "none" }}>
              <Input value="N.A" />
            </Form.Item>

            {/* <Form.Item
              name="mobile_no"
              label="Mobile"
              rules={[
                { min: 10, message: "Should be at least 10 values" },
                { required: true, message: "Required" },
              ]}
            >
              <Input type="number" />
            </Form.Item> */}

            <Form.Item
              name="category_type"
              label="Category"
              rules={[{ required: true, message: "Required" }]}
            >
              <Select
                style={{ maxWidth: 350 }}
                onChange={(value) => {
                  setCategory(value);
                }}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "").includes(input)
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                options={[
                  { value: "legal", label: "Legal" },
                  { value: "job", label: "Jobs" },
                  { value: "health", label: "Healthcare" },
                  { value: "housing", label: "Housing" },
                ]}
              />
            </Form.Item>

            {/* category */}
            {/* <Category category={category} /> */}
            {data.category_type && (
              <Category data={data} category={data.category_type.toLowerCase()} />
            )}

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                style={{ width: "100%" }}
                loading={isSubmitting}
              >
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </Modal>
  );
};
export default UpdateServiceModal;
