import { ThemeProvider } from "@emotion/react";
import { Route, Routes } from "react-router-dom";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import "./App.css";
import PrivateRoute from "./components/PrivateRoute";

import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import Login from "./components/login/Login";
import CreatePage from "./components/pages/CreatePage";
import ReadPage from "./components/pages/ReadPage";
import Registration from "./components/registration/Registration";
import ReviewDetails from "./components/reviews/ReviewDetails";

import Dashboard from "./components/dashboard/Dashboard";

import Homepage from "./components/dashboard/Homepage.jsx";
import CreateReview from "./components/reviews/CreateReview";

import FAQ from "./components/common/FAQ";
import Help from "./components/common/Help";
import Invite from "./components/common/Invite";
import Logout from "./components/login/Logout";

import Main from "./components/common/Main";
import PrivacyPolicy from "./components/common/PrivacyPolicy";
import Save from "./components/common/Save";
import Settings from "./components/common/Settings";
import TermsAndCondition from "./components/common/TermsAndCondition";
import MyPages from "./components/pages/MyPages";
import ReviewPanel from "./components/reviews/ReviewPanel";

import ChangePassword from "./components/login/ChangePassword";
import ForgotPassword from "./components/login/ForgotPassword";
import Intro from "./components/login/Intro";
import Otp from "./components/login/Otp";
import ResendOtp from "./components/login/ResendOtp";
import Splash from "./components/login/Splash";
import ChangeRequests from "./components/pages/ChangeRequests";
import Reports from "./components/pages/Reports";
import Profile from "./components/profile/Profile";
import PublicRoute from "./components/PublicRoute";
import NotFound from "./components/routes/NotFound";
import { getStatus } from "./features/user/userSlice";
import { theme } from "./theme";

const queryClient = new QueryClient();

function App() {
  const isLoggedIn = useSelector(getStatus);

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <Box sx={{ width: "100%" }}>
          <Routes>
            <Route path="/" element={<PublicRoute />}>
              {" "}
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Registration />} />
              <Route path="/register-privacy" element={<PrivacyPolicy />} />
              <Route path="/register-terms" element={<TermsAndCondition />} />
              <Route path="/otp" element={<Otp />} />
              <Route path="/resend-otp" element={<ResendOtp />} />
              <Route path="/intro" element={<Intro />} />
              <Route path="/splash" element={<Splash />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/change-password" element={<ChangePassword />} />
            </Route>

            <Route path="/" element={<PrivateRoute />}>
              <Route path="/" element={<Main />}>
                <Route path={`/me/:userId`} element={<Profile />} />
                <Route path="*" element={<NotFound />} />
                <Route index element={<Homepage />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/homepage" element={<Homepage />} />
                <Route
                  path="/review_details/:serviceId"
                  element={<ReviewDetails />}
                />
                <Route path="/create_review" element={<CreateReview />} />
                {/* <Route
                  path="/create_review_existing/:serviceId"
                  element={<CreateReviewExisting />}
                /> */}
                <Route path="/read_page/:serviceId" element={<ReadPage />} />
                <Route path="/create_page" element={<CreatePage />} />
                <Route path="/mypages" element={<MyPages />} />
                <Route path="/review_panel" element={<ReviewPanel />} />
                <Route path="/settings" element={<Settings />} />
                <Route path="/save" element={<Save />} />
                <Route path="/faq" element={<FAQ />} />
                <Route path="/help" element={<Help />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/terms-and-condition" element={<TermsAndCondition />} />
                <Route path="/invite" element={<Invite />} />
                <Route path="/logout" element={<Logout />} />
                <Route path="/requests/:serviceId" element={<ChangeRequests />} />
              </Route>
              <Route path="/profile" element={<Profile />} />
              <Route path="/reports" element={<Reports />} />
            </Route>
          </Routes>
        </Box>
      </ThemeProvider>
    </QueryClientProvider>
  );
}

export default App;
