import React from "react";

const FloatingActionButton = () => {
  return (
    <button className="floating-action-button black circular align-right">
      <span className="plus-icon"></span>
    </button>
  );
};

export default FloatingActionButton;
