import { DatePicker, Form, Input, Radio, Select, Space } from "antd";
import dayjs from "dayjs";
import { neighbourhoodOptions } from "./Neighbourhood";

// const initialValue = moment("2023-06-14T20:45:55.678Z");
export const legalSpecialisation = [
  {
    value: "Accident and Personal Injury Claims",
    label: "Accident and Personal Injury Claims",
  },
  {
    value: "Arbitration & Dispute Resolution",
    label: "Arbitration & Dispute Resolution",
  },
  { value: "Bankruptcy & Insolvency", label: "Bankruptcy & Insolvency" },
  {
    value: "Conveyancing, Landlord & Tenant",
    label: "Conveyancing, Landlord & Tenant",
  },
  { value: "Civil Litigation (General)", label: "Civil Litigation (General)" },
  { value: "Divorce & Family Matters", label: "Divorce & Family Matters" },
  { value: "Funds & Asset Management", label: "Funds & Asset Management" },
  { value: "Criminal", label: "Criminal" },
  { value: "Intellectual Property", label: "Intellectual Property" },
  { value: "Personal Data Protection", label: "Personal Data Protection" },
  { value: "Medical Negligence", label: "Medical Negligence" },
  {
    value: "Media, Internet & Information Technology",
    label: "Media, Internet & Information Technology",
  },
  { value: "Tax Practice & Planning", label: "Tax Practice & Planning" },
  { value: "Trusts, Wills & Probate", label: "Trusts, Wills & Probate" },
];

export const Legal = () => {
  return (
    <>
      <Form.Item
        name="profession"
        label="Profession"
        rules={[{ required: true, message: "Required" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="firm"
        label="Firm"
        rules={[{ required: true, message: "Required" }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="contact"
        label="Contact (Public Info)"
        rules={[{ required: true, message: "Required" }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="email"
        label="Email (Public Info)"
        rules={[{ required: true, message: "Required" }]}
      >
        <Input />
      </Form.Item>

      <Form.Item name="website" label="Website (Public Info)">
        <Input />
      </Form.Item>

      <Form.Item
        name="specialisation"
        label="Specialisation"
        rules={[{ required: true, message: "Required" }]}
      >
        <Select
          mode="multiple"
          style={{ maxwidth: 350 }}
          onChange={(value) => {
            //console.log(value);
          }}
          options={legalSpecialisation}
        />
      </Form.Item>

      <Form.Item
        name="about me"
        label="About Me"
        rules={[{ required: true, message: "Required" }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="facebook"
        label="Company Facebook Page"
        extra="(If applicable)"
      >
        <Input />
      </Form.Item>

      <Form.Item name="instagram" label="Company Instagram" extra="(If applicable)">
        <Input />
      </Form.Item>

      <div className="legal-radio">
        <b>Submitted by myself or on behalf of my company</b>

        <Form.Item
          name="confirmation"
          label=""
          rules={[{ required: true, message: "Required" }]}
        >
          <Radio.Group name="confirmation" style={{ display: "flex" }}>
            <Space direction="vertical">
              <Radio value="Yes, I am a LGBTQ-affirming service provider">
                Yes, I am a LGBTQ-affirming service provider
              </Radio>
              <Radio value="No, I am submitting on behalf of a colleague with permission, and they are LGBTQ affirming">
                No, I am submitting on behalf of a colleague with permission, and
                they are LGBTQ affirming
              </Radio>
              <Radio value="No, but I have used their services before, or I am aware that they are LGBTQ affirming. (We will contact them to verify)">
                <div style={{ maxWidth: 350 }}>
                  No, but I have used their services before, or I am aware that they
                  are LGBTQ affirming. (We will contact them to verify)
                </div>
              </Radio>
            </Space>
          </Radio.Group>
        </Form.Item>
      </div>
    </>
  );
};
export const healthCareSpecialisation = [
  { value: "Audiology", label: "Audiology" },
  { value: "Alternative Medicine", label: "Alternative Medicine" },
  { value: "Cardiology", label: "Cardiology" },
  { value: "Chiropractors", label: "Chiropractors" },
  { value: "Dentistry", label: "Dentistry" },
  { value: "Dermatology", label: "Dermatology" },
  { value: "Dietitian", label: "Dietitian" },
  { value: "Fertility Health", label: "Fertility Health" },
  { value: "General Health", label: "General Health" },
  { value: "Geriatrics", label: "Geriatrics" },
  { value: "Hormonal Health", label: "Hormonal Health" },
  { value: "Mental Health", label: "Mental Health" },
  { value: "Neurology", label: "Neurology" },
  { value: "Occupational Therapy", label: "Occupational Therapy" },
  { value: "Optometry", label: "Optometry" },
  { value: "Orthotics, Prosthetics", label: "Orthotics, Prosthetics" },
  { value: "Pediatrics", label: "Pediatrics" },
  { value: "Physiotherapy", label: "Physiotherapy" },
  { value: "Podiatry", label: "Podiatry" },
  { value: "Private Nurses", label: "Private Nurses" },
  { value: "Sexual Health", label: "Sexual Health" },
  { value: "Speech and Language", label: "Speech and Language" },
  { value: "Trans Health", label: "Trans Health" },
];

const mentalHealthSpecialisation = [
  { value: "Anger Management", label: "Anger Management" },
  { value: "Addiction", label: "Addiction" },
  { value: "Attachment Trauma", label: "Attachment Trauma" },
  { value: "Couples Therapy", label: "Couples Therapy" },
  { value: "Complex PTSD", label: "Complex PTSD" },
  { value: "Grief & Loss", label: "Grief & Loss" },
  { value: "Gender and Sexuality", label: "Gender and Sexuality" },
  { value: "Mood & Anxiety Disorders", label: "Mood & Anxiety Disorders" },
  { value: "Neurodevelopmental Disorders", label: "Neurodevelopmental Disorders" },
  { value: "Parenting", label: "Parenting" },
  { value: "Personality Disorders", label: "Personality Disorders" },
  { value: "Relational Difficulties", label: "Relational Difficulties" },
  { value: "Trauma & Abuse", label: "Trauma & Abuse" },
  { value: "Stress & Emotional Regulation", label: "Stress & Emotional Regulation" },
  { value: "Children", label: "Children" },
  { value: "Adolescents", label: "Adolescents" },
  { value: "Young Adults", label: "Young Adults" },
  { value: "Adults", label: "Adults" },
];

export const Health = () => {
  return (
    <>
      <Form.Item
        name="specialisation"
        label="Specialisation"
        rules={[{ required: true, message: "Required" }]}
      >
        <Select
          style={{ maxwidth: 350 }}
          mode="multiple"
          onChange={(value) => {
            //console.log(value);
          }}
          options={healthCareSpecialisation}
        />
      </Form.Item>

      <Form.Item name="profession" label="Profession">
        <Input />
      </Form.Item>

      <Form.Item
        name="clinic"
        label="Clinic/Hospital/Organisation"
        extra="(if applicable)"
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="contact"
        label="Contact (Public Info)"
        rules={[{ required: true, message: "Required" }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="email"
        label="Email (Public Info)"
        rules={[{ required: true, message: "Required" }]}
      >
        <Input />
      </Form.Item>

      <Form.Item name="website" label="Website (Public Info)">
        <Input />
      </Form.Item>

      <Form.Item
        name="about me"
        label="About Me"
        rules={[{ required: true, message: "Required" }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="mental health specialization"
        label="Area of Specialization"
        extra="For Mental Health Service Providers only"
      >
        <Select
          style={{ maxwidth: 350 }}
          mode="multiple"
          onChange={(value) => {
            //console.log(value);
          }}
          options={mentalHealthSpecialisation}
        />
      </Form.Item>

      <Form.Item
        name="facebook"
        label="Company Facebook Page"
        extra="(If applicable)"
      >
        <Input />
      </Form.Item>

      <Form.Item name="instagram" label="Company Instagram" extra="(If applicable)">
        <Input />
      </Form.Item>

      <div style={{ maxWidth: 350 }} className="legal-radio">
        <b style={{ marginBottom: "50" }}>
          Submitted by myself or on behalf of my company
        </b>
        <Form.Item
          name="confirmation"
          label=""
          rules={[{ required: true, message: "Required" }]}
        >
          <Radio.Group name="confirmation" style={{ display: "flex" }}>
            <Space direction="vertical">
              <Radio value="Yes">Yes</Radio>
              <Radio value="No, I am submitting on behalf of a colleague with permission">
                No, I am submitting on behalf of a colleague with permission
              </Radio>
              <Radio value="No, I have used their service before and they are LGBTQ affirming, hence I am submitting on behalf of someone I know without permission">
                <div style={{ maxwidth: 350 }}>
                  No, I have used their service before and they are LGBTQ affirming,
                  hence I am submitting on behalf of someone I know without
                  permission
                </div>
              </Radio>
            </Space>
          </Radio.Group>
        </Form.Item>
      </div>
    </>
  );
};

export const jobIndustrySpecialisation = [
  { value: "Accountancy", label: "Accountancy" },
  { value: "Advertising", label: "Advertising" },
  { value: "Aerospace", label: "Aerospace" },
  { value: "Agriculture", label: "Agriculture" },
  { value: "Air Transport", label: "Air Transport" },
  { value: "Architecture", label: "Architecture" },
  {
    value: "Arts, Entertainment and Recreation",
    label: "Arts, Entertainment and Recreation",
  },
  { value: "Beauty Services", label: "Beauty Services" },
  {
    value: "Biopharmaceuticals Manufacturing",
    label: "Biopharmaceuticals Manufacturing",
  },
  { value: "Built Environment", label: "Built Environment" },
  { value: "Consultancy", label: "Consultancy" },
  { value: "Design", label: "Design" },
  { value: "Delivery", label: "Delivery" },
  {
    value: "Early Childhood Care and Education",
    label: "Early Childhood Care and Education",
  },
  { value: "Education", label: "Education" },
  { value: "Electronics", label: "Electronics" },
  { value: "Energy and Chemicals", label: "Energy and Chemicals" },
  { value: "Energy and Power", label: "Energy and Power" },
  { value: "Environmental Services", label: "Environmental Services" },
  { value: "Finance", label: "Finance" },
  { value: "Food Manufacturing", label: "Food Manufacturing" },
  { value: "F&B", label: "F&B" },
  { value: "Healthcare", label: "Healthcare" },
  {
    value: "Hotel and Accommodation Services",
    label: "Hotel and Accommodation Services",
  },
  { value: "Human Resource", label: "Human Resource" },
  {
    value: "Information and Communications Technology",
    label: "Information and Communications Technology",
  },
  { value: "Insurance", label: "Insurance" },
  { value: "Landscape", label: "Landscape" },
  { value: "Legal", label: "Legal" },
  { value: "Logistics", label: "Logistics" },
  {
    value: "Marine and Offshore Engineering",
    label: "Marine and Offshore Engineering",
  },
  { value: "Media", label: "Media" },
  { value: "Non-Profit", label: "Non-Profit" },
  { value: "Precision Engineering", label: "Precision Engineering" },
  { value: "Public Service (Education)", label: "Public Service (Education)" },
  { value: "Public Transport", label: "Public Transport" },
  { value: "Real Estate", label: "Real Estate" },
  { value: "Retail", label: "Retail" },
  { value: "Sea Transport", label: "Sea Transport" },
  { value: "Security", label: "Security" },
  { value: "Social Service", label: "Social Service" },
  { value: "Tourism", label: "Tourism" },
  {
    value: "Training and Adult Education",
    label: "Training and Adult Education",
  },
  { value: "Wholesale Trade", label: "Wholesale Trade" },
];

export const jobIndustryTenure = [
  { value: "Full-Time", label: "Full-Time" },
  { value: "Part-Time", label: "Part-Time" },
  { value: "Contract", label: "Contract" },
  { value: "Temp", label: "Temp" },
  { value: "Internship", label: "Internship" },
];

export const workplaceType = [
  { value: "On-site", label: "On-site" },
  { value: "Hybrid", label: "Hybrid" },
  { value: "Remote", label: "Remote" },
];

const jobConfirmation = [
  { value: "Yes", label: "Yes" },
  { value: "No", label: "No" },
];

export const Job = () => {
  return (
    <>
      {/* <Form.Item
        name="job title"
        label="Job Title"
        rules={[{ required: true, message: "Required" }]}
      >
        <Input />
      </Form.Item> */}

      <Form.Item
        name="salary"
        label="Salary Range"
        rules={[{ required: true, message: "Required" }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="position"
        label="Position"
        rules={[{ required: true, message: "Required" }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="industry"
        label="Industry"
        rules={[{ required: true, message: "Required" }]}
      >
        <Select
          style={{ maxwidth: 350 }}
          onChange={(value) => {
            //console.log(value);
          }}
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) => (option?.label ?? "").includes(input)}
          filterSort={(optionA, optionB) =>
            (optionA?.label ?? "")
              .toLowerCase()
              .localeCompare((optionB?.label ?? "").toLowerCase())
          }
          options={jobIndustrySpecialisation}
        />
      </Form.Item>

      <Form.Item name="company" label="Company">
        <Input />
      </Form.Item>

      <Form.Item name="company address" label="Company Address">
        <Input />
      </Form.Item>

      <Form.Item name="work place type" label="Workplace Type">
        <Select
          style={{ maxwidth: 350 }}
          onChange={(value) => {
            //console.log(value);
          }}
          options={workplaceType}
        />
      </Form.Item>

      <Form.Item name="tenure" label="Tenure">
        <Select
          style={{ maxwidth: 350 }}
          onChange={(value) => {
            //console.log(value);
          }}
          options={jobIndustryTenure}
        />
      </Form.Item>

      <Form.Item
        name="job description"
        label="Job Description"
        extra="Details of job, salary range, commitment required etc."
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="contact"
        label="Contact (Public info)"
        extra="Name, Email or URL to Job Listing"
      >
        <Input />
      </Form.Item>

      <div
        style={{
          maxWidth: 350,
          display: "flex",
          alignItems: "stretch",
          flexDirection: "column",
          margin: "auto",
        }}
      >
        <p style={{ margin: "auto" }}>
          <strong>
            I am a representative of the company, and confirm that the company and
            this job listing is inclusive to all.
          </strong>
        </p>
        <Form.Item
          name="confirmation"
          label=""
          rules={[{ required: true, message: "Required" }]}
        >
          <Radio.Group name="confirmation" style={{ display: "flex" }}>
            <Radio value="Yes">Yes</Radio>
            <Radio value="No">No</Radio>
          </Radio.Group>
        </Form.Item>
      </div>
    </>
  );
};

export const housingCategory = [
  { value: "HDB Common room", label: "HDB Common room" },
  { value: "HDB Master room", label: "HDB Master room" },
  { value: "Landed Property", label: "Landed Property" },
  { value: "HDB Flat", label: "HDB Flat" },
  { value: "Condominium Unit", label: "Condominium Unit" },
  { value: "Apartment Unit", label: "Apartment Unit" },
  { value: "Condominium Room", label: "Condominium Room" },
  { value: "Apartment room", label: "Apartment room" },
  { value: "Landed Property Room", label: "Landed Property Room" },
];

export const bedrooms = [
  { value: "Studio", label: "Studio" },
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "2 + 1", label: "2 + 1" },
  { value: "3", label: "3" },
  { value: "3 + 1", label: "3 + 1" },
  { value: "4", label: "4" },
  { value: "4 + 1", label: "4 + 1" },
  { value: "5", label: "5" },
  { value: "More than 5", label: "More than 5" },
];

const toilets = [
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
  { value: "More than 5", label: "More than 5" },
];

const floorLevels = [
  { value: "Low floor", label: "Low floor" },
  { value: "Medium floor", label: "Medium floor" },
  { value: "High floor", label: "High floor" },
];

export const Housing = ({ data }) => {
  const date = data?.attributes?.find((attr) => attr?.attribute === "availability");
  return (
    <>
      <Form.Item
        name="listing type"
        label="Listing Type"
        rules={[{ required: true, message: "Required" }]}
      >
        <Select
          style={{ maxwidth: 350 }}
          onChange={(value) => {
            //console.log(value);
          }}
          options={[
            {
              value: "For Sale",
              label: "For Sale",
            },
            { value: "For Rent (Entire home)", label: "For Rent (Entire home)" },
            {
              value: "For Rent (Room only)",
              label: "For Rent (Room only)",
            },
          ]}
        />
      </Form.Item>

      <Form.Item
        name="category"
        label="Housing Category"
        rules={[{ required: true, message: "Required" }]}
      >
        <Select
          style={{ maxwidth: 350 }}
          onChange={(value) => {
            //console.log(value);
          }}
          options={housingCategory}
        />
      </Form.Item>

      <Form.Item
        name="availability"
        initialValue={date ? dayjs(data.value) : null}
        label="Availability (Date)"
      >
        <DatePicker />
      </Form.Item>

      <Form.Item
        name="existing tenancy"
        label="Existing Tenancy"
        extra="Existing tenants (if any)"
      >
        <Select
          style={{ maxwidth: 350 }}
          onChange={(value) => {
            //console.log(value);
          }}
          options={[
            {
              value: "Yes",
              label: "Yes",
            },
            { value: "No", label: "No" },
          ]}
        />
      </Form.Item>

      <Form.Item
        name="location"
        label="Location"
        rules={[{ required: true, message: "Required" }]}
      >
        <Select
          style={{ maxwidth: 350 }}
          onChange={(value) => {
            //console.log(value);
          }}
          options={neighbourhoodOptions}
        />
      </Form.Item>

      <Form.Item
        name="bedrooms"
        label="Bedrooms"
        rules={[{ required: true, message: "Required" }]}
      >
        <Select
          style={{ maxwidth: 350 }}
          onChange={(value) => {
            //console.log(value);
          }}
          options={bedrooms}
        />
      </Form.Item>

      <Form.Item name="toilets" label="Toilets">
        <Select
          style={{ maxwidth: 350 }}
          onChange={(value) => {
            //console.log(value);
          }}
          options={toilets}
        />
      </Form.Item>

      <Form.Item
        name="floor area in sqft"
        label="Floor area in sqft"
        extra="Optional for room rental"
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="price"
        label="Price"
        extra="For eg. '$380,000 (negotiable)' or '$2500 monthly'"
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="description"
        label="Description"
        extra="Home details, features, availability, tenanted or not, location, transportation etc"
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="furnishing"
        label="Furnishing"
        rules={[{ required: true, message: "Required" }]}
      >
        <Select
          style={{ maxwidth: 350 }}
          onChange={(value) => {
            //console.log(value);
          }}
          options={[
            {
              value: "Furnished",
              label: "Furnished",
            },
            { value: "Semi-furnished", label: "Semi-furnished" },
            {
              value: "Unfurnished",
              label: "Unfurnished",
            },
          ]}
        />
      </Form.Item>

      <Form.Item name="floor level" label="Floor level">
        <Select
          style={{ maxwidth: 350 }}
          onChange={(value) => {
            //console.log(value);
          }}
          options={floorLevels}
        />
      </Form.Item>

      <Form.Item
        name="contact"
        label="Contact (Public info)"
        extra="Name and Phone No. (Whatsapp or Telegram)"
      >
        <Input />
      </Form.Item>

      <div style={{ maxWidth: 350 }} className="legal-radio">
        <b>
          I confirm that this is a LGBTQ-inclusive housing option, submitted by a:
        </b>

        <Form.Item
          name="confirmation"
          label=""
          rules={[{ required: true, message: "Required" }]}
        >
          <Radio.Group name="confirmation" style={{ display: "flex" }}>
            <Space direction="vertical">
              <Radio value="LGBTQ-friendly Realtor">LGBTQ-friendly Realtor</Radio>
              <Radio value="LGBTQ-friendly Owner/Landlord">
                LGBTQ-friendly Owner/Landlord
              </Radio>
              <Radio value="LGBTQ-friendly Tenant">
                <div style={{ width: 300 }}>LGBTQ-friendly Tenant</div>
              </Radio>
            </Space>
          </Radio.Group>
        </Form.Item>
      </div>
    </>
  );
};

const Category = ({ data, category, onChange }) => {
  const CATEGORIES = {
    legal: Legal,
    health: Health,
    job: Job,
    housing: Housing,
  };

  //console.log(category);

  const CategoryValue = CATEGORIES[category];

  if (!category) return null;
  return <CategoryValue data={data} />;
};

export default Category;
