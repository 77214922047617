/* eslint-disable no-unused-vars */
import { Box, CircularProgress, TextField, Typography } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { message } from "antd";
import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { addServiceBugsReports } from "../../api/auth";
import { getEmail, getToken } from "../../features/user/userSlice";
import BackButton from "../common/BackButton";

const Reports = () => {
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [searchParams] = useSearchParams();
  const [reportData, setReportData] = useState({
    title: "",
    content: "",
  });
  const firstname = searchParams.get("firstname");
  const lastname = searchParams.get("lastname");
  const serviceId = searchParams.get("serviceId");

  const token = useSelector(getToken);
  const email = useSelector(getEmail);

  const bugReportMutation = useMutation({
    mutationFn: addServiceBugsReports,
    onSuccess: () => {
      message.success("Successfully added a bug report");
      setError("");
      navigate(-1);
    },
    onError: () => {
      message.error("Error while adding bug report");
      setError("A service bug report already exists for this service and profile");
    },
  });

  const handleChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;

    setReportData({ ...reportData, [name]: value });
  };

  const handleSubmit = () => {
    bugReportMutation.mutate({
      token,
      username: email,
      values: { ...reportData, service_id: serviceId },
    });
  };

  return (
    <Box>
      <BackButton onClick={() => navigate(-1)} />
      <Box
        sx={{
          maxWidth: "400px",
          margin: "auto",
          padding: "0 15px",
        }}
      >
        <Form.Group controlId="email" className="form-group-spacing">
          <Typography mb={3} textAlign="center">
            Suggest edits or report bugs for this listing
          </Typography>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <TextField
              label="Title"
              id="outlined-start-adornment"
              sx={{ width: "100%", marginTop: 10, mb: 2 }}
              name="title"
              onChange={handleChange}
              value={reportData.title}
            />
            <TextField
              id="outlined-multiline-static"
              label="Enter your suggestion or explain the bug."
              multiline
              rows={5}
              sx={{ width: "100%" }}
              name="content"
              onChange={handleChange}
              value={reportData.content}
            />
          </Box>

          <small style={{ color: "red" }}>{error}</small>

          {false ? (
            <div className="text-center">
              <CircularProgress style={{ margin: "auto" }} />
            </div>
          ) : (
            <Button
              variant="secondary"
              type="submit"
              className="custom-rainbow-button mt-3"
              onClick={handleSubmit}
              disabled={!reportData.content || !reportData.title}
            >
              Submit
            </Button>
          )}

          <Typography
            className=" p-italic"
            variant="body2"
            mt={2}
            textAlign="center"
          >
            You cannot edit once submitted
          </Typography>
        </Form.Group>
      </Box>
    </Box>
  );
};

export default Reports;
