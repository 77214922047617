import PropTypes from "prop-types";
import React from "react";
import { Nav, Navbar } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import PROUT_SEARCH_WHITE from "../../../images/prout_search_white.png";
import { setSearchOpen } from "../../features/user/userSlice";
import "../../styles/commons.css";
import BackButton from "../common/BackButton";
import BackgroundVideo from "../common/BackgroundVideo"; // Ensure this path is correct
import ProutWhite from "./ProutWhite";
const Header = ({ toggleMenu, title, isBack = false }) => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  let isDashboard = pathname === "/dashboard" || pathname === "/";
  let headerHeight = isDashboard ? "h-36" : "h-24";
  return (
    <div className={`relative w-full ${headerHeight}`}>
      <BackgroundVideo height={headerHeight} />

      <Navbar expand={false} className="absolute inset-0">
        <Nav
          className="ml-auto flex flex-col w-full"
          style={{ marginLeft: "1.5rem" }}
        >
          {isDashboard ? (
            <div>
              <div className="flex justify-between items-center w-full content-center">
                <Navbar.Brand href="#home" className="mt-0">
                  <div className="title">
                    <ProutWhite mb="mb-0" />
                  </div>
                </Navbar.Brand>
                <Navbar.Toggle
                  className=" border-transparent navbar-dark"
                  style={{ marginRight: "1.2rem" }}
                  aria-controls="basic-navbar-nav"
                  onClick={toggleMenu}
                />
              </div>
              <div className="flex items-center w-full content-center mt-3">
                <button
                  onClick={() => dispatch(setSearchOpen(true))}
                  style={{ border: "none", background: "none", padding: 0 }}
                >
                  <img
                    src={PROUT_SEARCH_WHITE}
                    alt="Clickable"
                    style={{ cursor: "pointer" }}
                  />
                </button>
                <input
                  onClick={() => dispatch(setSearchOpen(true))}
                  type="text"
                  placeholder="Tap here to search..."
                  value=""
                  onChange={(e) => setCode(e.target.value)}
                  onKeyDown={() => dispatch(setSearchOpen(true))}
                  className="flex-grow px-2.5 py-1 border-0 rounded-xl ml-4 mr-8 focus:outline-none font-extralight placeholder-gray-400"
                />
              </div>
            </div>
          ) : (
            <div className="flex justify-between items-center w-full content-center">
              <BackButton className="back-button" />
              <div className="flex-grow flex justify-center mt-6">
                <Navbar.Brand href="#home" className="mx-auto">
                  <div className="title">
                    <ProutWhite />
                  </div>
                </Navbar.Brand>
              </div>
              <Navbar.Toggle
                style={{ marginRight: "1.2rem" }}
                aria-controls="basic-navbar-nav"
                onClick={toggleMenu}
              />
            </div>
          )}
        </Nav>

        {/* <div className="navbar-header-border"></div> */}
      </Navbar>
    </div>
  );
};

Header.propTypes = {
  toggleMenu: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  isBack: PropTypes.bool,
};

export default Header;
