import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  Alert,
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { login } from "../../api/auth";
import {
  setEmail as reduxSetEmail,
  setIsLoggedIn,
  setProfileId,
  setToken,
} from "../../features/user/userSlice";
import "../../styles/login.css";
import Prout from "../common/Prout";
import RainbowButton from "../common/RainbowButton";

interface LoginProps {}

const Login: React.FC<LoginProps> = () => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const token = await login(email, password);
      localStorage.setItem("authToken", token.response.token);
      localStorage.setItem("profileId", token.response.profileId._id);
      localStorage.setItem("email", token.response.profileId.email_address);
      localStorage.setItem("isProfileComplete", token.response.isProfileComplete);

      setErrorMessage("");
      dispatch(setIsLoggedIn(true));
      dispatch(setProfileId(token.response.profileId._id.toString()));
      dispatch(setToken(token.response.token));
      dispatch(reduxSetEmail(token.response.profileId.email_address));
      // Redirect the user to the dashboard or another protected route
      navigate("/");
    } catch (error: any) {
      const STATUS = error.response.status;
      //console.log(STATUS);
      if (STATUS === 429) {
        return setErrorMessage("Slow down! You're making too many requests");
      }
      if (STATUS === 401) {
        return navigate("/otp");
      }
      setErrorMessage("Invalid email or password");
    } finally {
      setIsLoading(false);
    }
  };

  const createAccount = () => {
    // Your custom logic here
    //console.log("ca function called");
    navigate("/register");
  };

  const [showPassword, setShowPassword] = useState<boolean>(false);

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  return (
    <Container className="mt-5" fluid>
      <Row className="justify-content-center mx-5 mx-md-0">
        <Col md={6}>
          <div className=" mt-20 mb-20">
            <Prout />
          </div>

          <Form onSubmit={handleSubmit}>
            {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
            <Form.Group controlId="email" className="form-group-spacing">
              <Form.Label className=" text-red-500">Sign In</Form.Label>
              <TextField
                type="email"
                placeholder="Email address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                fullWidth
                variant="standard"
                inputProps={{ className: "custom-input" }}
                InputLabelProps={{ className: "small-placeholder" }}
              />
            </Form.Group>

            <Form.Group controlId="password" className="form-group-spacing">
              <Form.Label className="text-green-500">Password</Form.Label>
              <TextField
                type={showPassword ? "text" : "password"}
                placeholder="Enter your password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                fullWidth
                variant="standard"
                inputProps={{ className: "custom-input" }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleTogglePasswordVisibility}
                        size="small"
                      >
                        {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <span
                className="gray-link d-block mt-2 text-base font-light"
                onClick={() => navigate("/forgot-password")}
                style={{ cursor: "pointer", marginTop: "30px" }}
              >
                Forgot password
              </span>
            </Form.Group>
            {isLoading ? (
              <div className="text-center">
                <CircularProgress style={{ margin: "auto" }} />
              </div>
            ) : (
              // <Button
              //   variant="secondary"
              //   type="submit"
              //   className="custom-rainbow-button mt-16 text-black"
              // >
              //   Enter
              // </Button>

              <RainbowButton type="submit">Enter</RainbowButton>
            )}
          </Form>
          <br></br>
          <span
            className="gray-link d-block text-center cursor-pointer mt-2 text-base font-light"
            onClick={() => createAccount()}
          >
            Create Account
          </span>
        </Col>
      </Row>
    </Container>
  );
};

export default Login;
