import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setExpanded } from "../../features/user/userSlice";

const Invite = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setExpanded(false));
  });
  return (
    <div>
      <br />
      <center>Under Construction</center>
    </div>
  );
};

export default Invite;
