import React from "react";
import proutImage from "../../../images/prout_logo.png";

const Prout: React.FC = () => {
  return (
    <h2 className="text-center mb-4">
      <img style={{ width: 70 }} src={proutImage} alt="prout" />
    </h2>
  );
};

export default Prout;
