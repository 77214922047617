import { Box, CircularProgress, TextField, Typography } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { message } from "antd";
import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { verify } from "../../api/auth";
import BackButton from "../common/BackButton";
import RainbowButton from "../common/RainbowButton";

const Otp = () => {
  const navigate = useNavigate();
  const [vt, setVt] = useState("");

  const verifyMutation = useMutation({
    mutationFn: verify,
    onSuccess: () => {
      message.success("Successfully verified");
      navigate("/login");
    },
    onError: (error) => {
      const STATUS = error.response.status;
      if (STATUS === 429) {
        message.error("Slow down! You're making too many requests");
        return;
      }
      message.error("Error during verification");
    },
  });

  const handleSubmit = () => {
    verifyMutation.mutate({ vt });
  };

  return (
    <Box>
      <BackButton onClick={() => navigate("/login")} />
      <Box
        sx={{
          width: {
            md: 400,
          },
          margin: "auto",
        }}
      >
        <div style={{ margin: "0 15px" }}>
          <Form.Group controlId="email" className="form-group-spacing">
            <Form.Label>
              Looks like your account is not yet verified. Enter the verification
              code sent to your mobile number to verify
            </Form.Label>
            <TextField
              type="text"
              placeholder="Enter your otp"
              fullWidth
              value={vt}
              onChange={(e) => setVt(e.target.value)}
              variant="standard"
              inputProps={{ className: "custom-input" }}
              InputLabelProps={{ className: "small-placeholder" }}
            />
          </Form.Group>
          <Link to="/resend-otp" style={{ textDecoration: "none" }}>
            <Typography color="gray">Resend?</Typography>
          </Link>
          {verifyMutation.isLoading ? (
            <div className="text-center">
              <CircularProgress style={{ margin: "auto" }} />
            </div>
          ) : (
            // <Button
            //   variant="secondary"
            //   type="submit"
            //   className="custom-rainbow-button mt-3"
            //   onClick={handleSubmit}
            // >
            //   Submit
            // </Button>
            <RainbowButton className="mt-3" type="submit" onClick={handleSubmit}>
              Enter
            </RainbowButton>
          )}
        </div>
      </Box>
    </Box>
  );
};

export default Otp;
