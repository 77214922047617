import { faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CircularProgress from "@mui/material/CircularProgress";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Col } from "react-bootstrap";
// import HelpfulButton from "../reviews/HelpfulButton";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { message } from "antd";
import { useSelector } from "react-redux";
import { voteReview } from "../../api/auth";
import { getEmail, getToken } from "../../features/user/userSlice";
import RatingStars from "../reviews/RatingStars";

const ReviewItem = ({
  reviewer,
  since,
  rating,
  review,
  reviewId,
  like_count,
  dislike_count,
  title,
  isLast,
  userVote,
  serviceId,
  user_image_url,
}) => {
  const customColor = "#808080";

  const userVote_ = userVote === true ? true : null;
  const token = useSelector(getToken);
  const email = useSelector(getEmail);
  const [helpfulLoading, setHelpfulLoading] = useState(false);
  const [notHelpfulLoading, setNotHelpfulLoading] = useState(false);
  const queryClient = useQueryClient();
  const [like, setLike] = useState(userVote);
  const image_url = import.meta.env.VITE_API_BASE_URL + "/images/" + user_image_url;

  // const [dislike, setDislike] = useState(userVote ? false : false);
  const voteMutation = useMutation({
    mutationFn: voteReview,
    onSuccess: () => {
      message.success("Successfully added a vote");
      setHelpfulLoading(false);
      setNotHelpfulLoading(false);
      queryClient.invalidateQueries(["reviews", serviceId]);
    },
    onError: () => {
      message.error("Error while adding vote");
      setHelpfulLoading(false);
      setNotHelpfulLoading(false);
      setLike((prev) => !prev);
    },
  });

  const handleHelpfulClick = (value) => {
    if (value) {
      setHelpfulLoading(true);
      setLike((prev) => !prev);
      // setDislike((prev) => !prev);
    } else {
      setNotHelpfulLoading(true);
      // setDislike((prev) => !prev);
      setLike((prev) => !prev);
    }
    voteMutation.mutate({
      token,
      username: email,
      vote: value,
      review_id: reviewId,
    });
  };

  return (
    <Col>
      <br />
      <div style={{ display: "flex", alignItems: "center" }}>
        {user_image_url ? (
          <img
            src={image_url}
            alt="User Profile"
            style={{
              width: "32px",
              height: "32px",
              borderRadius: "50%",
              marginRight: "8px",
            }}
          />
        ) : (
          <div
            style={{
              width: "32px",
              height: "32px",
              borderRadius: "50%",
              marginRight: "8px",
              backgroundColor: "#808080",
            }}
          ></div>
        )}
        <p>{reviewer}</p>
      </div>
      <p className="p-italic">{since}</p>
      <RatingStars maxStars={5} rating={rating} />
      {/* <p>{title}</p> */}
      <p>&quot;{review}&quot;</p>
      <div style={{ display: "flex", marginBottom: "0.5rem" }}>
        {helpfulLoading ? (
          <CircularProgress size={18} />
        ) : (
          <div onClick={() => handleHelpfulClick(true)}>
            <FontAwesomeIcon
              style={{ color: customColor }}
              icon={faThumbsUp}
              className={`mr-2 ${like ? "text-primary" : ""}`}
            />
          </div>
        )}
        &nbsp;
        <p
          className="d-inline-block mb-0 p-italic"
          style={{ marginRight: "1.25rem" }}
        >
          Helpful ({like_count})
        </p>
        {/* {notHelpfulLoading ? (
          <CircularProgress size={18} />
        ) : (
          <div onClick={() => handleHelpfulClick(false)}>
            <FontAwesomeIcon
              style={{ color: customColor }}
              icon={faThumbsDown}
              className={`mr-2 ${dislike ? "text-danger" : ""}`}
            />
            &nbsp;
            <p
              className="d-inline-block mb-0 p-italic"
              style={{ marginRight: "1.25rem" }}
            >
              Not Helpful ({dislike_count})
            </p>
          </div>
        )} */}
      </div>
      {!isLast && <div className="navbar-header-border-any"></div>}
    </Col>
  );
};

ReviewItem.propTypes = {
  reviewer: PropTypes.string.isRequired,
  since: PropTypes.string.isRequired,
  rating: PropTypes.number.isRequired,
  review: PropTypes.string.isRequired,
};

export default ReviewItem;
