import { createTheme } from "@mui/material";

export const theme = createTheme({
  typography: {
    fontFamily: ["Helvetica Neue", "sans-serif"].join(","),
    logo: {
      fontFamily: "Segoe UI",
      fontSize: "20px",
      fontWeight: "600",
    },
    formTitle: {
      fontFamily: "Segoe UI",
      fontSize: "30px",
      fontWeight: "700",
      marginBottom: "20px",
    },
    bannerTitle: {
      fontFamily: ["Georgia", "sans-serif"].join(","),
      fontSize: "2.7rem",
      fontWeight: "600",
      color: "white",
    },
    count: {
      fontSize: "2rem",
      fontFamily: ["Arial", "Helvetica", "sans-serif"],
      fontWeight: 800,
      color: "#d4d4d4",
    },
    blogTitle: {
      fontSize: "16px",
      fontFamily: ["Helvetica Neue", "Helvetica", "sans-serif"].join(","),
      fontWeight: 700,
      lineHeight: "20px",
    },
    caption: {
      color: "#797a7a",
    },
    error: {
      color: "red",
      fontFamily: ["Helvetica Neue", "Helvetica", "sans-serif"].join(","),
      fontSize: "0.75rem",
      lineHeight: "1.66",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 481,
      md: 769,
      lg: 1025,
      xl: 1201,
    },
  },
});
