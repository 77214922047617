/* eslint-disable no-unused-vars */
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Button, Col, Form, Input, Row, Select, message } from "antd";
import ImageCompression from "browser-image-compression";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { addService, uploadServiceImage } from "../../api/auth";
import { getEmail, getToken } from "../../features/user/userSlice";
import "../../styles/antform.css";
import Category from "../common/Category";
import { neighbourhoodOptions } from "../common/Neighbourhood";

const CreatePage = () => {
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  const navigate = useNavigate();

  const handleAddTags = (tags) => {
    addTags(tags);
  };
  const [form] = Form.useForm();
  const [profileImage, setProfileImage] = useState();
  const [profileImageLink, setProfileImageLink] = useState();
  const [imageError, setImageError] = useState("");

  const [category, setCategory] = useState("");
  const [neighbourhood, setNeighbourhood] = useState("");
  const [tags, setTags] = useState([]);
  const categoryHandler = (values, category) => {
    const attributes = [];
    const CATEGORIES = {
      legal: [
        "firm",
        "specialisation",
        "confirmation",
        "profession",
        "contact",
        "email",
        "website",
        "about me",
        "facebook",
        "instagram",
      ],
      health: [
        "clinic",
        "specialisation",
        "profession",
        "confirmation",
        "contact",
        "email",
        "website",
        "about me",
        "facebook",
        "instagram",
        "mental health specialization",
      ],
      job: [
        "salary",
        "position",
        "tenure",
        "company",
        "contact",
        "company address",
        "industry",
        "work place type",
        // "job title",
        "job description",
        "confirmation",
      ],
      housing: [
        "listing type",
        "category",
        "availability",
        "existing tenancy",
        "bedrooms",
        "toilets",
        "floor area in sqft",
        "price",
        "location",
        "description",
        "furnishing",
        "floor level",
        "contact",
        "confirmation",
      ],
    };
    const keys = CATEGORIES[category];

    keys.forEach((key) => {
      attributes.push({ attribute: key, value: values[key] });
      delete values[key];
    });

    return attributes;
  };

  const token = useSelector(getToken);
  const email = useSelector(getEmail);
  const queryClient = useQueryClient();

  const updloadImageMutation = useMutation({
    mutationFn: uploadServiceImage,
    onSuccess: () => {
      message.success("Successfully added a service");
      form.resetFields();
      queryClient.invalidateQueries(["services"]);
      navigate("/mypages");
    },
    onError: () => {
      navigate("/mypages");
      //message.error("Error while adding data");
    },
  });

  const addServiceMutation = useMutation({
    mutationFn: addService,
    onSuccess: (data) => {
      //console.log(data);
      message.success("Successfully added a service");
      updloadImageMutation.mutate({
        token,
        username: email,
        serviceImage: profileImage,
        serviceId: data.id,
      });
    },
    onError: () => {
      message.error("Error while  adding a service");
    },
  });

  const create = (values) => {
    const category = values["category_type"];
    const attributes = categoryHandler(values, category);
    values.attributes = JSON.stringify(attributes);
    values.category_type = values.category_type.toUpperCase();
    values["tags"] = tags;

    //console.log("profileImage", profileImage);

    addServiceMutation.mutate({ username: email, token, values });
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];

    const options = {
      maxSizeMB: 0.6,
      maxWidthOrHeight: 1300,
      useWebWorker: true,
    };

    try {
      const compressedFile = await ImageCompression(file, options);
      //console.log("Original File:", file);
      //console.log("Compressed file:", compressedFile);

      // Add the file extension back to the compressed file if it's missing
      if (!compressedFile.name.includes(".")) {
        const fileExtension = file.name.split(".").pop();
        compressedFile.name += `.${fileExtension}`;
      }

      if (compressedFile && compressedFile.size < 3 * 1024 * 1024) {
        setImageError("");
        setProfileImage(compressedFile);
        setProfileImageLink(URL.createObjectURL(compressedFile));
      } else {
        setImageError("The file size exceeds the limit");
      }
    } catch (error) {
      console.error("Error during image compression:", error);
      setImageError("Error during image compression");
    }
  };

  return (
    <Row justify="center" align="middle" style={{ padding: "20px" }}>
      <Col xs={24} sm={20} md={16} lg={12} xl={10} xxl={8}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: 20,
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img
            src={
              profileImageLink ||
              "https://www.vhv.rs/dpng/d/312-3120300_default-profile-hd-png-download.png"
            }
            alt="Profile"
            className="create-profile-image"
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <input
              accept=".png,.jpg,.jpeg"
              type="file"
              style={{
                marginLeft: 100,
                fontSize: "16px",
                paddingTop: "10px",
              }}
              onChange={handleFileChange}
              name="image"
            />
            <small
              style={{
                textAlign: "center",
                color: "red",
                marginBottom: 10,
                marginTop: 5,
              }}
            >
              {imageError}
            </small>
          </div>
        </div>
        <Form
          {...layout}
          onFinish={(values) => {
            create(values);
          }}
          style={{ maxWidth: 1000 }}
          form={form}
        >
          <Form.Item
            name="category_type"
            label="Category"
            rules={[{ required: true, message: "Required" }]}
          >
            <Select
              style={{ maxWidth: 350 }}
              onChange={(value) => {
                setCategory(value);
              }}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) => (option?.label ?? "").includes(input)}
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "").toLowerCase())
              }
              options={[
                { value: "legal", label: "Legal" },
                { value: "job", label: "Job" },
                { value: "health", label: "Healthcare" },
                { value: "housing", label: "Housing" },
              ]}
            />
          </Form.Item>

          <Form.Item
            name="name"
            label="Listing Name"
            extra={
              category === "health"
                ? "Doctor or therapist’s name"
                : category === "legal"
                ? "Lawyer’s name"
                : category === "job"
                ? "eg. Graphic Designer"
                : category === "housing"
                ? "eg. 3 room HDB in Potong Pasir or 2 Bedroom Condo in Tanjong Pagar"
                : "Select a category first"
            }
            rules={[{ required: true, message: "Required" }]}
          >
            <Input />
          </Form.Item>
          <Category category={category} onChange={(value) => {}} />
          <br />
          <Form.Item
            name="first_name"
            label="First Name"
            style={{ display: "none" }}
            initialValue="N.A"
            // rules={[{ required: true, message: 'Required' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="last_name"
            label="Last Name"
            initialValue="N.A"
            style={{ display: "none" }}
            // rules={[{ required: true, message: 'Required' }]}
          >
            <Input />
          </Form.Item>
          {/* <Form.Item
            name="mobile_no"
            label="Contact (Private)"
            extra="Will not be published. The team from Prout may contact you to verify the listing"
            rules={[
              { min: 10, message: "Should be at least 10 values" },
              // { required: true, message: 'Required' },
            ]}
          >
            <Input type="number" />
          </Form.Item> */}
          <Form.Item
            name="email_address"
            label="Email"
            style={{ display: "none" }}
            // rules={[{ required: true, message: 'Required' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="office"
            label="Office"
            style={{ display: "none" }}
            // rules={[{ required: true, message: 'Required' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="neighbourhood"
            label="Neighbourhood"
            style={{ display: "none" }}
            // rules={[{ required: true, message: 'Required' }]}
          >
            <Select
              style={{ width: 350 }}
              onChange={(value) => {
                setNeighbourhood(value);
              }}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) => (option?.label ?? "").includes(input)}
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "").toLowerCase())
              }
              options={neighbourhoodOptions}
            />
          </Form.Item>
          <Form.Item
            name="address"
            label="Address"
            style={{ display: "none" }}
            // rules={[{ required: true, message: 'Required' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="postal_code"
            label="Postal Code"
            style={{ display: "none" }}
            rules={[
              // { required: true, message: 'Required' },
              { min: 5, message: "Mininum of 5" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="description"
            label="Description"
            style={{ display: "none" }}
            // rules={[{ required: true, message: 'Required' }]}
          >
            <Input.TextArea />
          </Form.Item>

          <Form.Item
            name="biography"
            label="Biography"
            style={{ display: "none" }}
            // rules={[{ required: true, message: 'Required' }]}
          >
            <Input.TextArea />
          </Form.Item>

          {/* <Form.Item extra="optional" label="Add Tags" name="tags">
            <Tags addTags={(values) => handleAddTags(values)} />
          </Form.Item> */}

          {/* category */}
          {/* <Category
            category={category}
            onChange={(value) => //console.log(value)}
          /> */}

          {/* {category === 'legal' && (
            <>
              <Form.Item
                name="firm"
                label="Firm"
                rules={[{ required: true, message: 'Required' }]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="specialisation"
                label="specialisation"
                rules={[{ required: true, message: 'Required' }]}
              >
                <Select
                  style={{ width: 350 }}
                  onChange={(value) => {
                    //console.log(value);
                  }}
                  options={[
                    {
                      value: 'accident',
                      label: 'Accident and Personal Injury Claims',
                    },
                    { value: 'criminal', label: 'Criminal' },
                  ]}
                />
              </Form.Item>
            </>
          )} */}
          <div
            style={{
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              type="primary"
              htmlType="submit"
              style={{ width: "70%", margin: "auto" }}
              // loading={isSubmitting}
            >
              Submit
            </Button>
          </div>
        </Form>
      </Col>
    </Row>
  );
};
export default CreatePage;
