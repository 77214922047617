import { Box, CircularProgress, TextField } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { message } from "antd";
import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { forgotPassword } from "../../api/auth";
import BackButton from "../common/BackButton";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");

  const verifyMutation = useMutation({
    mutationFn: forgotPassword,
    onSuccess: () => {
      message.success("Successfully requested a change password");
      navigate("/change-password");
    },
    onError: (err) => {
      const STATUS = err.response.status;
      if (STATUS === 429) {
        message.error("Slow down! You're making too many requests");
        return;
      }
      message.error("Error during request");
    },
  });

  const handleSubmit = () => {
    verifyMutation.mutate({ email_address: email });
  };

  return (
    <Box>
      <BackButton onClick={() => navigate("/login")} />
      <Box
        sx={{
          width: {
            md: 400,
          },
          margin: "auto",
        }}
      >
        <div style={{ margin: "0 15px" }}>
          <Form.Group controlId="email" className="form-group-spacing">
            <Form.Label>Enter your email to reset your password.</Form.Label>
            <TextField
              type="text"
              placeholder="Enter your email"
              fullWidth
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              variant="standard"
              inputProps={{ className: "custom-input" }}
              InputLabelProps={{ className: "small-placeholder" }}
            />
          </Form.Group>
          {verifyMutation.isLoading ? (
            <div className="text-center">
              <CircularProgress style={{ margin: "auto" }} />
            </div>
          ) : (
            <Button
              variant="secondary"
              type="submit"
              className="custom-rainbow-button mt-3"
              onClick={handleSubmit}
            >
              Submit
            </Button>
          )}
        </div>
      </Box>
    </Box>
  );
};

export default ForgotPassword;
