export const neighbourhoodOptions = [
  {
    value: "D01 - Raffles Place, Marina, Cecil",
    label: "D01 - Raffles Place, Marina, Cecil",
  },
  {
    value: "D02 - Tanjong Pagar, Chinatown",
    label: "D02 - Tanjong Pagar, Chinatown",
  },
  {
    value: "D03 - Tiong Bahru, Alexandra, Queenstown",
    label: "D03 - Tiong Bahru, Alexandra, Queenstown",
  },
  {
    value: "D04 - Mount Faber, Telok Blangah, Harbourfront",
    label: "D04 - Mount Faber, Telok Blangah, Harbourfront",
  },
  {
    value: "D05 - Buona Vista, Pasir Panjang, Clementi",
    label: "D05 - Buona Vista, Pasir Panjang, Clementi",
  },
  { value: "D06 - Clarke Quay, City Hall", label: "D06 - Clarke Quay, City Hall" },
  {
    value: "D07 - Bugis, Beach Road, Golden Mile",
    label: "D07 - Bugis, Beach Road, Golden Mile",
  },
  {
    value: "D08 - Little India, Farrer Park",
    label: "D08 - Little India, Farrer Park",
  },
  {
    value: "D09 - Orchard Road, River Valley",
    label: "D09 - Orchard Road, River Valley",
  },
  {
    value: "D10 - Bukit Timah, Holland, Balmoral",
    label: "D10 - Bukit Timah, Holland, Balmoral",
  },
  { value: "D11 - Novena, Newton, Thomson", label: "D11 - Novena, Newton, Thomson" },
  {
    value: "D12 - Toa Payoh, Serangoon, Balestier",
    label: "D12 - Toa Payoh, Serangoon, Balestier",
  },
  { value: "D13 - Macpherson, Braddell", label: "D13 - Macpherson, Braddell" },
  {
    value: "D14 - Geylang, Paya Lebar, Sims",
    label: "D14 - Geylang, Paya Lebar, Sims",
  },
  {
    value: "D15 - Joo Chiat, Marina Parade, Katong",
    label: "D15 - Joo Chiat, Marina Parade, Katong",
  },
  {
    value: "D16 - Bedok, Upper East Coast, Siglap",
    label: "D16 - Bedok, Upper East Coast, Siglap",
  },
  { value: "D17 - Changi, Flora, Loyang", label: "D17 - Changi, Flora, Loyang" },
  { value: "D18 - Tampines, Pasir Ris", label: "D18 - Tampines, Pasir Ris" },
  {
    value: "D19 - Punggol, Sengkang, Serangoon Gardens",
    label: "D19 - Punggol, Sengkang, Serangoon Gardens",
  },
  {
    value: "D20 - Ang Mo Kio, Bishan, Thomson",
    label: "D20 - Ang Mo Kio, Bishan, Thomson",
  },
  {
    value: "D21 - Upper Bukit Timah, Ulu Pandan, Clementi Park",
    label: "D21 - Upper Bukit Timah, Ulu Pandan, Clementi Park",
  },
  { value: "D22 - Boon Lay, Jurong, Tuas", label: "D22 - Boon Lay, Jurong, Tuas" },
  {
    value: "D23 - Choa Chu Kang, Diary Farm, Hillview, Bukit Panjang, Bukit Batok",
    label: "D23 - Choa Chu Kang, Diary Farm, Hillview, Bukit Panjang, Bukit Batok",
  },
  {
    value: "D24 - Kranji, Lim Chu Kang, Tengah",
    label: "D24 - Kranji, Lim Chu Kang, Tengah",
  },
  { value: "D25 - Woodlands, Admiralty", label: "D25 - Woodlands, Admiralty" },
  { value: "D26 - Upper Thomson, Mandai", label: "D26 - Upper Thomson, Mandai" },
  {
    value: "D27 - Sembawang, Yishun, Admiralty",
    label: "D27 - Sembawang, Yishun, Admiralty",
  },
  { value: "D28 - Yio Chu Kang, Seletar", label: "D28 - Yio Chu Kang, Seletar" },
  { value: "Others", label: "Others" },
];
