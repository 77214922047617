import React from "react";
// import React from "react";
import { Col } from "react-bootstrap";

// const PrivacyPolicy = () => {
//   return (
//     <Col xs={12} md={9} className="p-4">

const PrivacyPolicy = () => {
  return (
    <Col className=" m-10">
      <h2>Data Protection Notice for Users</h2>
      <p>
        This Data Protection Notice (“Notice”) sets out the basis which PROUT PTE LTD
        (“we”, “us”, or “our”) may collect, use, disclose or otherwise process
        personal data of users of the Prout platform www.prout.app (“App”) in
        accordance with the Personal Data Protection Act (“PDPA”). This Notice
        applies to personal data in our possession or under our control, including
        personal data in the possession of organisations which we have engaged to
        collect, use, disclose or process personal data for our purposes.
      </p>
      <h3>PERSONAL DATA</h3>
      <ol start="1">
        <li>
          As used in this Notice:
          <p className=" mt-3">
            <b>“user”</b> means an individual who (a) has contacted us through any
            means to find out more about the services we provide, (b) is registered
            to use, and uses the services provided by the App; and
          </p>
          <p>
            <b>“personal data”</b> means data, whether true or not, about a user who
            can be identified: (a) from that data; or (b) from that data and other
            information to which we have or are likely to have access.
          </p>
        </li>
        <li>
          Depending on the nature of your interaction with us, some examples of
          personal data which we may collect from you include your email address,
          telephone number, gender, and photograph.
        </li>
        <li>
          Other terms used in this Notice shall have the meanings given to them in
          the PDPA (where the context so permits).
        </li>
      </ol>
      <h3>COLLECTION, USE AND DISCLOSURE OF PERSONAL DATA</h3>
      <ol start="4">
        <li>
          We generally do not collect your personal data unless (a) it is provided to
          us voluntarily by you directly or via a third party who has been duly
          authorised by you to disclose your personal data to us (your{" "}
          <b>“authorised representative”</b>) after (i) you (or your authorised
          representative) have been notified of the purposes for which the data is
          collected, and (ii) you (or your authorised representative) have provided
          written consent to the collection and usage of your personal data for those
          purposes, or (b) collection and use of personal data without consent is
          permitted or required by the PDPA or other laws. We shall seek your consent
          before collecting any additional personal data and before using your
          personal data for a purpose which has not been notified to you (except
          where permitted or authorised by law).
        </li>
        <li>
          We may collect and use your personal data for any or all of the following
          purposes:
          <ol type="a">
            <li>
              performing obligations in the course of or in connection with our
              provision of the services requested by you;
            </li>
            <li>verifying your identity;</li>
            <li>
              responding to, handling, and processing queries, requests,
              applications, complaints, and feedback from you;
            </li>
            <li>managing your relationship with us;</li>
            <li>
              complying with any applicable laws, regulations, codes of practice,
              guidelines, or rules, or to assist in law enforcement and
              investigations conducted by any governmental and/or regulatory
              authority;
            </li>
            <li>any other purposes for which you have provided the information;</li>
            <li>
              transmitting to any unaffiliated third parties including our third
              party service providers and agents, and relevant governmental and/or
              regulatory authorities, whether in Singapore or abroad, for the
              aforementioned purposes; and
            </li>
            <li>
              any other incidental business purposes related to or in connection with
              the above.
            </li>
          </ol>
        </li>
        <li>
          We may disclose your personal data:
          <ol type="a">
            <li>
              where such disclosure is required for performing obligations in the
              course of or in connection with our provision of the services requested
              by you; or
            </li>
            <li>
              to third party service providers, agents and other organisations we
              have engaged to perform any of the functions with reference to the
              above mentioned purposes.
            </li>
          </ol>
        </li>
        <li>
          The purposes listed in the above clauses may continue to apply even in
          situations where your relationship with us has been terminated or altered
          in any way, for a reasonable period thereafter (including, where
          applicable, a period to enable us to enforce our rights under a contract
          with you).
        </li>
      </ol>
      <h3>RELIANCE ON THE LEGITIMATE INTERESTS EXCEPTION</h3>
      <ol start="8">
        <li>
          In compliance with the PDPA, we may collect, use or disclose your personal
          data without your consent for the legitimate interests of PROUT PTE LTD or
          another person. In relying on the legitimate interests exception of the
          PDPA, PROUT PTE LTD will assess the likely adverse effects on the
          individual and determine that the legitimate interests outweigh any adverse
          effect.
        </li>
        <li>
          In line with the legitimate interests’ exception, we will collect, use or
          disclose your personal data for the following purposes:
          <ol type="a">
            <li>Fraud detection and prevention;</li>
            <li>Detection and prevention of misuse of services.</li>
          </ol>
        </li>
        <li>
          The purposes listed in the above clause may continue to apply even in
          situations where your relationship with us (for example, pursuant to a
          contract) has been terminated or altered in any way, for a reasonable
          period thereafter.
        </li>
      </ol>
      <h3>WITHDRAWING YOUR CONSENT</h3>
      <ol start="10">
        <li>
          The consent that you provide for the collection, use and disclosure of your
          personal data will remain valid until such time it is being withdrawn by
          you in writing (via email). You may withdraw consent and request us to stop
          collecting, using and/or disclosing your personal data for any or all of
          the purposes listed above by submitting your request via email to our Data
          Protection Officer at the contact details provided below.
        </li>
        <li>
          Upon receipt of your written request to withdraw your consent, we may
          require reasonable time (depending on the complexity of the request and its
          impact on our relationship with you) for your request to be processed and
          for us to notify you of the consequences of us acceding to the same,
          including any legal consequences which may affect your rights and
          liabilities to us. In general, we shall seek to process your request within
          ten (10) business days of receiving it.
        </li>
        <li>
          Whilst we respect your decision to withdraw your consent, please note that
          depending on the nature and scope of your request, we may not be in a
          position to continue providing our services to you and we shall, in such
          circumstances, notify you before completing the processing of your request.
          Should you decide to cancel your withdrawal of consent, please inform us in
          writing in the manner described in clause 10 above.
        </li>
        <li>
          Please note that withdrawing consent does not affect our right to continue
          to collect, use and disclose personal data where such collection, use and
          disclose without consent is permitted or required under applicable laws.
        </li>
      </ol>
      <h3>ACCESS TO AND CORRECTION OF PERSONAL DATA</h3>
      <ol start="14">
        <li>
          If you wish to make (a) an access request for access to a copy of the
          personal data which we hold about you or information about the ways in
          which we use or disclose your personal data, or (b) a correction request to
          correct or update any of your personal data which we hold about you, you
          may submit your request via email to our Data Protection Officer at the
          contact details provided below.
        </li>
        <li>
          Please note that a reasonable fee may be charged for an access request. If
          so, we will inform you of the fee before processing your request.
        </li>
        <li>
          We will respond to your request as soon as reasonably possible. In general,
          our response will be within twenty (20) business days. Should we not be
          able to respond to your request within thirty (30) days after receiving
          your request, we will inform you via email within thirty (30) days of the
          time by which we will be able to respond to your request. If we are unable
          to provide you with any personal data or to make a correction requested by
          you, we shall generally inform you of the reasons why we are unable to do
          so (except where we are not required to do so under the PDPA).
        </li>
      </ol>
      <h3>PROTECTION OF PERSONAL DATA</h3>
      <ol start="17">
        <li>
          To safeguard your personal data from unauthorised access, collection, use,
          disclosure, copying, modification, disposal or similar risks, we have
          introduced appropriate administrative, physical and technical measures such
          as: minimised collection of personal data, authentication and access
          controls (such as good password practices, need-to-know basis for data
          disclosure, etc.), up-to-date antivirus protection, web security measures
          against risks, and usage of one time password (otp) to secure access.
        </li>
        <li>
          You should be aware, however, that no method of transmission over the
          Internet or method of electronic storage is completely secure. While
          security cannot be guaranteed, we strive to protect the security of your
          information and are constantly reviewing and enhancing our information
          security measures.
        </li>
      </ol>
      <h3>ACCURACY OF PERSONAL DATA</h3>
      <ol start="19">
        <li>
          We generally rely on personal data provided by you (or your authorised
          representative). In order to ensure that your personal data is current,
          complete and accurate, please update us if there are changes to your
          personal data by informing our Data Protection Officer via email at the
          contact details provided below.
        </li>
      </ol>
      <h3>RETENTION OF PERSONAL DATA</h3>
      <ol start="20">
        <li>
          We may retain your personal data for as long as it is necessary to fulfill
          the purpose for which it was collected, or as required or permitted by
          applicable laws.
        </li>
        <li>
          We will cease to retain your personal data, or remove the means by which
          the data can be associated with you, as soon as it is reasonable to assume
          that such retention no longer serves the purpose for which the personal
          data was collected, and is no longer necessary for legal or business
          purposes.
        </li>
      </ol>
      <h3>TRANSFER OF PERSONAL DATA OUTSIDE OF SINGAPORE</h3>
      <ol start="22">
        <li>
          We generally do not transfer your personal data to countries outside of
          Singapore. However, if we do so, we will obtain your consent for the
          transfer to be made and will take steps to ensure that your personal data
          continues to receive a standard of protection that is at least comparable
          to that provided under the PDPA.
        </li>
      </ol>
      <h3>DATA PROTECTION OFFICER</h3>
      <ol start="23">
        <li>
          You may contact our Data Protection Officer if you have any enquiries or
          feedback on our personal data protection policies and procedures, or if you
          wish to make any request, in the following manner:
        </li>
      </ol>
      <p>Email: dpo@proutapp.com</p>
      <h3>EFFECT OF NOTICE AND CHANGES TO NOTICE</h3>
      <ol start="24">
        <li>
          This Notice applies in conjunction with any other notices, contractual
          clauses and consent clauses that apply in relation to the collection, use
          and disclosure of your personal data by us.
        </li>
        <li>
          We may revise this Notice from time to time without any prior notice. You
          may determine if any such revision has taken place by referring to the date
          on which this Notice was last updated. Your continued use of our services
          constitutes your acknowledgement and acceptance of such changes.
        </li>
      </ol>
      <p>
        <b>Effective date </b>: 25/04/2023
      </p>
      <p>
        <b>Last updated</b> : 25/04/2023
      </p>
    </Col>
  );
};

export default PrivacyPolicy;

// import React from "react";
// import { Col } from "react-bootstrap";

// const PrivacyPolicy = () => {
//   return (
//     <Col xs={12} md={9} className="p-4">
//       <h1>Privacy Policy</h1>
//       <p>Last updated April 25, 2023</p>
//       <h2>Disclaimer</h2>
//       <p>
//         The Services are Available &quot;AS-IS&quot;. Your access to and use of the
//         Services or any Content are at your own risk. You understand and agree that
//         the Services are provided to you on an “AS IS” and “AS AVAILABLE” basis.
//       </p>
//       <p>
//         The “Twitter Entities” refers to Twitter, its parents, affiliates, related
//         companies, officers, directors, employees, agents, representatives, partners,
//         and licensors.
//       </p>
//       <p>
//         Without limiting the foregoing, to the maximum extent permitted under
//         applicable law, THE TWITTER ENTITIES DISCLAIM ALL WARRANTIES AND CONDITIONS,
//         WHETHER EXPRESS OR IMPLIED, OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
//         PURPOSE, OR NON-INFRINGEMENT. The Twitter Entities make no warranty or
//         representation and disclaim all responsibility and liability for: (i) the
//         completeness, accuracy, availability, timeliness, security or reliability of
//         the Services or any Content; (ii) any harm to your computer system, loss of
//         data, or other harm that results from your access to or use of the Services
//         or any Content; (iii) the deletion of, or the failure to store or to
//         transmit, any Content and other communications maintained by the Services;
//         and (iv) whether the Services will meet your requirements or be available on
//         an uninterrupted, secure, or error-free basis. No advice or information,
//         whether oral or written, obtained from the Twitter Entities or through the
//         Services, will create any warranty or representation not expressly made
//         herein.
//       </p>
//       <h2>Privacy Notice</h2>
//       <p>
//         This privacy notice for [Company Name] (doing business as [Company Short
//         Name]) (“Company,” “we,” “us,” or “our“), describes how and why we might
//         collect, store, use, and/or share (“process“) your information when you use
//         our services (“Services“), such as when you:
//       </p>
//       <ul>
//         <li>
//           Visit our website at [Website URL], or any website of ours that links to
//           this privacy notice
//         </li>
//         <li>
//           [Download and use our application(s), such as our mobile application —
//           [Mobile App Name], our Facebook application — [Facebook App Name], or any
//           other application of ours that links to this privacy notice]
//         </li>
//         <li>
//           Engage with us in other related ways ― including any sales, marketing, or
//           events
//         </li>
//       </ul>
//       <p>
//         If you do not agree with our policies and practices, please do not use our
//         Services. If you still have any questions or concerns, please contact us at
//         [Email Address].
//       </p>
//       <h3>Summary of Key Points</h3>
//       <p>
//         This summary provides key points from our privacy notice, but you can find
//         out more details about any of these topics by using our table of contents
//         below to find the section you are looking for.
//       </p>
//       <h3>What personal information do we process?</h3>
//       <p>
//         When you visit, use, or navigate our Services, we may process personal
//         information depending on how you interact with [Company Name] and the
//         Services, the choices you make, and the products and features you use.
//       </p>
//       <h3>Do we process any sensitive personal information?</h3>
//       <p>
//         [We do not process sensitive personal information. / We may process sensitive
//         personal information when necessary with your consent or as otherwise
//         permitted by applicable law.]
//       </p>
//       <h3>Table of Contents</h3>
//       <ol>
//         <li>What information do we collect?</li>
//         <li>How do we process your information?</li>
//         <li>What legal bases do we rely on to process your personal information?</li>
//       </ol>
//       <h3>1. What information do we collect?</h3>
//       <p>
//         In Short: We collect personal information that you provide to us and
//         information that is automatically collected when you visit our Services.
//       </p>
//       <p>
//         We collect personal information that you voluntarily provide to us when you
//         register on the Services, express an interest in obtaining information about
//         us or our products and Services, when you participate in activities on the
//         Services, or otherwise when you contact us.
//       </p>
//       <p>The personal information that we collect may include the following:</p>
//       <ul>
//         <li>[Personal Info Collected]</li>
//         <li>[Personal Info Collected]</li>
//         <li>[Personal Info Collected]</li>
//       </ul>
//       <p>
//         We may also collect information automatically when you visit, use, or
//         navigate the Services, such as your IP address, browser and device
//         characteristics, operating system, language preferences, referring URLs,
//         device name, country, location, information about how and when you use our
//         Services, and other technical information. We also collect information
//         through cookies and similar technologies. You can find out more about this in
//         our Cookie Notice: [Cookie Notice URL].
//       </p>
//       <h3>2. How do we process your information?</h3>
//       <p>
//         In Short: We process your information to provide, improve, and administer our
//         Services, communicate with you, for security and fraud prevention, and to
//         comply with law.
//       </p>
//       <p>
//         We process your personal information for a variety of reasons, depending on
//         how you interact with our Services, including:
//       </p>
//       <ul>
//         <li>
//           To facilitate account creation and authentication and otherwise manage user
//           accounts.
//         </li>
//         <li>
//           To communicate with you, such as through email, about offers, promotions,
//           or changes to our Services.
//         </li>
//         <li>
//           To comply with our legal obligations, resolve disputes, and enforce our
//           agreements.
//         </li>
//         <li>
//           For our legitimate interests, such as to improve our Services and for fraud
//           prevention and security purposes.
//         </li>
//       </ul>
//       <h3>3. What legal bases do we rely on to process your information?</h3>
//       <p>
//         In Short: We only process your personal information when we have a valid
//         legal reason (i.e., legal basis) to do so under applicable law, such as with
//         your consent or to comply with laws.
//       </p>
//       <p>
//         If you are located in the EU or UK, we may rely on the following legal bases
//         to process your personal information:
//       </p>
//       <ul>
//         <li>
//           Consent: We may process your information if you have given us permission
//           (i.e., consent) to use your personal information for a specific purpose.
//           You can withdraw your consent at any time.
//         </li>
//         <li>
//           Contractual necessity: We may process your information to enter into or
//           fulfill our contractual obligations with you.
//         </li>
//         <li>
//           Legal obligation: We may process your information to comply with legal
//           obligations we are subject to, such as tax or financial reporting
//           obligations.
//         </li>
//         <li>
//           Legitimate interests: We may process your information for our legitimate
//           interests, such as to improve our Services and for fraud prevention and
//           security purposes. We will only do so if our interests are not overridden
//           by your privacy rights.
//         </li>
//       </ul>
//       <p>
//         If you are located in Canada, we may process your information with your
//         express consent or in situations where your permission can be inferred (i.e.,
//         implied consent). You can withdraw your consent at any time. We may also be
//         legally permitted to process your information without your consent in certain
//         exceptional cases, such as for investigations and fraud detection and
//         prevention, or for business transactions provided certain conditions are met.
//       </p>
//       <p>
//         Please note that this privacy notice only applies to personal information
//         that we process as a data controller. If we process personal information as a
//         data processor on behalf of our customers, their privacy policies will apply
//         and you should direct any questions you have to them.
//       </p>
//       <h2>Disclaimer</h2>
//       <p>
//         The Services are available &quot;AS-IS&quot;. Your access to and use of the
//         Services or any Content are at your own risk. You understand and agree that
//         the Services are provided to you on an “AS IS” and “AS AVAILABLE” basis. The
//         “Twitter Entities” refers to Twitter, its parents, affiliates, related
//         companies, officers, directors, employees, agents, representatives, partners,
//         and licensors. Without limiting the foregoing, to the maximum extent
//         permitted under applicable law, THE TWITTER ENTITIES DISCLAIM ALL WARRANTIES
//         AND CONDITIONS, WHETHER EXPRESS OR IMPLIED, OF MERCHANTABILITY, FITNESS FOR A
//         PARTICULAR PURPOSE, OR NON-INFRINGEMENT. The Twitter Entities make no
//         warranty or representation and disclaim all responsibility and liability for:
//         (i) the completeness, accuracy, availability, timeliness, security or
//         reliability of the Services or any Content; (ii) any harm to your computer
//         system, loss of data, or other harm that results from your access to or use
//         of the Services or any Content; (iii) the deletion of, or the failure to
//         store or to transmit, any Content and other communications maintained by the
//         Services; and (iv) whether the Services will meet your requirements or be
//         available on an uninterrupted, secure, or error-free basis. No advice or
//         information, whether oral or written, obtained from the Twitter Entities or
//         through the Services, will create any warranty or representation not
//         expressly made herein.
//       </p>
//       <h2>Data Protection Notice for Users</h2>
//       <p>
//         This Data Protection Notice (“Notice”) sets out the basis which PROUT PTE LTD
//         (“we”, “us”, or “our”) may collect, use, disclose or otherwise process
//         personal data of users of the Prout platform www.prout.asia (“App”) in
//         accordance with the Personal Data Protection Act (“PDPA”). This Notice
//         applies to personal data in our possession or under our control, including
//         personal data in the possession of organisations which we have engaged to
//         collect, use, disclose or process personal data for our purposes.
//       </p>
//       <h3>Personal Data</h3>
//       <ol>
//         <li>
//           As used in this Notice:
//           <ul>
//             <li>
//               “user” means an individual who:
//               <ol>
//                 <li>
//                   (a) has contacted us through any means to find out more about the
//                   services we provide, or
//                 </li>
//                 <li>
//                   (b) is registered to use and uses the services provided by the App.
//                 </li>
//               </ol>
//             </li>
//             <li>
//               “personal data” means data, whether true or not, about a user who can
//               be identified:
//               <ol>
//                 <li>(a) from that data, or</li>
//                 <li>
//                   (b) from that data and other information to which we have or are
//                   likely to have access.
//                 </li>
//               </ol>
//             </li>
//           </ul>
//         </li>
//       </ol>
//       <h3>Collection, Use and Disclosure of Personal Data</h3>
//       <ol>
//         <li>
//           We generally do not collect your personal data unless:
//           <ol>
//             <li>
//               (a) it is provided to us voluntarily by you directly or via a third
//               party who has been duly authorised by you to disclose your personal
//               data to us (your “authorised representative”) after:
//               <ol>
//                 <li>
//                   (i) you (or your authorised representative) have been notified of
//                   the purposes for which the data is collected, and
//                 </li>
//                 <li>
//                   (ii) you (or your authorised representative) have provided written
//                   consent to the collection and usage of your personal data for those
//                   purposes, or
//                 </li>
//               </ol>
//             </li>
//             <li>
//               (b) collection and use of personal data without consent is permitted or
//               required by the PDPA or other laws. We shall seek your consent before
//               collecting any additional personal data and before using your personal
//               data for a purpose which has not been notified to you (except where
//               permitted or authorised by law).
//             </li>
//           </ol>
//         </li>
//         <li>
//           We may collect and use your personal data for any or all of the following
//           purposes:
//           <ol>
//             <li>
//               Performing obligations in the course of or in connection with our
//               provision of the services requested by you;
//             </li>
//             <li>Verifying your identity;</li>
//             <li>
//               Responding to, handling, and processing queries, requests,
//               applications, complaints, and feedback from you;
//             </li>
//             <li>Managing your relationship with us;</li>
//             <li>
//               Complying with any applicable laws, regulations, codes of practice,
//               guidelines, or rules, or to assist in law enforcement and
//               investigations conducted by any governmental and/or regulatory
//               authority;
//             </li>
//             <li>Any other purposes for which you have provided the information;</li>
//             <li>
//               Transmitting to any unaffiliated third parties including our third
//               party service providers and agents, and relevant governmental and/or
//               regulatory authorities, whether in Singapore or abroad, for the
//               aforementioned purposes;
//             </li>
//             <li>
//               Any other incidental business purposes related to or in connection with
//               the above.
//             </li>
//           </ol>
//         </li>
//         <li>
//           We may disclose your personal data:
//           <ol>
//             <li>
//               (a) where such disclosure is required for performing obligations in the
//               course of or in connection with our provision of the services requested
//               by you; or
//             </li>
//             <li>
//               (b) to third party service providers, agents and other organisations we
//               have engaged to perform any of the functions with reference to the
//               above-mentioned purposes.
//             </li>
//           </ol>
//         </li>
//         <li>
//           The purposes listed in the above clauses may continue to apply even in
//           situations where your relationship with us has been terminated or altered
//           in any way, for a reasonable period thereafter (including, where
//           applicable, a period to enable us to enforce our rights under a contract
//           with you).
//         </li>
//       </ol>
//       <h3>Reliance on the Legitimate Interests Exception</h3>
//       <ol>
//         <li>
//           In compliance with the PDPA, we may collect, use, or disclose your personal
//           data without your consent for the legitimate interests of PROUT PTE LTD or
//           another person. In relying on the legitimate interests exception of the
//           PDPA, PROUT PTE LTD will assess the likely adverse effects on the
//           individual and determine that the legitimate interests outweigh any adverse
//           effect.
//         </li>
//         <li>
//           In line with the legitimate interests’ exception, we will collect, use, or
//           disclose your personal data for the following purposes:
//           <ol>
//             <li>Fraud detection and prevention; and</li>
//             <li>Detection and prevention of misuse of services.</li>
//           </ol>
//         </li>
//       </ol>
//       <p>
//         The purposes listed in the above clause may continue to apply even in
//         situations where your relationship with us (for example, pursuant to a
//         contract) has been terminated or altered in any way, for a reasonable period
//         thereafter.
//       </p>
//       <h2>Data Protection Notice for Users</h2>
//       <p>
//         This Data Protection Notice (“Notice”) sets out the basis which PROUT PTE LTD
//         (“we”, “us”, or “our”) may collect, use, disclose or otherwise process
//         personal data of users of the Prout platform www.prout.asia (“App”) in
//         accordance with the Personal Data Protection Act (“PDPA”). This Notice
//         applies to personal data in our possession or under our control, including
//         personal data in the possession of organisations which we have engaged to
//         collect, use, disclose or process personal data for our purposes.
//       </p>
//       <h3>Personal Data</h3>
//       <ol>
//         <li>
//           As used in this Notice:
//           <ul>
//             <li>
//               “user” means an individual who:
//               <ol>
//                 <li>
//                   (a) has contacted us through any means to find out more about the
//                   services we provide, or
//                 </li>
//                 <li>
//                   (b) is registered to use and uses the services provided by the App.
//                 </li>
//               </ol>
//             </li>
//             <li>
//               “personal data” means data, whether true or not, about a user who can
//               be identified:
//               <ol>
//                 <li>(a) from that data, or</li>
//                 <li>
//                   (b) from that data and other information to which we have or are
//                   likely to have access.
//                 </li>
//               </ol>
//             </li>
//           </ul>
//         </li>
//       </ol>
//       <h3>Collection, Use and Disclosure of Personal Data</h3>
//       <ol>
//         <li>
//           We generally do not collect your personal data unless:
//           <ol>
//             <li>
//               (a) it is provided to us voluntarily by you directly or via a third
//               party who has been duly authorised by you to disclose your personal
//               data to us (your “authorised representative”) after:
//               <ol>
//                 <li>
//                   (i) you (or your authorised representative) have been notified of
//                   the purposes for which the data is collected, and
//                 </li>
//                 <li>
//                   (ii) you (or your authorised representative) have provided written
//                   consent to the collection and usage of your personal data for those
//                   purposes, or
//                 </li>
//               </ol>
//             </li>
//             <li>
//               (b) collection and use of personal data without consent is permitted or
//               required by the PDPA or other laws. We shall seek your consent before
//               collecting any additional personal data and before using your personal
//               data for a purpose which has not been notified to you (except where
//               permitted or authorised by law).
//             </li>
//           </ol>
//         </li>
//         <li>
//           We may collect and use your personal data for any or all of the following
//           purposes:
//           <ol>
//             <li>
//               Performing obligations in the course of or in connection with our
//               provision of the services requested by you;
//             </li>
//             <li>Verifying your identity;</li>
//             <li>
//               Responding to, handling, and processing queries, requests,
//               applications, complaints, and feedback from you;
//             </li>
//             <li>Managing your relationship with us;</li>
//             <li>
//               Complying with any applicable laws, regulations, codes of practice,
//               guidelines, or rules, or to assist in law enforcement and
//               investigations conducted by any governmental and/or regulatory
//               authority;
//             </li>
//             <li>Any other purposes for which you have provided the information;</li>
//             <li>
//               Transmitting to any unaffiliated third parties including our third
//               party service providers and agents, and relevant governmental and/or
//               regulatory authorities, whether in Singapore or abroad, for the
//               aforementioned purposes;
//             </li>
//             <li>
//               Any other incidental business purposes related to or in connection with
//               the above.
//             </li>
//           </ol>
//         </li>
//         <li>
//           We may disclose your personal data:
//           <ol>
//             <li>
//               (a) where such disclosure is required for performing obligations in the
//               course of or in connection with our provision of the services requested
//               by you; or
//             </li>
//             <li>
//               (b) to third party service providers, agents, and other organisations
//               we have engaged to perform any of the functions with reference to the
//               above-mentioned purposes.
//             </li>
//           </ol>
//         </li>
//         <li>
//           The purposes listed in the above clauses may continue to apply even in
//           situations where your relationship with us has been terminated or altered
//           in any way, for a reasonable period thereafter (including, where
//           applicable, a period to enable us to enforce our rights under a contract
//           with you).
//         </li>
//       </ol>
//       <h3>Reliance on the Legitimate Interests Exception</h3>
//       <ol>
//         <li>
//           In compliance with the PDPA, we may collect, use, or disclose your personal
//           data without your consent for the legitimate interests of PROUT PTE LTD or
//           another person. In relying on the legitimate interests exception of the
//           PDPA, PROUT PTE LTD will assess the likely adverse effects on the
//           individual and determine that the legitimate interests outweigh any adverse
//           effect.
//         </li>
//         <li>
//           In line with the legitimate interests’ exception, we will collect, use, or
//           disclose your personal data for the following purposes:
//           <ol>
//             <li>Fraud detection and prevention; and</li>
//             <li>Detection and prevention of misuse of services.</li>
//           </ol>
//         </li>
//       </ol>
//       <h3>Withdrawal of Consent</h3>
//       <ol>
//         <li>
//           The consent that you provide for the collection, use, and disclosure of
//           your personal data will remain valid until such time it is being withdrawn
//           by you in writing (via email). You may withdraw consent and request us to
//           stop collecting, using and/or disclosing your personal data for any or all
//           of the purposes listed above by submitting your request via email to our
//           Data Protection Officer at the contact details provided below.
//         </li>
//         <li>
//           Upon receipt of your written request to withdraw your consent, we may
//           require reasonable time (depending on the complexity of the request and its
//           impact on our relationship with you) for your request to be processed and
//           for us to notify you of the consequences of us acceding to the same,
//           including any legal consequences which may affect your rights and
//           liabilities to us. In general, we shall seek to process your request within
//           ten (10) business days of receiving it.
//         </li>
//         <li>
//           Whilst we respect your decision to withdraw your consent, please note that
//           depending on the nature and scope of your request, we may not be in a
//           position to continue providing our services to you, and we shall, in such
//           circumstances, notify you before completing the processing of your request.
//           Should you decide to cancel your withdrawal of consent, please inform us in
//           writing in the manner described in clause 10 above.
//         </li>
//         <li>
//           Please note that withdrawing consent does not affect our right to continue
//           to collect, use, and disclose personal data where such collection, use, and
//           disclosure without consent is permitted or required under applicable laws.
//         </li>
//       </ol>
//       <h3>Access to and Correction of Personal Data</h3>
//       <ol>
//         <li>
//           If you wish to make:
//           <ol>
//             <li>
//               (a) an access request for access to a copy of the personal data which
//               we hold about you or information about the ways in which we use or
//               disclose your personal data, or
//             </li>
//             <li>
//               (b) a correction request to correct or update any of your personal data
//               which we hold about you,
//             </li>
//           </ol>
//           you may submit your request via email to our Data Protection Officer at the
//           contact details provided below.
//         </li>
//         <li>
//           Please note that a reasonable fee may be charged for an access request. If
//           so, we will inform you of the fee before processing your request.
//         </li>
//         <li>
//           We will respond to your request as soon as reasonably possible. In general,
//           our response will be within twenty (20) business days. Should we not be
//           able to respond to your request within thirty (30) days after receiving
//           your request, we will inform you via email within thirty (30) days of the
//           time by which we will be able to respond to your request. If we are unable
//           to provide you with any personal data or to make a correction requested by
//           you, we shall generally inform you of the reasons why we are unable to do
//           so (except where we are not required to do so under the PDPA).
//         </li>
//       </ol>
//       <h3>Protection of Personal Data</h3>
//       <ol>
//         <li>
//           To safeguard your personal data from unauthorised access, collection, use,
//           disclosure, copying, modification, disposal or similar risks, we have
//           introduced appropriate administrative, physical and technical measures such
//           as:
//           <ol>
//             <li>Minimised collection of personal data;</li>
//             <li>
//               Authentication and access controls (such as good password practices,
//               need-to-know basis for data disclosure, etc.);
//             </li>
//             <li>Up-to-date antivirus protection;</li>
//             <li>Web security measures against risks; and</li>
//             <li>Usage of one-time password (OTP) to secure access.</li>
//           </ol>
//         </li>
//         <li>
//           You should be aware, however, that no method of transmission over the
//           Internet or method of electronic storage is completely secure. While
//           security cannot be guaranteed, we strive to protect the security of your
//           information and are constantly reviewing and enhancing our information
//           security measures.
//         </li>
//       </ol>
//       <h3>Accuracy of Personal Data</h3>
//       <ol>
//         <li>
//           We generally rely on personal data provided by you (or your authorised
//           representative). In order to ensure that your personal data is current,
//           complete, and accurate, please update us if there are changes to your
//           personal data by informing our Data Protection Officer via email at the
//           contact details provided below.
//         </li>
//       </ol>
//       <h3>Retention of Personal Data</h3>
//       <ol>
//         <li>
//           We may retain your personal data for as long as it is necessary to fulfill
//           the purpose for which it was collected, or as required or permitted by
//           applicable laws.
//         </li>
//         <li>
//           We will cease to retain your personal data or remove the means by which the
//           data can be associated with you as soon as it is reasonable to assume that
//           such retention no longer serves the purpose for which the personal data was
//           collected and is no longer necessary for legal or business purposes.
//         </li>
//       </ol>
//       <h3>Transfers of Personal Data Outside of Singapore</h3>
//       <ol>
//         <li>
//           We generally do not transfer your personal data to countries outside of
//           Singapore. However, if we do so, we will obtain your consent for the
//           transfer to be made and will take steps to ensure that your personal data
//           continues to receive a standard of protection that is at least comparable
//           to that provided under the PDPA.
//         </li>
//       </ol>
//       <h3>Contact Details</h3>
//       <p>
//         If you have any questions or feedback regarding this Notice, or if you wish
//         to make a complaint about our privacy practices, you may contact our Data
//         Protection Officer via email at{" "}
//         <a href="mailto:contact@proutapp.com">privacy@prout.asia</a>
//       </p>
//     </Col>
//   );
// };

// export default PrivacyPolicy;
