import { Alert, CircularProgress, TextField } from "@mui/material";
import React, { useState } from "react";

import { Col, Container, Form } from "react-bootstrap";
import "../../styles/dashboard.css";
import "../../styles/ratingstar.css";
import "../../styles/register.css";

import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { setExpanded } from "../../features/user/userSlice";
import RainbowButton from "../common/RainbowButton";
import RatingStars from "../reviews/RatingStars";
const CreateReview = () => {
  // const [isExpanded, setIsExpanded] = useState(false);
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState("");
  const [fullName, setFullName] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [biography, setBiography] = useState("");

  // const toggleMenu = () => {
  //   setIsExpanded(!isExpanded);
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const token = await login(email, password);
      localStorage.setItem("authToken", token);
      setErrorMessage("");
      // Redirect the user to the dashboard or another protected route
      navigate("/");
    } catch (error) {
      console.error("Login failed:", error);
      setErrorMessage("Invalid email or password");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    dispatch(setExpanded(false));
  }, []);

  return (
    <Container fluid className="container">
      {/* <Header toggleMenu={toggleMenu} title="Create review" />
      <SidePanel isExpanded={isExpanded} toggleMenu={toggleMenu} /> */}

      <Col xs={12} md={9} className="p-4">
        <Form onSubmit={handleSubmit}>
          {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
          <Form.Group controlId="pageName" className="form-group-spacing">
            <Form.Label>Page Name</Form.Label>

            <TextField
              type="string"
              placeholder="What are you reviewing?"
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
              fullWidth
              variant="standard"
              inputProps={{ className: "custom-input" }}
              InputLabelProps={{ className: "small-placeholder" }}
            />
          </Form.Group>

          <Link to="/create_page">Submit Listing</Link>

          <Form.Group controlId="rate" className="form-group-spacing">
            <Form.Label>Rate the page</Form.Label>
            <RatingStars />
          </Form.Group>

          <Form.Group controlId="review" className="form-group-spacing">
            <Form.Label>Review</Form.Label>
            <TextField
              multiline
              rows={3}
              type="text"
              placeholder="Type a short review of your experience."
              value={biography}
              onChange={(e) => setBiography(e.target.value)}
              fullWidth
              variant="standard"
              inputProps={{ className: "custom-input" }}
              InputLabelProps={{ className: "small-placeholder" }}
            />
          </Form.Group>
        </Form>
        {isLoading ? (
          <div className="text-center">
            <CircularProgress style={{ margin: "auto" }} />
          </div>
        ) : (
          <RainbowButton type="submit">Create Review </RainbowButton>
          // <Button
          //   variant="secondary"
          //   type="submit"
          //   className="custom-rainbow-button mt-3"
          // >
          //   Create Review
          // </Button>
        )}
      </Col>
    </Container>
  );
};

export default CreateReview;
