import React from "react";
import proutImage from "../../../images/prout_logo_white.png";

interface ProutWhiteProps {
  mb?: string; // Optional prop for margin bottom, default value will be set
  width?: number; // Optional prop for width, default value will be set
}

const ProutWhite: React.FC<ProutWhiteProps> = ({ mb = "mb-4", width = 70 }) => {
  return (
    <h2 className={`text-center ${mb}`}>
      <img style={{ width }} src={proutImage} alt="prout" />
    </h2>
  );
};

export default ProutWhite;
