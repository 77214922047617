// src/components/BackgroundVideo.js
import React, { useEffect, useState } from "react";
import backgroundImage from "../../../images/prout_bg.jpg";
import backgroundVideo from "../../../images/prout_bg.mp4"; // Placeholder image path

const BackgroundVideo = ({ height = "h-screen" }) => {
  const [videoLoaded, setVideoLoaded] = useState(false);

  useEffect(() => {
    const video = document.getElementById("background-video");
    video.addEventListener("canplaythrough", () => {
      setVideoLoaded(true);
    });
  }, []);

  return (
    <div className={`relative w-full overflow-hidden ${height}`}>
      {!videoLoaded && (
        <img
          src={backgroundImage}
          alt="Background"
          className="absolute inset-0 w-full h-full object-cover"
        />
      )}
      <video
        id="background-video"
        className={`absolute inset-0 w-full h-full object-cover ${
          videoLoaded ? "block" : "hidden"
        }`}
        autoPlay
        loop
        muted
        playsInline
      >
        <source src={backgroundVideo} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default BackgroundVideo;
