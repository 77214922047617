import React from "react";
import { Col } from "react-bootstrap";

const FAQ = () => {
  return (
    <Col xs={12} md={9} className="p-4">
      <h1>FAQ</h1>
      <p className="p-bold">Is this platform free for the LGBTQ+ community? </p>
      <p>The platform is 100% free for community use.</p>
      <p className="p-bold">Why is Prout creating this platform? </p>
      <p>
        Many within the LGBTQ+ community have shared terrible experiences with
        critical service providers. Some of these include conservative
        gynaecologists, General Practitioners (GPs) or even counsellors who promote
        conversion therapy. Folks from the community also experience renting and
        living in non-affirming environments, and are worried about being kicked out.
        There are also younger folks concerned about finding jobs in companies that
        may discriminate against them for who they are, and may not know their legal
        recourse in the event that they are fired without reason. <br />
        In an ideal world, Prout’s platform would not be needed, but the above
        experiences shared are exactly why inclusive critical service providers are
        necessary for the wellbeing of the community.
      </p>
      <p className="p-bold">
        There are reviews below the listings. Who is reviewing these service
        providers?
      </p>
      <p>
        End users within the community, like you, are the ones sharing your
        experiences with these service providers. This helps others within the
        community make an informed decision as to who to reach out to for inclusive
        healthcare, or to draft a legal document etc.
      </p>
      <p className="p-bold">
        How does Prout know these service providers are actually inclusive to the
        LGBTQ+ community?
      </p>
      <p>
        Many of the doctors, therapists, lawyers, realtors, and companies have been
        either crowdsourced from others within our community who have used their
        services, or know that these companies have DEI policies in place to protect
        minorities. These companies, clinics, law firms, and realtors may also submit
        their own listings, and we will reach out to verify on our end that they are
        inclusive to the LGBTQ+ community.
      </p>
      <p className="p-bold">
        Is there a way to guarantee these service providers are actually inclusive to
        the community?
      </p>
      <p>
        As these service providers are crowdsourced and also the listings are
        submitted by the service providers themselves, Prout does its best to ensure
        they are inclusive to the LGBTQ+ community by verifying with these service
        providers (especially service providers who submitted their own listings). We
        urge users to reach out to service providers themselves to share their
        concerns if they have any.
      </p>
      <p>
        Please also refer to our{" "}
        <a href="/terms-and-condition">Terms and Conditions</a> &amp;{" "}
        <a href="/privacy-policy">Disclaimers</a> pages for more information.
      </p>
      <p className="p-bold">
        I am a service provider. How do I submit a listing of an inclusive service
        provider?
      </p>
      <p>
        You must first create a profile as a user. You may submit it by clicking the
        + icon at the bottom right corner of the web app when you are viewing other
        listings. We will proceed to reach out to verify the details in the listing.
      </p>
      <p className="p-bold">
        It isn’t cheap to maintain a web app. How is Prout sustaining the platform
        financially?
      </p>
      <p>
        As of now, the platform is 100% free for everyone to use. We are not charging
        service providers any fee to have their services listed, neither are we
        charging users to use it, and we have no plans to do so for now. We believe
        the platform should be free for the LGBTQ+ community end users to use, for
        their wellbeing. <br />
        If you would like to support this web app or our community work, please
        consider purchasing our pride merchandise from{" "}
        <a href="https://www.prout.shop" target="_blank" rel="noopener noreferrer">
          www.prout.shop
        </a>{" "}
        or reaching out to{" "}
        <a href="mailto:contact@proutapp.com">contact@proutapp.com</a> with your
        details.
      </p>
      <p className="p-bold">HELP </p>
      <p>
        If you would like to raise any concern or feedback about a service provider,
        please email us at{" "}
        <a href="mailto:contact@proutapp.com" className="email-link">
          <strong>contact@proutapp.com</strong>
        </a>
        .
      </p>
    </Col>
  );
};

export default FAQ;
