import CircularProgress from "@mui/material/CircularProgress";
import { Box } from "@mui/system";
import PropTypes from "prop-types";
import React from "react";

interface CenteredCircularProgressProps {
  color?: string;
}

function CenteredCircularProgress({
  color = "#147edb",
}: CenteredCircularProgressProps) {
  return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <CircularProgress sx={{ color }} size="35px" />
    </Box>
  );
}

CenteredCircularProgress.propTypes = {
  color: PropTypes.string,
};

CenteredCircularProgress.defaultProps = {
  color: "#147edb",
};

export default CenteredCircularProgress;
