import PropTypes from "prop-types";
import React from "react";
import { Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../../styles/commons.css";
import BackButton from "../common/BackButton";

const SidePanel = ({ isExpanded, toggleMenu }) => {
  // const navigate = useNavigate();
  return (
    <div className={`side-panel${isExpanded ? " show" : ""}`}>
      <div className="side-panel-header">
        {isExpanded && (
          <div className="back-button-container">
            <BackButton color="text-black" onClick={toggleMenu} />
          </div>
        )}
        <span className="side-panel-title">Menu</span>
        <div className="side-panel-header-border"></div>
      </div>
      <Nav className="mr-auto flex-column">
        <br />
        <Link className="navlink-black" to="/homepage" onClick={toggleMenu}>
          Home{" "}
        </Link>
        <br />
        <Link className="navlink-black" to="/profile" onClick={toggleMenu}>
          Profile{" "}
        </Link>
        {/* <br />
        <Link className="navlink-black" to="/settings" onClick={toggleMenu}>
          Settings{" "}
        </Link> */}
        {/* <br />
        <Link className="navlink-black" to="/save" onClick={toggleMenu}>
          Saved Listings{" "}
        </Link> */}
        <br />

        {/* <Link className="navlink-black" to="/mypages/" onClick={toggleMenu}>
          My Pages{" "}
        </Link>

        <br /> */}
        <a
          className="navlink-black"
          href="https://prout.shop"
          target="_blank"
          rel="noopener noreferrer"
          onClick={toggleMenu}
        >
          Shop
        </a>
        <br />
        <Link className="navlink-black" to="/faq" onClick={toggleMenu}>
          FAQ
        </Link>
        <br />
        <Link className="navlink-black" to="/help" onClick={toggleMenu}>
          Help{" "}
        </Link>
        <br />
        <a
          className="navlink-black"
          href="https://t.me/proutapp"
          target="_blank"
          rel="noopener noreferrer"
          onClick={toggleMenu}
        >
          Join Telegram
        </a>
        <br />
        <Link className="navlink-black" to="/privacy-policy" onClick={toggleMenu}>
          Privacy Policy
        </Link>
        <br />
        <Link
          className="navlink-black"
          to="/terms-and-condition"
          onClick={toggleMenu}
        >
          Terms and Condition
        </Link>
        <br />
        <Link className="navlink-black" to="/logout" onClick={toggleMenu}>
          Logout
        </Link>
        <br />
        <br />
      </Nav>
    </div>
  );
};

SidePanel.propTypes = {
  isExpanded: PropTypes.bool.isRequired,
  toggleMenu: PropTypes.func.isRequired,
};

export default SidePanel;
