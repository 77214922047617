function isValidSGNum(str) {
  //const regex = /^(65|0|63|8|9).{7,}$/;
  const regex = /^\+?(65|0|63|8|9)\s*\d{1,3}\s*\d{1,4}\s*\d{1,4}$/;
  return regex.test(str);
}
export default isValidSGNum;

export function isValidEmail(email) {
  const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return regex.test(email);
}
