import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import BackgroundVideo from "../common/BackgroundVideo";

const Splash = () => {
  const navigate = useNavigate();
  const [code, setCode] = useState("");

  const handleClick = () => {
    if (code === "BEPROUT24" || code === "WOOO") {
      localStorage.setItem("demoCodeEntered", 1);
      navigate("/intro");
    } else {
      alert("Invalid code. Please try again.");
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleClick();
    }
  };

  return (
    <div className="relative w-full h-screen overflow-hidden">
      <BackgroundVideo />
      <div className="absolute inset-0 flex flex-col items-center justify-center h-full text-center z-10">
        <p className="text-5xl text-white font-extrabold">
          Welcome <span className="block">to Prout!</span>
        </p>

        <p className="font-thin text-white mt-1">
          Key in early access<span className="block">code to enter</span>
        </p>

        <div className="mt-0 flex items-center">
          <input
            type="text"
            placeholder="Enter code"
            value={code}
            onChange={(e) => setCode(e.target.value)}
            onKeyDown={handleKeyDown}
            className="px-4 py-2 border-0 rounded-md focus:outline-none"
          />
        </div>
        <div className="mt-3">
          <ArrowForwardIcon
            className="ml-2 cursor-pointer"
            sx={{ color: "white" }}
            onClick={handleClick}
          />
        </div>
      </div>
    </div>
  );
};

export default Splash;
