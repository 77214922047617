function shuffleArray<T>(array: T[]): T[] {
  const newArray = [...array]; // Create a new array to avoid modifying the original array
  let currentIndex = newArray.length,
    temporaryValue: T,
    randomIndex: number;

  while (currentIndex !== 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // Swap elements in the new array
    temporaryValue = newArray[currentIndex];
    newArray[currentIndex] = newArray[randomIndex];
    newArray[randomIndex] = temporaryValue;
  }

  return newArray; // Return the shuffled array
}

export default shuffleArray;
