import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// Type for the state
interface UserState {
  isLoggedIn: boolean;
  userProfile: string;
  currentBlog: Record<string, any>; // You might want to specify the type more accurately
  isExpanded: boolean;
  token: string;
  email: string;
  services: any[] | null; // You might want to specify the type more accurately
  profileId: string;
  isSearchOpen: boolean;
  isFilterOpen: boolean;
  currentService: string;
}

// Initial state
const user: string = localStorage.getItem("authToken") || "";
const isLoggedIn: boolean = localStorage.getItem("authToken") !== null;
const email: string = localStorage.getItem("email") || "";
const services: any[] | null = JSON.parse(
  localStorage.getItem("services") || "null"
);
const profileId: string = localStorage.getItem("profileId") || "";
const isSearchOpen: boolean = false;
const currentService: string = localStorage.getItem("category") || "HEALTH";
const initialState: UserState = {
  isLoggedIn,
  userProfile: user,
  currentBlog: {},
  isExpanded: false,
  token: user,
  email,
  services,
  profileId,
  isSearchOpen,
  currentService,
  isFilterOpen: false,
};

// Redux Toolkit createSlice
export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    userStatus: (state, action: PayloadAction<boolean>) => {
      state.isLoggedIn = action.payload;
    },
    userInfo: (state, action: PayloadAction<string>) => {
      state.userProfile = action.payload;
    },
    removeUser: (state) => {
      state.userProfile = "";
    },
    addCurrentBlog: (state, action: PayloadAction<Record<string, any>>) => {
      state.currentBlog = action.payload;
    },
    setExpanded: (state, action: PayloadAction<boolean>) => {
      state.isExpanded = action.payload;
    },
    setEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
    setIsLoggedIn: (state, action: PayloadAction<boolean>) => {
      state.isLoggedIn = action.payload;
    },
    setServices: (state, action: PayloadAction<any[] | null>) => {
      state.services = action.payload;
    },
    setProfileId: (state, action: PayloadAction<string>) => {
      state.profileId = action.payload;
    },
    setSearchOpen: (state, action: PayloadAction<boolean>) => {
      state.isSearchOpen = action.payload;
    },
    setFilterOpen: (state, action: PayloadAction<boolean>) => {
      state.isFilterOpen = action.payload;
    },
    setCurrentService: (state, action: PayloadAction<string>) => {
      state.currentService = action.payload;
    },
  },
});

// Export action creators
export const {
  userStatus,
  userInfo,
  removeUser,
  addCurrentBlog,
  setExpanded,
  setEmail,
  setIsLoggedIn,
  setToken,
  setServices,
  setProfileId,
  setSearchOpen,
  setFilterOpen,
  setCurrentService,
} = userSlice.actions;

// Export selectors
export const getStatus = (state: { user: UserState }) => state.user.isLoggedIn;
export const getUserProfile = (state: { user: UserState }) => state.user.userProfile;
export const getCurrentBlog = (state: { user: UserState }) => state.user.currentBlog;
export const getExpanded = (state: { user: UserState }) => state.user.isExpanded;
export const getEmail = (state: { user: UserState }) => state.user.email;
export const getToken = (state: { user: UserState }) => state.user.token;
export const getServices = (state: { user: UserState }) => state.user.services;
export const getProfileId = (state: { user: UserState }) => state.user.profileId;
export const getSearchOpen = (state: { user: UserState }) => state.user.isSearchOpen;
export const getFilterOpen = (state: { user: UserState }) => state.user.isFilterOpen;
export const getCurrentService = (state: { user: UserState }) =>
  state.user.currentService;

// Export reducer
export default userSlice.reducer;
