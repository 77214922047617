import { Form, Select, Space } from "antd";
import React, { ReactElement } from "react";
import { Button, Col } from "react-bootstrap";
import { RiCloseLine } from "react-icons/ri";
import {
  bedrooms,
  healthCareSpecialisation,
  housingCategory,
  jobIndustrySpecialisation,
  jobIndustryTenure,
  legalSpecialisation,
  workplaceType,
} from "../common/Category";
import { neighbourhoodOptions } from "../common/Neighbourhood";

interface HOUSINGProps {
  onSubmit: (value: string) => void;
}

const HEALTH = (): ReactElement => {
  return (
    <Form.Item
      className=" overscroll-y-none"
      style={{ marginLeft: 5, width: 200 }}
      name="health_specialisation"
    >
      <Select
        virtual={false}
        className="text-base"
        placeholder="Specialisation"
        style={{ maxWidth: 200 }}
        options={healthCareSpecialisation}
      />
    </Form.Item>
  );
};

const LEGAL = (): ReactElement => {
  return (
    <Form.Item style={{ marginLeft: 5, width: 275 }} name="legal_specialisation">
      <Select
        className="text-base"
        placeholder="Specialisation"
        style={{ maxWidth: 500, width: 275 }}
        options={legalSpecialisation}
      />
    </Form.Item>
  );
};

const JOB = (): ReactElement => {
  return (
    <>
      <Form.Item style={{ marginLeft: 5, width: 150 }} name="industry">
        <Select
          className="text-base max-w-[150px]"
          placeholder="Industry"
          options={jobIndustrySpecialisation}
        />
      </Form.Item>
      <Form.Item style={{ marginLeft: 5, width: 150 }} name="workplacetype">
        <Select
          className="text-base max-w-[150px]"
          placeholder="Work Setup"
          options={workplaceType}
        />
      </Form.Item>
      <Form.Item style={{ marginLeft: 5, width: 150 }} name="tenure">
        <Select
          className="text-base max-w-[150px]"
          options={jobIndustryTenure}
          placeholder="Tenure"
        />
      </Form.Item>
    </>
  );
};

const HOUSING = ({ onSubmit }: HOUSINGProps): ReactElement => {
  return (
    <>
      <Form.Item style={{ marginLeft: 5, width: 150 }} name="location">
        <Select
          className="text-base max-w-[150px]"
          onChange={(value) => {
            onSubmit(value);
          }}
          placeholder="Neighbourhood"
          options={neighbourhoodOptions}
        />
      </Form.Item>
      <Form.Item style={{ marginLeft: 5, width: 130 }} name="listing_type">
        <Select
          className="text-base max-w-[150px]"
          onChange={(value) => {
            onSubmit(value);
          }}
          placeholder="Listing Type"
          options={[
            {
              value: "For Sale",
              label: "For Sale",
            },
            { value: "For Rent (Entire home)", label: "For Rent (Entire home)" },
            {
              value: "For Rent (Room only)",
              label: "For Rent (Room only)",
            },
          ]}
        />
      </Form.Item>
      <Form.Item style={{ marginLeft: 5, width: 130 }} name="category">
        <Select
          className="text-base max-w-[150px]"
          placeholder="Category"
          onChange={(value) => {
            onSubmit(value);
          }}
          options={housingCategory}
        />
      </Form.Item>
      <Form.Item style={{ marginLeft: 5, width: 130 }} name="bedrooms">
        <Select
          className="text-base max-w-[150px]"
          onChange={(value) => {
            //console.log(value);
          }}
          options={bedrooms}
          placeholder="Bedrooms"
        />
      </Form.Item>
    </>
  );
};

interface FilterProps {
  category: string;
  onSubmit: (value: string) => void;
  handleLastDropdownChange: (changedFields: any) => void;
  form: any;
  reset: () => void;
}

const Filter = ({
  category,
  onSubmit,
  handleLastDropdownChange,
  form,
  reset,
}: FilterProps): ReactElement => {
  const categories: Record<string, () => ReactElement> = {
    HEALTH,
    LEGAL,
    JOB,
    HOUSING,
  };

  const FilterComponent: React.FC<HOUSINGProps> = categories[category];

  return (
    <Form
      className=" overflow-x-scroll"
      style={{
        display: "flex",
        paddingBottom: 2,
      }}
      onFinish={onSubmit}
      form={form}
      onValuesChange={handleLastDropdownChange}
    >
      <Col style={{ paddingLeft: "10px", paddingRight: "10px" }}>
        <Button
          onClick={() => {
            form.resetFields();
            reset();
          }}
          variant="light"
          style={{
            height: "30px",
            lineHeight: "24px",
            backgroundColor: "white",
            color: "black",
            textAlign: "center",
            width: "30px",
            padding: 0,
          }}
        >
          <RiCloseLine style={{ color: "rgba(128, 128, 128, 0.5)" }} />
        </Button>
      </Col>

      <Col style={{ paddingLeft: "-10px", paddingRight: "-10px" }}>
        <div style={{ display: "flex" }}>
          <FilterComponent onSubmit={onSubmit} />
        </div>
      </Col>

      <Col style={{ paddingLeft: "10px", paddingRight: "10px" }}>
        <Space>
          {/* <Button type="primary" htmlType="submit">
            Filter
          </Button> */}
          {/* <Button onClick={() => form.resetFields()}>Reset</Button> */}
        </Space>
      </Col>
      <Col style={{ paddingLeft: "0px", paddingRight: "10px" }}>
        <Button
          onClick={() => {
            form.resetFields();
            reset();
          }}
          variant="light"
          style={{
            height: "30px",
            lineHeight: "24px",
            backgroundColor: "white",
            color: "black",
            textAlign: "center",
            width: "30px",
            padding: 0,
          }}
        >
          <RiCloseLine style={{ color: "rgba(128, 128, 128, 0.5)" }} />
        </Button>
      </Col>
    </Form>
  );
};

export default Filter;
