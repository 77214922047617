import React from "react";
import Button, { ButtonProps } from "react-bootstrap/Button";

interface RainbowButtonProps extends ButtonProps {
  className?: string; // Optional className prop
}

const RainbowButton: React.FC<RainbowButtonProps> = ({
  children,
  className,
  ...rest
}) => {
  return (
    <Button
      variant="" // Example: Using 'primary' variant in react-bootstrap
      className={`custom-rainbow-button font-medium mt-16 text-black ${
        className || ""
      }`}
      {...rest}
    >
      {children}
    </Button>
  );
};

export default RainbowButton;
