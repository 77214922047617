import React from "react";

const Save = () => {
  return (
    <div>
      <br />
      <center>Under Construction</center>
    </div>
  );
};

export default Save;
