import { CircularProgress, Rating, TextField } from "@mui/material";
import React, { useState } from "react";

import { Col, Container, Form } from "react-bootstrap";
import "../../styles/dashboard.css";
import "../../styles/ratingstar.css";
import "../../styles/register.css";

import { useMutation, useQueryClient } from "@tanstack/react-query";
import { message } from "antd";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { addReview } from "../../api/auth";
import { getEmail, getToken, setExpanded } from "../../features/user/userSlice";
import Alert from "../common/Alert";
import RainbowButton from "../common/RainbowButton";
const CreateReviewExisting = ({ serviceId }) => {
  // const [isExpanded, setIsExpanded] = useState(false);
  // const { serviceId } = useParams();
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState("");
  const [fullName, setFullName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const token = useSelector(getToken);
  const email = useSelector(getEmail);

  const navigate = useNavigate();

  const [data, setData] = useState({
    title: "",
    review: "",
    rating: 0,
  });

  const onChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;

    setData((prev) => ({ ...prev, [name]: value }));
  };

  const [biography, setBiography] = useState("");
  const [open, setOpen] = React.useState(false);

  const queryClient = useQueryClient();

  // const toggleMenu = () => {
  //   setIsExpanded(!isExpanded);
  // };

  //   const handleSubmit = async (e) => {
  //     e.preventDefault();
  //     setIsLoading(true);
  //     try {
  //       const token = await login(email, password);
  //       localStorage.setItem("authToken", token);
  //       setErrorMessage("");
  //       // Redirect the user to the dashboard or another protected route
  //       navigate("/");
  //     } catch (error) {
  //       console.error("Login failed:", error);
  //       setErrorMessage("Invalid email or password");
  //     } finally {
  //       setIsLoading(false);
  //     }
  //   };

  const mutation = useMutation({
    mutationFn: addReview,
    onSuccess: () => {
      queryClient.invalidateQueries(["reviews", serviceId]);
      setOpen(true);

      // navigate(-1);
    },
    onError: () => {
      message.error("Review already exists for this listing");
    },
  });

  const handleSubmit = () => {
    if (!data.rating || !data.review || !data.title) {
      message.error("Please enter all values");
      return;
    }
    mutation.mutate({
      token,
      username: email,
      values: { ...data, service_id: serviceId },
    });
  };

  useEffect(() => {
    dispatch(setExpanded(false));
  }, []);

  return (
    <Container fluid className="container">
      {/* <Header toggleMenu={toggleMenu} title="Create review" />
      <SidePanel isExpanded={isExpanded} toggleMenu={toggleMenu} /> */}

      <Col
        style={{ marginTop: "16px", marginBottom: "24px" }}
        xs={12}
        md={12}
        className="p-12"
      >
        <Form onSubmit={handleSubmit}>
          {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
          <Form.Group controlId="pageName" className="form-group-spacing">
            <Form.Label>Title</Form.Label>

            <TextField
              type="string"
              placeholder="Enter title"
              onChange={onChange}
              value={data.title}
              fullWidth
              variant="standard"
              inputProps={{ className: "custom-input" }}
              InputLabelProps={{ className: "small-placeholder" }}
              name="title"
            />
          </Form.Group>

          <Form.Group controlId="rate" className="form-group-spacing">
            <Form.Label>Rate the page</Form.Label>
            <Rating
              name="rating"
              defaultValue={0}
              onChange={onChange}
              precision={0.5}
            />
          </Form.Group>

          <Form.Group controlId="review" className="form-group-spacing">
            <Form.Label>Review</Form.Label>
            <TextField
              multiline
              rows={3}
              type="text"
              placeholder="Type a short review of your experience."
              onChange={onChange}
              value={data.review}
              fullWidth
              variant="standard"
              inputProps={{ className: "custom-input" }}
              InputLabelProps={{ className: "small-placeholder" }}
              name="review"
            />
          </Form.Group>
        </Form>
        {isLoading ? (
          <div className="text-center">
            <CircularProgress style={{ margin: "auto" }} />
          </div>
        ) : (
          // <Button
          //   variant="secondary"
          //   type="submit"
          //   className="custom-rainbow-button mt-3"
          //   onClick={handleSubmit}
          // >
          //   Create Review
          // </Button>
          <div>
            <RainbowButton onClick={handleSubmit} type="submit mt-3">
              Submit
            </RainbowButton>
          </div>
        )}
      </Col>
      <Alert
        handleClick={handleClick}
        handleClose={handleClose}
        message="Succesfully added a review"
        open={open}
      />
    </Container>
  );
};

export default CreateReviewExisting;
