import { Autocomplete } from "@mui/lab";
import { TextField } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import "../../styles/pageselector.css";

const PageSelector = ({ pages, onChange }) => {
  const handlePageChange = (event, newValue) => {
    onChange(newValue);
  };

  return (
    <Autocomplete
      id="page-selector"
      options={pages}
      getOptionLabel={(option) => option.title}
      style={{ display: "none" }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          placeholder="Select a page"
          className="page-selector-textfield"
          InputProps={{
            ...params.InputProps,
            type: "search",
            classes: { input: "autocomplete-input" },
          }}
        />
      )}
      onChange={handlePageChange}
      autoHighlight
      filterOptions={(options, state) =>
        options.filter(
          (option) =>
            option.title.toLowerCase().indexOf(state.inputValue.toLowerCase()) !== -1
        )
      }
      className="autocomplete-root"
    />
  );
};

PageSelector.propTypes = {
  pages: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default PageSelector;
