import axios from "axios";
const API_BASE_URL = import.meta.env.VITE_API_BASE_URL;

const createHeader = ({ token, username }) => ({
  Authorization: `Bearer ${token}`,
  "Content-Type": "multipart/form-data",
  client_key: username,
});

const api = axios.create({
  baseURL: API_BASE_URL,
  headers: {},
});

export const login = async (email, password) => {
  try {
    const formData = new FormData();
    formData.append("username", email);
    formData.append("password", password);

    const response = await api.post(`/login`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const register = async (email_address, username, password, mobileNumber) => {
  try {
    const formData = new FormData();
    formData.append("username", username);
    formData.append("email_address", email_address);
    formData.append("password", password);
    formData.append("mobile_number", mobileNumber);

    const response = await api.post(`/register`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

// export const getOtherServices = async ({ token, username }) => {
//   //console.log(token, username);
//   const res = await api.get("/getOtherServices", {
//     headers: createHeader({ token, username }),
//   });
//   return res.data;
// };

export const getOwnServicesWithAttributes = async ({ token, username }) => {
  const res = await api.get(`/getOwnServicesWithAttributes/?page=1&limit=100`, {
    headers: createHeader({ token, username }),
  });
  return res.data;
};

export const getOtherServicesWithAttributes = async ({
  token,
  username,
  category,
  page,
  delayInMilliseconds = 1000, // Adjust the delay time as needed
}) => {
  // Simulate a delay using setTimeout
  await new Promise((resolve) => setTimeout(resolve, delayInMilliseconds));

  const res = await api.get(
    `/getOtherServicesWithAttributes/?page=${page}&limit=50&category=${category}`,
    {
      headers: createHeader({ token, username }),
    }
  );
  return res.data;
};

export const getServiceChangeRequests = async ({ token, username, serviceId }) => {
  const res = await api.get(`/getServiceChangeRequests/${serviceId}`, {
    headers: createHeader({ token, username }),
  });
  return res.data;
};

export const getReviews = async ({ token, username, service_id }) => {
  const res = await api.get(
    `/getReviews/?service_id=${service_id}&page=1&limit=10`,
    {
      headers: createHeader({ token, username }),
    }
  );
  return res.data;
};

export const addServiceChangeRequest = async ({ token, username, values }) => {
  const res = await api.post("/addServiceChangeRequest", values, {
    headers: createHeader({ token, username }),
  });
  return res.data;
};

export const deleteServiceChangeRequest = async ({ token, username, id }) => {
  const res = await api.delete(`/deleteServiceChangeRequest/${id}`, {
    headers: createHeader({ token, username }),
  });
  return res.data;
};

export const addReview = async ({ token, username, values }) => {
  const res = await api.post("/addReview", values, {
    headers: createHeader({ token, username }),
  });
  return res.data;
};

export const addService = async ({ token, username, values }) => {
  const res = await api.post("/addServiceWithAttributes", values, {
    headers: createHeader({ token, username }),
  });
  return res.data;
};

export const updateProfile = async ({ token, username, values }) => {
  const res = await api.put("/updateprofile", values, {
    headers: createHeader({ token, username }),
  });
  return res.data;
};

export const getProfile = async ({ token, username }) => {
  const res = await api.get(`/profile`, {
    headers: createHeader({ token, username }),
  });
  return res.data;
};

export const uploadProfileImage = async ({ token, username, profileImage }) => {
  const formData = new FormData();
  formData.append("image", profileImage);
  const response = await api.post(`/uploadImage`, formData, {
    headers: createHeader({ token, username }),
  });

  return response.data;
};

export const uploadServiceImage = async ({
  token,
  username,
  serviceImage,
  serviceId,
}) => {
  const formData = new FormData();
  formData.append("image", serviceImage);
  const response = await api.post(`/addServiceImage/${serviceId}`, formData, {
    headers: createHeader({ token, username }),
  });

  return response.data;
};

export const deleteServiceImage = async ({ token, username, serviceId }) => {
  const res = await api.delete(`/deleteServiceImage/${serviceId}`, {
    headers: createHeader({ token, username }),
  });
  return res.data;
};

export const verify = async ({ vt }) => {
  const res = await api.get(`/verify/?vt=${vt}`);
  return res.data;
};

export const resendOTP = async ({ values }) => {
  const res = await api.post("/resendMobileVerification", values, {});
  return res.data;
};

export const addServiceBugsReports = async ({ token, username, values }) => {
  const res = await api.post("/addServiceBugsReports", values, {
    headers: createHeader({ token, username }),
  });
  return res.data;
};
export const updateService = async ({ username, token, values, id }) => {
  const res = await api.put(`/updateServiceWithAttributes/${id}`, values, {
    headers: createHeader({ token, username }),
  });
  return res.data;
};

export const voteReview = async ({ token, username, vote, review_id }) => {
  const res = await api.post(
    "/voteReview",
    { vote, review_id },
    {
      headers: createHeader({ token, username }),
    }
  );
  return res.data;
};

export const forgotPassword = async ({ email_address }) => {
  const res = await api.post(
    "/forgotpassword",
    { email_address },
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return res.data;
};

export const changePassword = async ({ token, password }) => {
  const res = await api.post(
    "/changePassword",
    { token, password },
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return res.data;
};

export const searchServicesWithAttributes = async ({ token, username, values }) => {
  const queryParams = Object.keys(values)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(values[key]))
    .join("&");
  const res = await api.get(
    `/searchServicesWithAttributes?page=1&limit=100&${queryParams}`,
    {
      headers: createHeader({ token, username }),
    }
  );
  return res.data;
};

export const deleteServiceImageSolo = async ({ token, username, image }) => {
  const res = await api.delete(`/deleteServiceImageSolo/?image_url=${image}`, {
    headers: createHeader({ token, username }),
  });
  return res.data;
};
