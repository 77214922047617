/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { Col, Container } from "react-bootstrap";

const TermsAndConditions = () => {
  return (
    <Container>
      <Col xs={12} md={9} className="p-3">
        <br />
        <h1>Terms of Use</h1>
        <p>
          This document sets out the terms and conditions (“Terms”) on which you
          (“you” or “User”) may use the Prout web application available at
          www.prout.asia (“Prout App” or “Platform”).
        </p>
        <p>You must be 18 and above to use the Prout App.</p>
        <p>
          By using the Prout App, you warrant that you are 18 and above, and you
          confirm that you accept these Terms and that you agree to comply with them.
          If you do not agree to these Terms, you must not use the Prout App.
        </p>
        <p>
          We recommend that you print a copy of these Terms for future reference.
        </p>
        <p>
          These Terms of Use refer to additional terms, which also apply to your use
          of the Prout App : &nbsp; <br />
          <a href="/privacy-policy">Our Privacy Notice</a>
        </p>
        <h2>Definitions</h2>
        <p>
          “Content” means the content within Prout App, including but not limited to
          LGBQTIA+ resources, User Submissions, and Listings within the Critical
          Resource Directory;
        </p>
        <p>
          "Critical Resource Directory" means a directory of Listings on Prout App
          for Critical Sector Services;
        </p>
        <p>
          "Critical Sector Services" means critical sector services in the areas of:
          Legal, Housing, Healthcare, and Jobs;
        </p>
        <p>
          "Listings" means a page created by a Service Provider, and hosted on Prout
          App, comprising of details related to Critical Sector Services that may be
          offered by the Service Provider;
        </p>
        <p>
          "Platform" or "Prout App" means the web app accessible via www.prout.asia
          and hosted by PROUT;
        </p>
        <p>
          "Services" includes but is not limited to the offering of the Platform
          connecting Users to Listings;
        </p>
        <p>
          "Service Provider" means a User who provides Critical Sector Services in a
          professional capacity, and who has chosen to create and upload a Listing;
        </p>
        <p>
          "User" means a natural person who is a registered user of Prout App; and
        </p>
        <p>
          "User Submissions" means reviews, ratings, feedback, images, third party
          links or other information created and/or posted by Users on Prout App.
        </p>
        <h2>SECTION A: GENERAL PROVISIONS FOR ALL USERS</h2>
        <p>1. INFORMATION ABOUT PROUT AND PROUT APP</p>
        <p>
          1. Prout App is owned, managed, operated and maintained by Prout Pte Ltd,
          (registration number: 201802647Z), and having its registered address at
          TAMPINES INDUSTRIAL PARK A, 9008 TAMPINES STREET 93, #04-59, Postal 528843
          (“PROUT”).
        </p>
        <p>
          2. Prout App is an online listing platform offering a Critical Resource
          Directory which allows Service Providers to create Listings for Users.
          Prout App also allows Users to search for Listings.
        </p>
        <p>
          3. Users can create personal profiles (“Profiles”), and provide feedback on
          the Listings.
        </p>
        <p>
          4. Prout shall be entitled at its own discretion to suspend the Prout App
          for any reason whatsoever, including, but not limited to, repairs, planned
          maintenance or upgrades to the Prout App. Prout shall not be liable to you
          for any losses, damages, costs or expenses arising from or in connection
          with any suspension, delays, interruptions, or unavailability of the Prout
          App, including, but not limited to, preventing you from accessing the Prout
          App or Listings.
        </p>
        <p>
          5. Prout reserves the right to make any changes to the Prout App including
          any functionalities or Content, or to discontinue any aspect of the same
          without notice to you.
        </p>
        <p>
          6. Prout App may be contacted by email at:{" "}
          <a href="mailto:contact@proutapp.com">contact@proutapp.com</a>
        </p>
        <h2>GENERAL</h2>
        <p>
          1. Although we make reasonable efforts to update the information on the
          Platform, Prout makes no representations, warranties or guarantees, whether
          express or implied, that the details of the Service Providers and the
          Content on the Platform are accurate, complete or up to date. Your access
          to and use of any Content are at your own risk. You understand and agree
          that the Prout App is provided to you on an “AS IS” and “AS AVAILABLE”
          basis. Without limiting the foregoing, to the maximum extent permitted
          under applicable law, the Prout App disclaims all warranties and
          conditions, whether express or implied, of merchantability, fitness for a
          particular purpose, or non-infringement.
        </p>
        <p>
          2. A waiver by Prout of any default shall not constitute a waiver of any
          subsequent default.
        </p>
        <p>
          3. For the avoidance of doubt, references to “in writing” shall be deemed
          to include emails.
        </p>
        <p>
          4. If any term, condition, or provision of these Terms is determined by any
          competent authority to be invalid, unlawful or unenforceable to any extent,
          such term, condition, or provision will to that extent be severed from the
          remaining terms, conditions, and provisions which shall continue to be
          valid to the fullest extent permitted by law.
        </p>
        <p>
          5. These Terms and any document expressly referred to in them represent the
          entire agreement between you and Prout in respect of your use of the Prout
          App.
        </p>
        <p>
          6. You acknowledge that in entering into these Terms, you have not relied
          on any representation, undertaking or promise given by or implied from
          anything said or written, except as expressly set out in these Terms.
        </p>
        <p>7. Prout reserves the right to amend these Terms from time to time.</p>
        <p>
          8. These Terms are governed by and construed in accordance with the laws of
          Singapore. The courts of Singapore shall have exclusive jurisdiction to
          settle any dispute or claims arising out of or in connection with these
          Terms.
        </p>
        <ol>
          <li>
            <h2>Warranties</h2>
            <ol>
              <li>
                By registering your details as a User, you warrant that:
                <ol>
                  <li>
                    You are legally capable of entering into binding contracts;
                  </li>
                  <li>You are at least 18 years old;</li>
                  <li>
                    The information provided by you to Prout App is true, accurate
                    and correct. You further warrant that you shall promptly notify
                    Prout App in the event of any changes to such information; and
                  </li>
                  <li>
                    You are not in any way prohibited by the applicable law in the
                    jurisdiction in which you are currently located to enter into
                    these Terms for the use of the Platform.
                  </li>
                </ol>
              </li>
            </ol>
          </li>
          <li>
            <h3>Registration</h3>
            <ol>
              <li>
                In order to create a profile, you must complete the registration form
                and set up an account as a Prout App User ("Account").
              </li>
              <li>
                Prout reserves the right to:
                <ol>
                  <li>
                    Accept or reject your application to register for any reason; and
                  </li>
                  <li>
                    Suspend your Account and/or refuse you access to the Prout App
                    (partly or wholly) if we believe you have breached any of the
                    provisions in these Terms.
                  </li>
                </ol>
              </li>
            </ol>
          </li>
          <li>
            <h3>User Obligations</h3>
            <ol>
              <li>
                You agree that you are solely responsible and liable for all
                activities carried out by your use of the Prout App, including, but
                not limited to, for the content of any communication made when using
                or about the Prout App.
              </li>
              <li>
                You shall not upload User Submissions whether within your personal
                Profile, when submitting a Listing or feedback anywhere in the Prout
                App, which in Prout's reasonable opinion, may be deemed to be
                offensive, illegal, inappropriate or that in any way:
                <ol>
                  <li>
                    Promotes racism, bigotry, hatred, homophobia or physical harm of
                    any kind against any group or individual;
                  </li>
                  <li>Harasses or advocates harassment of another person;</li>
                  <li>Displays pornographic or sexually explicit material;</li>
                  <li>
                    Promotes any conduct that is abusive, threatening, obscene,
                    defamatory or libellous;
                  </li>
                  <li>Promotes any illegal activities;</li>
                  <li>
                    Promotes or contains information that you know or believe to be
                    inaccurate, false or misleading; or
                  </li>
                  <li>
                    Infringes any rights of any third party (including their
                    intellectual property rights).
                  </li>
                </ol>
              </li>
              <li>
                You acknowledge that making a User Submission does not guarantee that
                such User Submission, or any part thereof, shall appear on the Prout
                App. Prout may, at its sole discretion, choose to display or remove
                any User Submission.
              </li>
              <li>
                You hereby grant to Prout App a non-exclusive, perpetual,
                irrevocable, worldwide license to display on the Prout App any User
                Submission or any part of the same and for Prout App to make the User
                Submissions available to other Users of the Prout App.
              </li>
              <li>
                If you believe that any User Submission made by another User is
                incorrect or inappropriate, please report it using the Platform or
                contact Prout App using contact@proutapp.com. Prout App shall use its
                reasonable efforts to review the relevant User Submission as soon as
                practicable and shall take such action as it deems necessary, if any
                at all. If a User Submission is deemed to be in breach of these
                Terms, Prout reserves the right in its absolute discretion to suspend
                the account linked to the User Submission.
              </li>
              <li>
                You further agree that at all times, you shall:
                <ol>
                  <li>
                    Not use your login details with the intent of impersonating
                    another person;
                  </li>
                  <li>
                    Not share your login details with any other person, or allow them
                    to use your login details or your account;
                  </li>
                  <li>
                    Not use the information presented on the Prout App for commercial
                    purposes;
                  </li>
                  <li>
                    Not do anything likely to harass, cause harm or distress to other
                    Users or Prout employee;
                  </li>
                  <li>
                    Not create multiple accounts or create a new account if you have
                    previously been suspended and/or if you have been refused access
                    to the Prout App for any reason;
                  </li>
                  <li>
                    Not infringe any rights of any third parties (including their
                    intellectual property rights);
                  </li>
                  <li>Comply with all instructions and policies from Prout App;</li>
                  <li>
                    Cooperate with any reasonable security or other checks or
                    requests for information made by Prout App from time to time; and
                  </li>
                  <li>
                    Use the information made available to you on Prout App and
                    through the Listings at your own risk.
                  </li>
                </ol>
              </li>
            </ol>
          </li>
          <li>
            <h3>Exclusion of Warranties and Limitation of Liability</h3>
            <ol>
              <li>
                You acknowledge and agree that the Listings and User Submissions are
                not attributed to Prout nor are they endorsed by Prout and, to the
                maximum extent permitted by law, Prout excludes all liability for the
                accuracy, defamatory nature, completeness, timeliness, suitability,
                or otherwise of such Listings and User Submissions.
              </li>
              <li>
                You acknowledge and agree that Prout is not responsible or liable for
                any adverse or unfavorable Listings or User Submissions.
              </li>
              <li>
                Prout does not routinely monitor any User Submission or other
                information made available to you through your use of the Platform.
                Prout does not warrant or guarantee the accuracy, correctness,
                reliability, or suitability in respect of any User Submission,
                Service Provider, or Listings. If you intend to use the information,
                services, or products available on the Prout App, you hereby release
                Prout from any claims, demands, losses, expenses, and damages
                (whether direct, indirect, actual, or consequential) of any kind and
                nature, known and unknown, arising out of or in connection with such
                dispute and Prout will have no liability or responsibility to resolve
                such dispute.
              </li>
            </ol>
          </li>
          <li>
            <h3>Exclusion of Warranties and Limitation of Liability</h3>
            <ol>
              <li>
                You acknowledge and agree that the Listings and User Submissions are
                not attributed to Prout nor are they endorsed by Prout and, to the
                maximum extent permitted by law, Prout excludes all liability for the
                accuracy, defamatory nature, completeness, timeliness, suitability or
                otherwise of such Listings and User Submissions.
              </li>
              <li>
                You acknowledge and agree that Prout is not responsible or liable for
                any adverse or unfavourable Listings or User Submissions.
              </li>
              <li>
                Prout does not routinely monitor any User Submission, or other
                information made available to you through your use of the Platform.
                Prout does not warrant or guarantee the accuracy, correctness,
                reliability or suitability in respect of any User Submission, Service
                Provider, or Listings. If you intend to use and/or rely upon any User
                Submission, Service Provider Listing, or any other information made
                available to you through your use of the Platform, you do so at your
                own risk and liability. If Prout is made aware that a User Submission
                is incorrect, it may correct the User Submission as it sees fit.
              </li>
              <li>
                To the maximum extent permitted by applicable law, the Prout App
                shall not be liable for any indirect, incidental, special,
                consequential or punitive damages, or any loss of profits or
                revenues, whether incurred directly or indirectly, or any loss of
                data, use, goodwill, or other intangible losses, resulting from (i)
                your access to or use of or inability to access or use the Prout App;
                (ii) any conduct or content including Content provided by any Service
                Provider or any other third party on the Prout App, including without
                limitation, any defamatory, offensive or illegal conduct of other
                Users or third parties; (iii) any Content obtained from another User,
                User Submissions, the Listings, or any Service Provider; or (iv)
                unauthorized access, use or alteration of your transmissions or
                Content. The limitations of this section shall apply to any
                liability, whether based on warranty, contract, statute, tort
                (including negligence) or otherwise, and whether or not Prout App has
                been informed of the possibility of any such damage.
              </li>
              <li>
                Nothing in these Terms excludes or limits Prout’s liability for:
                <ol>
                  <li>death or personal injury caused by its negligence;</li>
                  <li>fraud or fraudulent misrepresentation by Prout; or</li>
                  <li>
                    any other matter for which it would be illegal for Prout to
                    exclude or attempt to exclude its liability.
                  </li>
                </ol>
              </li>
              <li>
                Prout does not in any way participate nor shall it be liable in any
                way for whatever reason for any communication, transaction, meet-up,
                set-up or relationship between you and other Users. Prout therefore
                recommends that you take all safety precautions when contacting,
                socialising and engaging in social gatherings or meetings, including
                with regard to the Listings, with other Users.
              </li>
            </ol>
          </li>
          <li>
            <h3>Intellectual Property Rights</h3>
            <ol>
              <li>
                Prout and its licensors own all the intellectual property rights in
                and relating to Prout App.
              </li>
              <li>
                Any content you upload to our site will be considered
                non-confidential and non-proprietary. You retain all of your
                ownership rights in your content, but you are required to grant us
                and other Users a limited licence to use, store and copy that content
                and to distribute and make it available to third parties.
              </li>
              <li>
                You are expressly prohibited from:
                <ol>
                  <li>
                    reproducing, copying, editing, transmitting, uploading or
                    incorporating into any other materials, any of Prout App, except
                    for the purposes of sharing content on social media platforms
                    such as Instagram, Facebook, etc; and
                  </li>
                  <li>
                    removing, modifying, altering or using any registered or
                    unregistered trademarks/logos/designs owned by Prout App or its
                    licensors, and doing anything which may be seen to take unfair
                    advantage of the reputation and goodwill of Prout App or could be
                    considered an infringement of any of the rights in the
                    intellectual property rights owned by and/or licensed to Prout
                    App.
                  </li>
                </ol>
              </li>
            </ol>
          </li>
          <li>
            <h3>Privacy and Data Protection</h3>
            <ol>
              <li>
                Any personal information that you provide to us will be dealt with in
                line with our Privacy Notice, which explains what personal
                information we collect from you, how and why we collect, store, use
                and share such information, your rights in relation to your personal
                information and how to contact us and supervisory authorities in the
                event you have a query or complaint about the use of your personal
                information.
              </li>
            </ol>
          </li>
          <li>
            <h3>Force Majeure</h3>
            <ol>
              <li>
                Prout shall not be liable or responsible for any failure to perform,
                or delay in performance of any of its obligations under these Terms
                that is caused by events outside its reasonable control (“Force
                Majeure Event”).
              </li>
              <li>
                A Force Majeure Event includes any act, event, non-happening,
                omission or accident beyond Prout’s reasonable control and includes
                in particular (without limitation) strikes, lock-outs, other
                industrial action, civil war, riot, invasion, terrorist attacks, fire
                explosions, storm, floods, earthquakes, epidemic or other natural
                disaster, impossibility of use of public or private telecommunication
                networks, acts, decrees, legislations or restrictions of any
                government (including lockdowns).
              </li>
              <li>
                Prout App’s performance under these Terms is deemed to be suspended
                for the period that the Force Majeure Event continues, and Prout App
                shall have an extension of time for performance for the duration of
                that period. Prout App will use its reasonable endeavours to bring
                the Force Majeure Event to a close or to find a solution by which its
                obligations under these Terms may be performed despite the Force
                Majeure Event.
              </li>
            </ol>
          </li>
          <li>
            <h3>Complaints</h3>
            <ol>
              <li>
                If you wish to lodge a complaint about another User for breaching any
                of these Terms, you may do so by sending Prout details of your
                complaint by emailing contact@proutapp.com. Prout will use its
                reasonable endeavours to respond to your complaints within a
                reasonable time and to take reasonable action which it deems
                appropriate to attempt to resolve or rectify the subject matter of
                such complaints.
              </li>
            </ol>
          </li>
          <li>
            <h3>Termination</h3>
            <ol>
              <li>
                Prout may suspend or terminate your use of the Prout App at its
                entire discretion if:
                <ol>
                  <li>
                    Prout believes you or someone using your login details has failed
                    to comply with one or more of these Terms;
                  </li>
                  <li>
                    Prout believes there has been fraudulent use, misuse or abuse of
                    the Prout App;
                  </li>
                  <li>
                    Prout believes you have provided any false, inaccurate or
                    misleading information; or
                  </li>
                  <li>
                    Prout believes that your use of the Prout App may cause harm or
                    distress to other Users.
                  </li>
                </ol>
              </li>
              <li>
                On termination, your access to the Prout App shall cease and Prout
                may delete your account.
              </li>
              <li>
                We will notify you that your account has been terminated, banned or
                suspended unless you repeatedly breach the Terms or we have legal or
                regulatory reasons preventing us from notifying you.
              </li>
            </ol>
          </li>
        </ol>
        <h2>Terms and Conditions</h2>
        <p>SECTION A: GENERAL TERMS AND CONDITIONS</p>
        <ol>
          <li>
            <h3>Deleting Your Account</h3>
            <ol>
              <li>
                You may delete your account on the Prout App at any time. If you
                terminate your account, you may lose any information associated with
                your account, including your profile, any User Submissions, and any
                related content.
              </li>
            </ol>
          </li>
          <li>
            <h3>Internet Security</h3>
            <ol>
              <li>
                We do not guarantee that the Prout App will be secure or free from
                bugs or viruses.
              </li>
              <li>
                You are responsible for configuring your information technology,
                computer programmes, and platform to access our site. You should use
                your own virus protection software.
              </li>
              <li>
                You must not misuse our site by knowingly introducing viruses,
                trojans, worms, logic bombs, or other material that is malicious or
                technologically harmful. You must not attempt to gain unauthorized
                access to Prout App, the server on which Prout App is stored, or any
                server, computer, or database connected to Prout App. You must not
                attack Prout App via a denial-of-service attack or a distributed
                denial-of-service attack.
              </li>
            </ol>
          </li>
          <li>
            <h3>Linking to Prout App</h3>
            <ol>
              <li>
                While you may link to the Prout App, you must not establish a link in
                such a way as to suggest any form of association, approval, or
                endorsement on our part where none exists. We reserve the right to
                withdraw linking permission without notice.
              </li>
            </ol>
          </li>
          <li>
            <h3>Service Providers</h3>
            <ol>
              <li>
                <h4>Introduction</h4>
                <ol>
                  <li>
                    If you are a Service Provider who wishes to submit a Listing in a
                    professional capacity, you must first register and become a User
                    of Prout App. Upon registration as a User, and upon successful
                    verification of all details Prout may require, Prout may grant
                    you the right to upload Listings.
                  </li>
                </ol>
              </li>
              <li>
                <h4>Service Providers' Rights</h4>
                <ol>
                  <li>
                    Service Providers may be granted the following rights:
                    <ol>
                      <li>
                        The facility to create a Profile page (including a photo);
                      </li>
                      <li>The ability to make Listings;</li>
                      <li>
                        The ability to post reviews and/or submit feedback about
                        Listings; and
                      </li>
                      <li>
                        Any other features and functionalities provided by Prout from
                        time to time.
                      </li>
                    </ol>
                  </li>
                </ol>
              </li>
              <li>
                <h4>Additional Obligations as a Service Provider</h4>
                <ol>
                  <li>
                    By choosing to be a Service Provider, in addition to the general
                    obligations set out in section A of these Terms, you agree that
                    all information submitted by you must:
                    <ol>
                      <li>Be accurate, correct, and up-to-date;</li>
                      <li>
                        Not breach any applicable statutory or regulatory
                        requirements;
                      </li>
                      <li>Not commit an offence;</li>
                      <li>
                        Not be misleading, deceptive, or in any way contravene any
                        and all applicable consumer, health and safety, and
                        e-commerce laws and regulations;
                      </li>
                      <li>
                        Not be obscene, defamatory, or be in the reasonable view of
                        Prout deemed to be offensive and/or inappropriate.
                      </li>
                    </ol>
                  </li>
                  <li>
                    In creating a Listing, you must:
                    <ol>
                      <li>At all times keep all information secure and accurate;</li>
                      <li>Ensure your use of the Listings is personal to you;</li>
                      <li>
                        Have obtained the permission of your employer if you are
                        representing and including details of your company in a
                        Listing; and
                      </li>
                      <li>
                        Immediately notify Prout App in the event you have any reason
                        to believe or suspect that a Listing has breached any of the
                        Terms.
                      </li>
                    </ol>
                  </li>
                  <li>
                    You further acknowledge and agree that Prout may, at its sole
                    discretion, immediately remove your account and/or Listing from
                    the Prout App for any reason whatsoever at its absolute
                    discretion.
                  </li>
                </ol>
              </li>
              <li>
                <h4>Additional Exclusion of Warranties</h4>
                <ol>
                  <li>
                    You acknowledge and agree that Prout only provides you with the
                    facility to create Listings. Prout does not warrant, represent,
                    or guarantee that you will achieve any specific results.
                  </li>
                  <li>
                    Your use and reliance on any information on the Prout App shall
                    be at your own risk, and Prout App shall not be liable whatsoever
                    for any damages and loss which you may incur as a result of or in
                    connection with your use and reliance on such information. You
                    undertake to conduct your own research and ensure that you comply
                    with the requirements, laws, and regulations applicable to you.
                    Prout shall not be liable to you for any failure by you to comply
                    with any relevant laws, requirements, and regulations that may
                    apply to you in the use of the Prout App.
                  </li>
                  <li>
                    You further agree that Prout App does not vet or verify the
                    identity of the Users. Prout does not warrant or guarantee the
                    completeness, accuracy, correctness, or identity of any User of
                    the Prout App.
                  </li>
                  <li>
                    You further acknowledge that Prout has no control over and
                    therefore has no liability whatsoever in respect of the quality
                    of the Listings.
                  </li>
                  <li>
                    Prout's primary function is to connect Users to Listings created
                    by Service Providers. You, as a Service Provider, shall hereby
                    indemnify Prout in full and on demand against all losses,
                    damages, costs, claims, and expenses that Prout incurs in
                    connection with any of your dealings with Listings, including
                    losses and damages incurred by Prout in respect of any third
                    party infringements, negligence, or omissions applicable to any
                    Listings shared by you via the Prout App.
                  </li>
                </ol>
              </li>
              <li>
                <h4>Intellectual Property Rights</h4>
                <ol>
                  <li>
                    In addition to the general intellectual property provisions set
                    out in these Terms, the following additional terms and conditions
                    apply to Service Providers:
                  </li>
                  <li>
                    You warrant that:
                    <ol>
                      <li>
                        You are the legal owner or valid licensor of all the
                        intellectual property rights in and relating to the
                        photographs, images, and copy that you provide or upload to
                        Listings;
                      </li>
                      <li>
                        Our use of your intellectual property rights will not
                        infringe any intellectual property rights owned by any third
                        party, and there is and will be no claim against us by any
                        third party arising in relation to the use of such
                        intellectual property rights; and
                      </li>
                      <li>
                        Listings created by you are not fakes, trolls, or inaccurate
                        information.
                      </li>
                    </ol>
                  </li>
                  <li>
                    You permit us to access and use any Content, including photos,
                    that appear on your Listing, in connection with Prout's business.
                  </li>
                  <li>
                    You hereby grant to Prout App a non-exclusive, perpetual,
                    irrevocable, worldwide license to display on the Prout App any
                    Listing or any part of the same and for Prout App to make the
                    Listings available to other Users of the Prout App.
                  </li>
                  <li>
                    You shall indemnify us in full for any and all damages,
                    liabilities, costs, expenses, and/or losses resulting from any
                    breach of this clause 18 in respect of any claim that the normal
                    operation, possession, or use of those intellectual property
                    rights by us infringes a third party's intellectual property
                    rights.
                  </li>
                </ol>
              </li>
            </ol>
          </li>
        </ol>
      </Col>
    </Container>
  );
};

export default TermsAndConditions;
