import {
  PayloadAction,
  // configureStore,
  // createSelector,
  createSlice,
} from "@reduxjs/toolkit";
//import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: {
    search: "",
    category: "",
    page: 1,
  },
  reducers: {
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    setCategory: (state, action: PayloadAction<string>) => {
      state.category = action.payload;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
  },
});

export const { setSearch, setCategory, setPage } = dashboardSlice.actions;
export default dashboardSlice.reducer;
