import React from "react";
import FOOTER_IG from "../../../images/footer_instagram.svg";
import FOOTER_SHOP from "../../../images/footer_shopping.svg";
import FOOTER_TG from "../../../images/footer_telegram.svg";

interface ImageTileProps {
  image: string;
  widthxy: string;
  altText: string;
  onClick: () => void;
}

const ImageTile: React.FC<ImageTileProps> = ({
  image,
  widthxy,
  altText,
  onClick,
}) => {
  return (
    <img
      src={image}
      alt={altText}
      style={{ width: widthxy, height: widthxy }}
      onClick={onClick}
    />
  );
};

const DashboardFooter: React.FC<{ isBottom: boolean }> = ({ isBottom }) => {
  // Replace with actual image path
  const images = { alt: "image description" }; // Replace with actual alt text

  return (
    <footer
      className={`fixed bottom-0 w-full bg-purple-700 text-center transition-transform duration-1000 ${
        isBottom ? "transform translate-y-0" : "transform translate-y-full"
      }`}
      style={{
        height: "250px",
        background: "#613D70",
        textAlign: "center",
      }}
    >
      <div className="text-white text-sm font-light flex items-center w-full content-center">
        <div className="w-1/2 ml-6 mt-8 mb-6 justify-start text-left">
          <p>
            <span>
              Are you an inclusive service provider in the areas of legal,
              healthcare, housing, or jobs?
            </span>
            <span> Contact us at </span>
            <span>contact@proutapp.com</span>
          </p>
          <p>Terms and Conditions Privacy Policy © prout 2024</p>
        </div>
        <div className="w-1/2 mr-3"></div>
        <div className="w-1/2 mr-3 mt-6 mb-6">
          <div className="justify-end">
            <a
              className="navlink-black"
              href="https://www.instagram.com/proutapp"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => {}}
            >
              <ImageTile
                key={0}
                image={FOOTER_IG}
                widthxy="40px"
                altText={images.alt}
                onClick={() => {}}
              />
            </a>
          </div>
          <div className="mt-6 mb-6">
            <a
              className="navlink-black"
              href="https://prout.shop"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => {}}
            >
              <ImageTile
                key={0}
                image={FOOTER_SHOP}
                widthxy="40px"
                altText={images.alt}
                onClick={() => {}}
              />
            </a>
          </div>
          <div className="">
            <a
              className="navlink-black"
              href="https://t.me/proutapp"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => {}}
            >
              <ImageTile
                key={0}
                image={FOOTER_TG}
                widthxy="40px"
                altText={images.alt}
                onClick={() => {}}
              />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default DashboardFooter;
