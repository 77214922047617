import { useQuery } from "@tanstack/react-query";
import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getOwnServicesWithAttributes } from "../../api/auth";
import { getEmail, getToken, setServices } from "../../features/user/userSlice";
import { formatDate } from "../../helpers/formatDate";
import "../../styles/dashboard.css";
import CenteredCircularProgress from "../common/CenteredCircularProgress";
import FloatingActionButton from "../common/FloatingActionButton";
import PageSelector from "../common/PageSelector";
import ServicePagesItem from "./ServicePagesItem";

const MyPages = () => {
  // const [isExpanded, setIsExpanded] = useState(false);
  const dispatch = useDispatch();
  const pages = [
    { id: 1, title: "Page 1" },
    { id: 2, title: "Page 2" },
    { id: 3, title: "Page 3" },
    { id: 4, title: "Page 4" },
    { id: 5, title: "Page 5" },
  ];
  // const users = ["Elon", "Bill", "Mark"];
  const navigate = useNavigate();
  const handlePageChange = (page) => {
    //console.log("Selected page:", page);
  };
  const token = useSelector(getToken);
  const email = useSelector(getEmail);
  //const dispatch = useDispatch();

  const { data, isLoading } = useQuery({
    queryFn: () => getOwnServicesWithAttributes({ token, username: email }),
    queryKey: ["services"],
    refetchOnWindowFocus: false,
  });

  const SERVICES = data?.response ?? [];

  useEffect(() => {
    localStorage.setItem("services", JSON.stringify(SERVICES));
    dispatch(setServices(SERVICES));
  }, [SERVICES, dispatch, setServices]);

  if (isLoading) {
    return <CenteredCircularProgress />;
  }

  if (!isLoading) {
    localStorage.setItem("services", JSON.stringify(SERVICES));
    dispatch(setServices(SERVICES));
    // useEffect(() => {
    //   dispatch(setExpanded(false));
    // }, []);
  }

  return (
    <Container fluid className="container">
      <PageSelector pages={pages} onChange={handlePageChange} />
      {/* <Row>
        <Col xs={12} md={9} className="p-4">
          <h1>My Dashboard {email}</h1>
        </Col>
      </Row> */}
      {SERVICES.length === 0 && (
        <h5 style={{ textAlign: "center", marginTop: 100 }}>No Listings</h5>
      )}

      {SERVICES.map((service) => (
        <ServicePagesItem
          _id={service._id}
          name={service.name}
          since={
            service.verified === "FALSE"
              ? "Not Verified"
              : `Verified Since: ${formatDate(service.verified)}`
          }
          key={service._id}
          serviceImage={
            service.images.at(service.images.length - 1) &&
            `${service.base_url}${service.images.at(service.images.length - 1)}`
          }
          office={service.category_type}
          onClick={() => navigate(`/read_page/${service._id}`)}
        />
        // <Box
        //   key={service._id}
        //   sx={{
        //     padding: 3,
        //     backgroundColor: "#e6e1e1",
        //     margin: "3px 0",
        //     cursor: "pointer",
        //   }}
        //   onClick={() => navigate(`/read_page/${service._id}`)}
        // >
        //   <Typography variant="body1" textTransform="capitalize">
        //     {service.firstname || "No firstname"}
        //   </Typography>
        // </Box>
      ))}
      <div
        style={{ display: "none" }}
        className="fab-container"
        onClick={() => navigate("/review_panel")}
      >
        <FloatingActionButton />
      </div>
    </Container>
  );
};

export default MyPages;
