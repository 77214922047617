import { ExclamationCircleFilled } from "@ant-design/icons";
import { Box, Typography } from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Form, Modal, message } from "antd";
import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { Provider, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getOtherServicesWithAttributes,
  searchServicesWithAttributes,
} from "../../api/auth";
import {
  getCurrentService,
  getEmail,
  getFilterOpen,
  getSearchOpen,
  getToken,
  setCurrentService,
  setFilterOpen,
  setSearchOpen,
  setServices,
} from "../../features/user/userSlice";
import debounce from "../../helpers/debouncer";
import { removeDuplicates } from "../../helpers/duplicateRemover";
import "../../styles/dashboard.css";
import CenteredCircularProgress from "../common/CenteredCircularProgress";
import DelayedRender from "../common/DelayedRender";
import FloatingActionButton from "../common/FloatingActionButton";
import ImageTile from "../common/ImageTile";
import store from "../common/MasterStore";
import ServiceItem from "./ServiceItem";

import HEALTH from "../../../images/prout_health.png";
import JOBS from "../../../images/prout_job.png";
import LEGAL from "../../../images/prout_legal.png";
import RENT from "../../../images/prout_rent.png";

import HEALTHREC from "../../../images/prout_healthcare_rec.jpg";
import RENTREC from "../../../images/prout_housing_rec.jpg";
import JOBSREC from "../../../images/prout_jobs_rec.jpg";
import LEGALREC from "../../../images/prout_legal_rec.jpg";

import { RiFilter3Line } from "react-icons/ri";
import { hasDecimal, removeDecimals } from "../../helpers/decimals";
import shuffleArray from "../../helpers/randomizer";
import DashboardFooter from "../common/DashboardFooter";
import DropdownCustom from "../common/DropdownCustom";
import SearchCategory from "../common/SearchCategory";
import useScrollToBottom from "../hooks/UseScrollToBottom";

import Filter from "./Filter";

const isCategoryRectangle = localStorage.getItem("categoryRectangle", false);
const pagination_count = import.meta.env.VITE_SERVICE_PAGINATION_COUNT || 11;
const Image = ({ image, isSelected }) => {
  return (
    <img
      src={image}
      alt=""
      style={{ filter: isSelected ? "grayscale(0%)" : "grayscale(75%)", width: 50 }}
    />
  );
};

const Homepage = () => {
  const isBottom = useScrollToBottom();
  const dispatch = useDispatch();
  //const [filterValues, setFilterValues] = useState({});
  const currentService = useSelector(getCurrentService);
  const [category, setCategory] = useState(currentService);
  const [categoryRectangle, setCategoryRectangle] = useState(isCategoryRectangle);
  useEffect(function categoryHandle() {}, [categoryRectangle]);
  const [pageError, setPageError] = useState(false);

  const [localStorageServices, setLocalStorageServices] = useState(
    JSON.parse(localStorage.getItem("services"))
  );
  const [SERVICES, setServicesList] = useState([]);

  const [localSERVICES, setLocalSERVICES] = useState(
    localStorageServices?.length ? localStorageServices : SERVICES
  );
  //Math.round( someNumber * 1e2 ) / 1e2
  // const [page, setPage] = useState(
  //   +(localSERVICES?.length / pagination_count).toFixed() || 1
  // );
  const [page, setPage] = useState(
    +(localStorageServices?.length / pagination_count).toFixed() || 1
  );

  const navigate = useNavigate();

  const token = useSelector(getToken);
  const email = useSelector(getEmail);
  const isSearchModalOpen = useSelector(getSearchOpen);
  const isFilterModalOpen = useSelector(getFilterOpen);
  //const dispatch = useDispatch();

  // State to store the services list
  const [initialLoading, setInitialLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(
    JSON.parse(localStorage.getItem("total_count")) || 0
  );
  const {
    data: x,
    isLoading: useQueryLoading,
    refetch,
  } = useQuery({
    queryFn: () =>
      getOtherServicesWithAttributes({
        token,
        username: email,
        category,
        page: removeDecimals(page),
      }),
    queryKey: ["services", category],
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    retry: false,
    enabled: false,
    onSettled: (data, error) => {
      if (error === null) {
        if (
          data.response.total_count != null &&
          data.response.total_count != undefined
        ) {
          setTotalCount(data.response.total_count);
          localStorage.setItem(
            "total_count",
            JSON.stringify(data.response.total_count)
          );
        }
      }
    },
    onSuccess: (data) => {
      //console.log("FETCH IS CALLED", page, data.response.services.length > 0);

      if (page > 1) {
        setTimeout(() => {
          if (data.response.services.length > 0) {
            const sc = (prev) => {
              return [
                ...prev,
                ...shuffleArray([
                  ...data.response.services.sort(() => {
                    return Math.random() - 0.5;
                  }),
                ]),
              ];
            };
            //saveToLocal([1, 2, 3]);
            setServicesList((prev) => {
              return sc(prev);
            });
          }
          //setInitialLoading(false);
        }, 500);
        //
      } else {
        //for intial and category change
        setTimeout(() => {
          if (data.response.services != null && data.response.services != undefined)
            if (data.response.services.length > 0) {
              const dt = shuffleArray(data.response.services);
              setServicesList(dt);
              saveToLocal(dt);
              if (page === 1) {
                //prepping for pagination
                setPage(2);
              }
            }
          setInitialLoading(false);
        }, 500);
      }

      setTimeout(() => {
        setPageError(false);
      }, 500);
    },
    onError: () => {
      setPageError(true);
      //setPage((prev) => prev - 1);
      setTimeout(() => {
        setInitialLoading(false);
      }, 500);
    },
  });

  const searchMutation = useMutation({
    mutationFn: searchServicesWithAttributes,
    onSuccess: (response) => {
      setLocalSERVICES(response.response);
      searchForm.resetFields();
      dispatch(setSearchOpen(false));
      dispatch(setFilterOpen(false));
      localStorage.setItem("services", JSON.stringify(response.response));
    },
    onError: () => {
      message.error("No results");
    },
  });

  const [form] = Form.useForm();
  const [searchForm] = Form.useForm();

  //const SERVICES = data?.response ?? [];

  const callServices = (category) => {
    setCategory(category);
    setPage(1);
    //setServicesList([]);
    // refetch();
  };

  function saveCategoryLocal(data) {
    setInitialLoading(true);
    callServices(data);
    dispatch(setCurrentService(data));
    localStorage.setItem("category", data);
  }

  function removeUndefinedValues(obj) {
    for (let key in obj) {
      if (obj[key] === undefined || obj[key] === "") {
        delete obj[key];
      }
    }
    return obj;
  }

  function setGlobalCategoryRectangle() {
    // localStorage.setItem("categoryRectangle", true);
    // setCategoryRectangle(true);
    // window.history.go(0);
  }

  function goToNext() {
    navigate("/dashboard");
  }

  const handleSubmitSearch = (values) => {
    const cleanValues = removeUndefinedValues(values);
    cleanValues["category"] = category;
    searchMutation.mutate({ token, username: email, values: cleanValues });
  };

  const filterObjToArr = (obj) => {
    const newF = [];
    Object.entries(obj).forEach(([key, val]) => {
      newF.push({ [key]: val });
    });
    return newF;
  };

  function filterAttributes(testData, filters) {
    return testData.filter((data) => {
      return filters.every((filter) => {
        const filterKey = Object.keys(filter)[0];
        const filterValue = filter[filterKey];
        return data.attributes.some((attribute) => {
          return (
            attribute.attribute === filterKey && attribute.value === filterValue
          );
        });
      });
    });
  }

  function filterBySpecialisation(data, specialisation) {
    const filteredData = [];
    for (const item of data) {
      const attributes = item.attributes || [];
      for (const attr of attributes) {
        if (
          attr.attribute === "specialisation" &&
          attr.value.includes(specialisation)
        ) {
          filteredData.push(item);
          break;
        }
      }
    }
    return filteredData;
  }

  const handleLastDropdownChange = (value) => {
    if (value !== undefined) {
      form.submit();
    }
  };

  function filterUndefinedValues(obj) {
    const filteredObj = {};
    for (const key in obj) {
      if (obj[key] !== undefined) {
        filteredObj[key] = obj[key];
      }
    }
    return filteredObj;
  }

  useEffect(
    function handleLoading() {
      setInitialLoading(useQueryLoading);
    },
    [useQueryLoading]
  );

  useEffect(function profileChecker() {
    const isProfileComplete = localStorage.getItem("isProfileComplete");
    if (isProfileComplete === "false") {
      Modal.confirm({
        title:
          "We have observed that your profile is incomplete. To enhance overall user experience, we kindly request you to kindly take a moment to fill out your profile. Thank you!",
        icon: <ExclamationCircleFilled />,
        okText: "OK",
        okType: "primary",
        onOk: () => {
          localStorage.removeItem("isProfileComplete");
          navigate("/profile");
        },
      });
    }
  }, []);

  useEffect(
    function serviceUpdates() {
      if (SERVICES?.length === 0 && localSERVICES.length > 0) {
        //onrefresh, or if revisiting this page, populate service via local storage
        setServicesList(localSERVICES);
      } else if (SERVICES?.length > 0) {
        //fills local services with SERVICES
        setLocalSERVICES(SERVICES);
        saveToLocal(SERVICES);
      } else {
        //initial call
        refetch();
      }
    },
    [SERVICES]
  );
  //---------------------------------PAGINATION ONLY------------------------//

  const loadMoreData = () => {
    //pagination
    setPage((prevPage) => {
      if (totalCount >= localSERVICES.length) {
        const newPage = prevPage + 1.001;
        //refetch();
        return newPage;
      }
    });
    if (pageError) {
      setTimeout(() => {
        setInitialLoading(false);
      }, 500);
    }
  };

  useEffect(
    //this prevents page to exceed logical page count
    function pageProtection() {
      const pageShield = +(localSERVICES.length / pagination_count).toFixed() + 1;
      if (page > pageShield) {
        setPage(pageShield);
      }
      //refetch for pagination
      if (hasDecimal(page)) {
        refetch();
      }
      setInitialLoading(false);
    },
    [page]
  );

  useEffect(
    function paginationScroll() {
      //used for pagination scrolling
      //setPage(+(localSERVICES.length / pagination_count).toFixed());
      const handleScroll = debounce(() => {
        if (
          initialLoading ||
          window.innerHeight + document.documentElement.scrollTop <
            document.documentElement.offsetHeight - 100
        )
          return;
        // if (SERVICES.length != totalCount) {
        setInitialLoading(true);
        loadMoreData();
        //}
      }, 777);

      window.addEventListener("scroll", handleScroll);

      return () => window.removeEventListener("scroll", handleScroll);
    },
    [initialLoading]
  );

  //---------------------------------PAGINATION ONLY------------------------//

  useEffect(
    function categoryFetch() {
      //used for selecting categories.
      if (localSERVICES.length === 0) {
        refetch();
      }
    },
    [category]
  );

  function resetFilter() {
    const storogeSVC = JSON.parse(localStorage.getItem("services"));
    setLocalSERVICES(storogeSVC?.length ? storogeSVC : SERVICES);
  }

  function saveToLocal(data) {
    localStorage.setItem("services", JSON.stringify(data));
    setLocalStorageServices(data);
    dispatch(setServices(data));
  }
  const images = [
    { src: "../../../images/prout_health.png", alt: "Image 1", label: "Image 1" },
    { src: "../../../images/prout_health.png", alt: "Image 2", label: "Image 2" },
    { src: "../../../images/prout_health.png", alt: "Image 3", label: "Image 3" },
    { src: "../../../images/prout_health.png", alt: "Image 4", label: "Image 4" },
  ];

  const dropdownOptions = [
    {
      label: "Legal",
      onClick: () => {
        saveCategoryLocal("LEGAL");
        if (category !== "LEGAL") {
          form.resetFields();
          setLocalSERVICES([]);
          saveToLocal([]);
        } else {
          refetch();
          //debounce(() => refetch(), 1000);
        }
      },
    },
    {
      label: "Housing",
      onClick: () => {
        saveCategoryLocal("HOUSING");
        if (category !== "HOUSING") {
          form.resetFields();
          setLocalSERVICES([]);
          saveToLocal([]);
        } else {
          refetch();
          //debounce(() => refetch(), 1000);
        }
      },
    },
    {
      label: "Healthcare",
      onClick: () => {
        saveCategoryLocal("HEALTH");
        if (category !== "HEALTH") {
          form.resetFields();
          setLocalSERVICES([]);
          saveToLocal([]);
        } else {
          refetch();
        }
      },
    },
    {
      label: "Jobs",
      onClick: () => {
        saveCategoryLocal("JOB");
        resetFilter();
        if (category !== "JOB") {
          form.resetFields();
          setLocalSERVICES([]);
          saveToLocal([]);
        } else {
          refetch();
          //debounce(() => refetch(), 1000);
        }
      },
    },
  ];

  return (
    <Provider store={store}>
      <Container fluid className="container overflow-hidden">
        {/* <PageSelector pages={pages} onChange={handlePageChange} /> */}
        
        
        {/* initial content is here */}
        {1 == 1 ? (
          <div>
            <div className="mt-3 mb-3 ml-3 mr-3">
              {" "}
              <div className="mt-3 mb-3 ml-3 mr-3">
                <ImageTile
                  className=""
                  key={0}
                  image={HEALTHREC}
                  widthxy="100%"
                  altText={images.alt}
                  onClick={() => {
                    setGlobalCategoryRectangle();
                    dropdownOptions[2].onClick();
                    goToNext();
                  }}
                />
                </div>
                <div className="mt-3 mb-3 ml-3 mr-3">
                <ImageTile
                  className=""
                  key={0}
                  image={LEGALREC}
                  widthxy="100%"
                  altText={images.alt}
                  onClick={() => {
                    setGlobalCategoryRectangle();
                    dropdownOptions[0].onClick();
                    goToNext();
                  }}
                />
              </div>
              <div className="mt-3 mb-3 ml-3 mr-3">
                <ImageTile
                  className=""
                  key={0}
                  image={RENTREC}
                  widthxy="100%"
                  altText={images.alt}
                  onClick={() => {
                    setGlobalCategoryRectangle();
                    dropdownOptions[1].onClick();
                    goToNext();
                  }}
                />
              </div>
           
            <div className="mt-3 mb-3 ml-3 mr-3">
              <ImageTile
                className=""
                key={0}
                image={JOBSREC}
                widthxy="100%"
                altText={images.alt}
                onClick={() => {
                  setGlobalCategoryRectangle();
                  dropdownOptions[3].onClick();
                  goToNext();
                }}
              />
            </div>
            </div>
          </div>
        ) : (
          <div />
        )}
        {/* main content is here */}

        {localSERVICES.length === 0 && !initialLoading && (
          <DelayedRender>
            <h5 style={{ textAlign: "center", marginTop: 100 }}>
              <span className="block">There’s nothing here right now.</span>
              <span className="block">Come back later!</span>
            </h5>
          </DelayedRender>
        )}
        {initialLoading && (
          <div className="m-4">
            <CenteredCircularProgress />
          </div>
        )}
        <div
          style={{ display: "none" }}
          className="fab-container"
          onClick={() => navigate("/review_panel")}
        >
          <FloatingActionButton />
        </div>
        <Modal
          title={`Search:   ` + category}
          open={isSearchModalOpen}
          onOk={() => {
            setCategoryRectangle(true);
            if (!isCategoryRectangle) {
              //so that it doesnt always refresh when using search since setGlobal.. refreshes page
              setGlobalCategoryRectangle();
            }
            searchForm.submit();
          }}
          okText="Search"
          onCancel={() => dispatch(setSearchOpen(false))}
          disabled
        >
          <Form onFinish={handleSubmitSearch} form={searchForm}>
            <SearchCategory category={category.toLowerCase()} />
          </Form>
        </Modal>
        <Modal
          title={`Filter `}
          open={isFilterModalOpen}
          footer={null}
          onCancel={() => dispatch(setFilterOpen(false))}
          disabled
        >
          {/* <Form onFinish={handleSubmitSearch} form={searchForm}>
            <SearchCategory category={category.toLowerCase()} />
          </Form> */}
          <Filter
            orientation="horizontal"
            onSubmit={(data) => {
              const type = {
                HEALTH: "health_specialisation",
                LEGAL: "legal_specialisation",
              };

              if (category == "HEALTH") {
                handleSubmitSearch({ specialisation: data.health_specialisation });
              } else if (category == "LEGAL") {
                handleSubmitSearch({ specialisation: data.legal_specialisation });
              } else {
                if (Object.keys(data)[0].split("_")[1] === "specialisation") {
                  setLocalSERVICES(
                    filterBySpecialisation(SERVICES, data[type[category]])
                  );
                  return;
                }

                const filteredValues = filterUndefinedValues(data);
                setLocalSERVICES(
                  filterAttributes(SERVICES, filterObjToArr(filteredValues))
                );
              }
              setFilterOpen();
            }}
            category={category}
            handleLastDropdownChange={handleLastDropdownChange}
            form={form}
            reset={
              category == "HEALTH" || category == "LEGAL" ? refetch : resetFilter
            }
          />
        </Modal>
      </Container>

      {/* <DashboardFooter isBottom={isBottom} /> */}
    </Provider>
  );
};

export default Homepage;
