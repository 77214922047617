/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";

import { Col, Container, Form } from "react-bootstrap";
import "../../styles/commons.css";
import "../../styles/dashboard.css";
import "../../styles/ratingstar.css";
import "../../styles/register.css";

import { Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getReviews } from "../../api/auth";
import { getEmail, getServices, getToken } from "../../features/user/userSlice";
import CenteredCircularProgress from "../common/CenteredCircularProgress";
import RatingStars from "../reviews/RatingStars";
import ReviewItem from "../reviews/ReviewItem";
import CreateReviewExisting from "./CreateReviewExisting";

const ReviewDetails = () => {
  const location = useLocation();
  //console.log(location.pathname);
  const { serviceId } = useParams();

  // const [isExpanded, setIsExpanded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const token = useSelector(getToken);
  const email = useSelector(getEmail);

  const { data, isLoading: reviewLoading } = useQuery({
    queryFn: () => getReviews({ token, username: email, service_id: serviceId }),
    queryKey: ["reviews", serviceId],
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    retry: 1,
  });

  const REVIEWS = data?.response || [];
  const [averageRating, setAverageRating] = useState(0);

  // const toggleMenu = () => {
  //   //console.log(setIsLoading + "" + isLoading);
  //   setIsExpanded(!isExpanded);
  // };

  // const reviews = [
  //   {
  //     reviewer: "John Doe",
  //     since: "Since April 2021",
  //     rating: 3.5,
  //     review: "Super understanding with great confidence in her work.",
  //     helpful: 0,
  //     notHelpful: 2,
  //   },
  //   {
  //     reviewer: "Jane Doe",
  //     since: "Since April 2021",
  //     rating: 2.5,
  //     review: "Super understanding with great confidence in her work.",
  //     helpful: 1,
  //     notHelpful: 3,
  //   },
  //   {
  //     reviewer: "Toronto Doe",
  //     since: "Since April 2021",
  //     rating: 2.5,
  //     review: "Super understanding with great confidence in her work.",
  //     helpful: 3,
  //     notHelpful: 10,
  //   },
  // ];

  const services = useSelector(getServices);
  const navigate = useNavigate();

  const _user = services.find((service) => service._id.toString() === serviceId);

  useEffect(() => {
    if (REVIEWS.length > 0) {
      let totalRating = 0;
      REVIEWS.forEach((review) => {
        totalRating += review.rating;
      });
      setAverageRating(totalRating / REVIEWS.length);
    }
  }, [REVIEWS]);

  if (reviewLoading) {
    return <CenteredCircularProgress />;
  }
  if (!isLoading) {
    //console.log(data?.response);
  }
  const username = localStorage.getItem("email", 1);
  const profile_id = localStorage.getItem("profileId", 1);
  // console.log(
  //   _user.profile_id,
  //   username,
  //   "user daw to",
  //   REVIEWS.some((review) => review.username === username)
  // );
  return (
    <Container fluid className="container">
      {/* <Header toggleMenu={toggleMenu} title="Reviews" />
      <SidePanel isExpanded={isExpanded} toggleMenu={toggleMenu} /> */}

      <Col xs={12} md={9} className="p-4">
        <Form>
          <Form.Group controlId="pageName" className="form-group-spacing">
            <Form.Label>Reviews for {_user.name}</Form.Label>
            <br />
            <RatingStars
              maxStars={5}
              rating={Number(Number(averageRating).toFixed(2))}
            />
            <p className="p-italic">
              {Number(averageRating).toFixed(2)} stars average
            </p>
            <div className="navbar-header-border"></div>
          </Form.Group>
        </Form>
        <div className="navbar-header-border-any"></div>
        {!REVIEWS.some((review) => review.username == username) &&
          _user.profile_id != profile_id && (
            <CreateReviewExisting serviceId={serviceId} />
          )}
        {!REVIEWS.length && (
          <Typography style={{ color: "gray" }} variant="body1">
            No reviews yet
          </Typography>
        )}

        {REVIEWS.length > 0 &&
          REVIEWS.map(
            (
              {
                _id,
                firstname,
                lastname,
                rating,
                username,
                title,
                created_at,
                like_count,
                dislike_count,
                review,
                user_vote,
                user_image_url,
              },
              index
            ) => (
              <ReviewItem
                key={_id}
                reviewer={username}
                since={new Date(created_at).toLocaleString()}
                rating={rating}
                review={review}
                reviewId={_id}
                like_count={like_count}
                dislike_count={dislike_count}
                isLast={index === REVIEWS.length - 1}
                userVote={user_vote}
                serviceId={serviceId}
                title={title}
                user_image_url={user_image_url}
              />
            )
          )}
      </Col>
      {/* <div
        style={{ marginLeft: "19px" }}
        className="fab-container"
        onClick={() => navigate(`/create_review_existing/${serviceId}`)}
      >
        <FloatingActionButton />
      </div> */}
    </Container>
  );
};

export default ReviewDetails;
