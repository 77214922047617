import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React from "react";
import { Container, Navbar } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
const BackButton = ({ onClick, color = "text-white" }) => {
  const navigate = useNavigate();
  return (
    <Navbar>
      <Container>
        <Navbar.Brand href="#" onClick={onClick || (() => navigate(-1))}>
          <FontAwesomeIcon icon={faArrowLeft} className={`${color}`} />
        </Navbar.Brand>
      </Container>
    </Navbar>
  );
};

BackButton.propTypes = {
  onClick: PropTypes.func,
};

export default BackButton;
