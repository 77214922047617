function debounce(func, wait, immediate) {
  let timeout;

  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      if (!immediate) {
        func.apply(this, args);
      }
    };

    if (timeout) {
      clearTimeout(timeout);
    }

    const callNow = immediate && !timeout;
    timeout = setTimeout(later, wait);

    if (callNow) {
      func.apply(this, args);
    }
  };
}

export default debounce;
